<!-- @format -->

<template>
  <nav
    id="navbarBlur"
    :class="`${
      !isNavFixed
        ? 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none'
        : `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky ${
            darkMode ? 'bg-default' : 'bg-white'
          } left-auto top-2 z-index-sticky`
    } ${isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-0 px-md-2 py-0 container-fluid">
      <div
        style="display: flex; justify-content: space-between"
        class="sidenav-toggler sidenav-toggler-inner me-2"
      >
        <a href="#" class="p-0 nav-link text-body" @click.prevent="ActivarDes">
          <div class="sidenav-toggler-inner">
            <i
              class="sidenav-toggler-line"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
              "
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
              "
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
              "
            ></i>
          </div>
        </a>
      </div>

      <breadcrumbs
        :current-page="currentRouteName"
        :current-directory="currentDirectory"
      />

      <h3 v-if="currentDirectory == 'Dashboards'" style="color: white" class="w-100 mt-2 text-center">Periodo: {{FechaInicio + ' - ' +  FechaFin}} </h3>
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        ></div>
        <ul class="navbar-nav justify-content-end">
          <i v-if="currentDirectory == 'Dashboards'"
            style="color: white; margin-right: 5px; cursor: pointer"
            class="mt-3 fas fa-filter"
            @click="MuestraFiltro = true"
          ></i>
          <li class="nav-item">
            <NavbarDropDown :url-foto-miniatura="urlFotoMiniatura" />
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <modal
    v-if="MuestraFiltro"
    :show="MuestraFiltro"
    @close="MuestraFiltro = false"
  >
    <template v-slot:header>
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <div class="w-100 text-center">
        <h3 class="text-primary">Elige periodo a mostrar</h3>
      </div>
    </template>
    <template v-slot:body>
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <div class="w-100">
        <MazPicker
          style="width: 90%"
          class="mx-auto"
          v-model="LFecha"
          label="Periodo"
          color="secondary"
          noShortcuts
          clearable
          required
        />
        <div style="width: 90%" class="mt-4 mx-auto d-flex justify-content-end">
          <MazBtn  class="btn btn-success" @click="ActualizaFecha">Elegir</MazBtn
          >
        </div>
      </div>

      <!-- <div class="mt-3">
        <MazInput v-model="NuevoComentario" label="Agrega un comentario" class="mb-3">
        <template #left-icon>
            <i  class=" fas fa-comment fa-lg " ></i>
        </template>
      </MazInput>
     </div> -->

      <slot></slot>
    </template>
    <slot> </slot>
  </modal>
</template>
<script>
import moment from "moment";
moment.locale("es-mx");
import { mapState, mapMutations, mapActions } from "vuex";
import Breadcrumbs from "../Breadcrumbs.vue";
import Modal from "@/Componentes/Modal.vue";
import NavbarDropDown from "./NavbarDropDown.vue";
import { inject } from "vue";

export default {
  name: "Navbar",
  components: {
    Breadcrumbs,
    Modal,
    NavbarDropDown,
    /*ArgonAvatar,*/
  },
  props: {
    Fecha : {type:Object, default:null},
  },
  setup() {
    // Injectar SuscripcionesUsuarios en el componente
    const SuscripcionesUsuarios = inject("SuscripcionesUsuarios");
    const Usuarios = inject("Usuarios");
    // Ahora SuscripcionesUsuarios está disponible para usar en la plantilla
    return {
      SuscripcionesUsuarios,
      Usuarios,
    };
  },
  data() {
    return {
      showMenu: false,
      PerfilLocal: {},
      LFecha:null,
      // Fecha: {
      //   start: "",
      //   end: "",
      // },
      MuestraFiltro: false,
    };
  },
  emits:["update:Fecha"],
  watch: {
 
  },

  computed: {
    urlFotoMiniatura() {
      return this.Usuarios.FotoUsuarioURL(
        this.PerfilLocal == null ? null : this.PerfilLocal.usuario
      );
    },
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    ...mapState(["isRTL", "isNavFixed", "darkMode"]),
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      let dir = this.$route.path.split("/")[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
    FechaInicio(){
      if (this.LFecha != null && this.LFecha.start != "") {
        return moment(this.LFecha.start).format("DD MMMM YYYY")
      }
      return ""
    },
    FechaFin(){
      if (this.LFecha != null && this.LFecha.end != "") {
        return moment(this.LFecha.end).format("DD MMMM YYYY")
      }
      return ""
    },
  },
  created() {
    this.minNav;
  },

  beforeMount(){
    this.LFecha = this.Fecha
  },

  mounted() {
    this.Usuarios.getPerfilLocal((PerfilLocal) => {
      this.PerfilLocal = PerfilLocal;
      console.log("PerfilLocal", this.PerfilLocal);
    });
  },
  beforeUpdate() {
    this.toggleNavigationOnMobile();
  },
  methods: {
    capitalizeFirstLetter(string) {
      console.log("string", string);
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    ActivarDes() {
      this.togglebtnactive();
      this.navbarMinimize();
    },
    ...mapMutations([
      "navbarMinimize",
      "toggleConfigurator",
      "togglebtnactive",
    ]),
    ...mapActions(["toggleSidebarColor"]),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        console.debug("Es vista movil")
        // this.navbarMinimize();  
        this.togglebtnactive();
      }
    },
    ActualizaFecha(){
      this.$emit("update:Fecha",this.LFecha)
      this.MuestraFiltro = false
    },
  },
};
</script>
<style scoped></style>
