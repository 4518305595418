<template>
    <div style="width: 100%; height: 100%">
        <camera  ref="camara" :resolution="{ width: 1920, height: 1080 }" autoplay @started="Encendida">
            <div style="height: 100%;position: relative; ">
                <svg style="width: 100%; height: 100%;  " id="Capa_2" data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 508 610" preserveAspectRatio="xMidYMin slice">
                    <path class="cls-3"
                        d="M0,0v610h508V0H0ZM254,330.88c-67.93,0-123-55.07-123-123s55.07-123,123-123,123,55.07,123,123-55.07,123-123,123Z" />
                    <g id="Elipse_55" data-name="Elipse 55">
                        <ellipse class="cls-1" cx="253.99" cy="209.81" rx="120.14" ry="124.93" />
                        <ellipse class="cls-2" cx="255.07" cy="208.14" rx="124.07" ry="121.67" />
                        <ellipse class="cls-2" cx="255.07" cy="208.14" rx="124.07" ry="121.67" />
                        <ellipse class="cls-2" cx="255.07" cy="208.14" rx="124.07" ry="121.67" />
                    </g>
                    <path class="cls-3"
                        d="M288.75,296.38c-.14-4.1-.26-8.21-.4-12.31-.06-1.68-.09-3.35-.18-5.03-.03-.59.17-.95.63-1.3,6.39-4.89,11.57-10.79,14.9-18.2,1.69-3.78,2.78-7.7,3.03-11.85.02-.41.08-.83,0-1.21-.24-.97.17-1.37,1.08-1.64,6.55-1.96,10.59-6.55,13.06-12.7,2.05-5.09,2.79-10.41,2.55-15.88-.06-1.22-.72-2.11-1.67-2.81-2.39-1.76-5.17-2.48-8.05-2.95-.61-.1-1.23-.21-1.95-.34.75-2.4,1.44-4.72,2.2-7.01,2.22-6.71,3.14-13.6,2.84-20.66-.42-9.95-3.79-18.95-8.98-27.34-7.74-12.51-18.99-20.28-32.98-24.32-6.94-2-14.03-2.89-21.24-2.59-13.84.57-26.42,4.75-37.28,13.55-9.36,7.58-15.52,17.32-18.98,28.8-1.52,5.01-2.56,10.14-2.79,15.38-.18,4.13-.18,8.26-.14,12.39.04,3.62.99,7.06,2.3,10.43.17.42.28.86.45,1.39-1.36.22-2.67.31-3.89.68-2.01.6-4.02,1.26-5.93,2.11-1.81.8-2.77,2.2-2.77,4.37,0,7.29,1.48,14.11,5.88,20.09,2.84,3.86,6.71,6.22,11.26,7.49.69.19.87.47.85,1.19-.14,5.13,1.4,9.86,3.7,14.35,3.54,6.92,8.79,12.37,15.05,16.86.92.66,1.21,1.33,1.16,2.42-.23,5.55-.38,11.1-.56,16.64h-5.04c.17-4.97.32-9.95.51-14.91.02-.61-.17-1-.66-1.38-6.54-5.03-11.88-11.06-15.46-18.55-1.84-3.85-3.14-7.88-3.57-12.15-.02-.28-.29-.66-.54-.77-8.92-3.75-13.75-10.86-16.19-19.87-1.15-4.26-1.64-8.62-1.27-13.04.21-2.6,1.58-4.53,3.6-6.07,2.06-1.56,4.44-2.44,6.88-3.19.11-.03.22-.06.27-.08-.26-1.72-.57-3.42-.76-5.12-.46-4.18-.57-8.36-.36-12.56.22-4.16.65-8.29,1.47-12.37,2.74-13.72,8.72-25.71,18.98-35.39,8.61-8.13,18.81-13.31,30.32-15.93,6-1.37,12.09-1.96,18.24-1.72,14.57.59,27.98,4.68,39.67,13.63,8.45,6.47,14.47,14.87,18.74,24.57,2.32,5.26,3.99,10.7,4.64,16.43.51,4.49.56,8.99.11,13.5-.5,5.08-1.66,10.01-3.36,14.91.3.1.56.21.81.29,2.2.73,4.32,1.65,6.13,3.15,2.13,1.76,3.23,3.98,3.31,6.77.19,6.51-.81,12.79-3.48,18.74-2.65,5.94-6.73,10.53-12.76,13.25-.26.12-.54.48-.57.75-.92,8.67-4.54,16.16-9.91,22.89-2.32,2.91-4.94,5.51-7.82,7.87-.49.4-.63.8-.61,1.4.16,4.57.3,9.13.4,13.71,0,.41-.25.82-.38,1.24h-4.51.02Z" />
                </svg>
                <div style="position: absolute; z-index:1;"
                    class="MenuCamara d-flex flex-column w-100 justify-content-center align-middle text-center">
                    <h3>Centra tu rostro en el contorno</h3>
                    <div style="gap: 10px;" class="w-100 d-flex justify-content-center">
                        <button class="BotonCamara bg-gradient-success" @click="TomarFoto">
                            <i style="font-size: 2.3rem;" class="fas fa-camera "></i>
                        </button>
                        <!-- <<div class="text-center icon icon-shape bg-gradient-success border-radius-5xl">
                            <i class="fas fa-camera"></i>
                        </div>> -->
                        <el-tooltip ref="tooltipRef" :visible="verCamaras" placement="top">
                            <template #content>
                                <MazTabs v-model="CamaraActiva">
                                    <MazTabsBar :items="CamarasDisp">
                                        <template #item="{ item, active }">
                                            {{ item.label }}

                                            <MazBadge size="0.6rem" rounded-size="full"
                                                :color="active ? 'secondary' : 'gray'">
                                                <i class="fas fa-check"></i>
                                            </MazBadge>
                                        </template>
                                    </MazTabsBar>
                                </MazTabs>
                            </template>
                            <div  style="background-color: gray;" id="Cameraslist"
                                class="text-center icon icon-shape  border-radius-2xl" @click="verCamaras = !verCamaras">
                                <i class="fas fa-sync-alt"></i>
                            </div>
                        </el-tooltip>
                    </div>
                    <!-- <ArgonButton style="top: 10px; left: 10px;"  >Guardar</ArgonButton> -->
                </div>
            </div>
        </camera>
    </div>
</template>

<script>
import Camera from "simple-vue-camera";
import MazTabs from 'maz-ui/components/MazTabs'
import MazTabsBar from 'maz-ui/components/MazTabsBar'
import { ElTooltip } from 'element-plus'
import MazBadge from 'maz-ui/components/MazBadge'
import Colaboradores from '@/api-services/colaboradores.service';
export default {
    name: "CamaraFacial",
    components: {
        Camera, MazTabs, MazTabsBar,
        MazBadge, ElTooltip,
    },
    props: {

    },
    data() {
        return {
            CamarasDisp: null,
            CamaraID: null,
            ListCamera: [],
            verCamaras: false,
            CamaraActiva:0,
            img:null,
            MuestraNoti: false,
            Mensaje: "blablabla",
        }
    },
    watch: {
        async CamaraActiva(NuevoValor){
            let WebCam = this.$refs.camara
            let camaraseleccionada = this.ListCamera[NuevoValor - 1]
            await  WebCam.changeCamera(camaraseleccionada.deviceId)
            console.debug("Se cambio la camara")
        },
    },
    mounted() {
        
    },
    methods: {
        async TomarFoto() {
            let WebCam = this.$refs.camara
            let fotoblob = null
         await WebCam.snapshot({ width: 375, height: 812 }).then((response) => {
                fotoblob = response
                console.debug("El blob es: ", fotoblob)
                let imagen = URL.createObjectURL(fotoblob)
                this.img = imagen
            }
            )
            Colaboradores.FotoPatch(this,this.$route.params.id,0,fotoblob,(progreso) => {}, (response) => {
                console.debug("La respuesta es:",response.data)
                this.$notify({ type: "success", title: "Foto guardada" });
            })

            

            // Colaboradores.IdentificarPatch(this,fotoblob,(progreso) => {}, (response) => {
            //     console.debug("La respuesta es", response.data)
            // })

        },

        async Encendida() {
            let WebCam = this.$refs.camara
            console.debug("La camara esta encendida")
            await WebCam.devices(["videoinput"]).then((response) => {
                this.CamarasDisp = response
                this.ListCamera = response
                console.debug("Esta es la info de las camaras",this.CamarasDisp)
                if (this.CamaraActiva == 0) {
                    this.CamaraActiva = 1
                }
            })
        },
    },
}
</script>

<style lang="scss" >

.camera-container {
  position: relative;
  width: 100%;
  height: 100vh; 
  overflow: hidden;
}
.MenuCamara {
    position: fixed;
        right: 0;
        bottom: 0vw;
        left: 0;
        z-index: 1030;
}

.BotonCamara {
    width: 60px;
    height: 60px;
    color: #ffffff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    border: none;
    text-decoration: none;
}

#Capa_2 {

    .cls-1,
    .cls-2 {
        fill: none;
    }

    .cls-1,
    .cls-3 {
        stroke-width: 0px;
    }

    .cls-2 {
        stroke: #2dce89;
        stroke-width: 4px;
    }

    .cls-3 {
        fill: #fff;
    }
}

#video{
    width: 100%;
    height: 100%;
    transform: scale(-1,1) !important;
}

@media only screen and (min-width: 577px) and (max-width: 992px) {

    /* Agrega aquí los estilos para pantallas medianas */
    .MenuCamara {
        position: fixed;
        right: 0;
        bottom: 0vw;
        left: 0;
        z-index: 1030;
    }
}
</style>