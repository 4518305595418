<!-- @format -->

<template>
    <div style="box-shadow: 1px 1px 20px #D9D9D9 ;"  class="card  p-2">
      <div class="d-flex flex-column  justify-content-between">
        <div class="w-100 d-flex justify-content-between">
            <div>
                <h4 class="text-muted" >{{registro.sucursal}}</h4>
                <p class="m-0">{{Recibe}}</p>
                <p class="m-0">
                    <ins class="w-100">{{Direccion}}</ins>
                </p>
                <ins style="font-weight: bold; cursor: pointer;" class="text-primary" @click="VerDetallesPedido">{{registro.noGafetes}} gafetes</ins>
            </div>
            <img v-if="registro.miniatura != null" :src="registro.miniatura" width="50" height="50" />
        </div>
        <div class="w-100 d-flex justify-content-between mt-3 ">
            <div :style="Estatus" class="w-50 text-center" @click="CambiarEstado">
                {{EstatusTexto}}
            </div>
            <div style="font-size: small; font-weight: bold;">
                No.{{registro.noPedido}}
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CardGafete",
    props: {
      registro: {
        type: Object,
        required: true,
      },
    },
    emits: ["CambiaEstado","VerDetalles"],
    computed: {
        Recibe(){
            if (this.registro == null) {
                return null
            }
            let nombre = this.registro.receptor
            let numero = this.registro.noReceptor
            if (nombre == null) {
                nombre = ""
            }
            if (numero == null) {
                numero = ""
            }

            return "Recibe: " + nombre + " - " + numero

        },
        Direccion(){
        if (this.registro == null) {
            return null
        }
        let direccion = JSON.parse(this.registro.direccionEntrega)
        let calle = direccion.DirCalle != null ? direccion.DirCalle : "" 
        let callenum = direccion.DirCalleNo != null ? direccion.DirCalleNo : ""
        let colonia = direccion.Colonia != null ? direccion.Colonia : ""
        let estado = direccion.Estado!=null ? direccion.Estado :""
        return calle + " " +callenum + ", " + colonia + ", " + estado
    },

    Estatus(){
        if (this.registro == null) {
            return ""
        }
        switch (this.registro.eEstatusEnvio) {
            
            case "RegistrarPago":
                
                return 'border-radius:10px; background-color: #ffdc7d; font-weight: bold; color: #ffa701; cursor:pointer;'
            case "Imprimir":
                
             return 'border-radius:10px; background-color: #22c997; font-weight: bold; color: white; cursor:pointer;'
            case "Verificar":
                
             return 'border-radius:10px; background-color: #95edcd; font-weight: bold; color: #22c997; cursor:pointer;'
            case "Entregar":
                
             return 'border-radius:10px; background-color: #a8efeb; font-weight: bold; color: #2ccec6; cursor:pointer;'
            case "Entregado":
                
             return 'border-radius:10px; background-color: white; font-weight: bold; color: #22c997;'

             default:
                return ''; 
        }
        
    },
    EstatusTexto(){
        if (this.registro == null) {
            return ""
        }
        switch (this.registro.eEstatusEnvio) {
            
            case "RegistrarPago":
                
                return "Registrar pago"

             default:
                return this.registro.eEstatusEnvio; 
        }
    },
 
    },
    methods: {
        CambiarEstado(){
            if (this.registro.eEstatusEnvio == 'Entregado') {
                return 
            }
            let objeto = {
                id:this.registro.pedidoID,
                estado:this.registro.eEstatusEnvio,
                pedido: this.registro.noPedido
            }
            this.$emit("CambiaEstado",objeto)
        },
        VerDetallesPedido(){
            this.$emit("VerDetalles",this.registro.pedidoID)
        },
    },
  };
  </script>
  <style lang="scss" scoped>
 
</style>
  