<template>
  <div class="card w-100 pt-4">
  <div style="width: 90%;" class="mx-auto row d-flex justify-content-center">
    <div class="col-md-6 col-sm-12  mb-3 pb-3">
      <GraficaEvaluaPorMeses />
    </div>
    <div class="col-md-6 col-sm-12  mb-3 pb-3">
      <GraficaEvaluaPorSemanas />
    </div>
  </div>
  <div style="width: 90%;" class="mx-auto row d-flex justify-content-center">
    <div class="col-md-6 col-sm-12  mb-3 pb-3">
      <GraficaEvaluaPorDias />
    </div>
  </div>
</div>
</template>

<script>
import GraficaEvaluaPorMeses from "../../../Componentes/Graficas/GraficaEvaluaPorMeses.vue";
 import GraficaEvaluaPorSemanas from "../../../Componentes/Graficas/GraficaEvaluaPorSemanas.vue"; 
import GraficaEvaluaPorDias from "../../../Componentes/Graficas/GraficaEvaluaPorDias.vue";
//import ChildComponent from "../../../Componentes/Graficas/ChildComponent.vue";

export default {
  components: {
    GraficaEvaluaPorMeses,
     GraficaEvaluaPorSemanas, 
    GraficaEvaluaPorDias,
    //ChildComponent,
  },
  data() {
    return {
      chartData1: [10, 20, 30, 40, 50, 60, 70],
      chartData2: [50, 60, 70, 80, 90, 100, 110],
      chartData3: [30, 40, 50, 60, 70, 80, 90]
    }
  }
};
</script>

<style lang="scss" scoped>
</style>