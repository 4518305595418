<template>
  <div>
    <!-- <div :class="Editar ? 'card card-edition mt-4' : 'card  mt-4'"> -->
          <div style="gap: 3px;" class="d-flex w-100">
              <MazInput v-if="Tipo == 'Etiqueta'" class=" w-90 mx-auto mt-1" v-model="Palabra.etiqueta" color="secondary">
              </MazInput>
              <MazInput v-else class=" w-90 mx-auto mt-1" v-model="Palabra.identificador" color="secondary">
              </MazInput>
              <div style="cursor: pointer;" class="mt-3 d-flex justify-content-end text-primary" @click="Guardar">
              <i style="color: #2dce89;" class="fas fa-check "></i>
            </div>
          </div>
    </div>
</template>

<script>
import MazInput from 'maz-ui/components/MazInput'

export default {
  name: "CardPregunta",
  components: {
    //MazBadge,
    //MazTextarea,
    MazInput,
  },
  props: {
    PalabraObj: { type: Object, default: () => ({}) },
    Tipo:{type:String,default:null},
  },
  emits: [
    "actualizar","cancelar"
  ],
  data() {
    return {
      Palabra: null,
    };
  },
  watch: {
   "Palabra" : {
    handler(Valor){
      this.$emit('actualizar', Valor);
    },
    deep: true
   }
  },
  beforeMount() {
    this.Palabra = { ...this.PalabraObj };
    /* this.OpDisponibles = this.Opciones.map(opcion => {
      const opcionFiltrada = opcion.idiomas.find(x => x.idioma === this.Idioma);
      return { ...opcionFiltrada };
    }); */
  },
  methods: {
    Cancelar() {
      this.$emit("cancelar")
    },
    Guardar() {
    
      this.$emit('actualizar', this.Palabra);
    },
  },
};

</script>

<style>
.card-edition {
  min-width: 200px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>