<template>
  <div
    class="card d-flex justify-content-center align-items-center pt-3 cardAgregarPregunta"
  >
    <div class="row">
      <div class="col">
        <button @Agregar="Agregar">
          <p class="cardAgregarPregunta__parrafo">
            <i class="fa-1x fas fa-plus" style="color: #2dcec6"></i> Agregar
            pregunta
          </p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
  },
  data() {
    return {
      Preguntas: [],
    };
  },
  methods: {
    Agregar() {
      if (this.Preguntas.length < 5) {
        this.Preguntas.push({
          pregunta: "string",
          placeholder: "swds",
        });
      } else {
        alert("Ya has agregado el máximo de 5 elementos.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cardAgregarPregunta {
  border: 1px dashed #2dcec6;
  background-color: #effffe;
}
.cardAgregarPregunta__parrafo {
  color: #2dcec6;
  font-size: 1.2rem;
}
</style>