<!-- @format -->

<template>
  <MazPhoneNumberInput
    v-model="ElTelefono"
    :placeholder="label"
    :country-selector-width="countrySelectorWidth"
    :preferred-countries="['MX', 'US', 'GT', 'HN', 'VE', 'ES', 'CA', 'CO', 'AR', 'CU', 'BR']"
    :translations="{
      label: ['MX'],

      countrySelector: {
        placeholder: 'Código de país',
        error: 'Elegir país',
        searchPlaceholder: 'Buscar país',
      },
      phoneInput: {
        placeholder: 'Móvil personal',
        example: 'Ej:',
      },
    }"
    
  />
</template>

<script>
export default {
  name: "Telefono",
  // Componentes hijos aquí
  components: {
    // Componentes hijos
  },
  // Propiedades del componente aquí
  props: {
    // Propiedades del componente
    label: {
      type: String,
      required: false,
      default: "Teléfono",
    },
  },

  data() {
    return {
      ElTelefono: "",
      // Datos del componente
    };
  },
  computed: {
    countrySelectorWidth() {
      return "7rem";
    },
  },
  // Ciclo de vida del componente aquí
  created() {
    // Lógica que se ejecuta cuando se crea el componente
  },

  // Métodos y lógica del componente aquí
  methods: {
    // Métodos del componente
  },
};
</script>

<style scoped>
/* Estilos del componente aquí */
</style>
