<template>
  <div class="card mx-auto ms-2 ml-2 pt-3">
    <div class="container">
      <div class="row">
        <div class="col-1">
          <MazSelect
            v-model="selectedItemsPerPage"
            label="15"
            :color="color"
            :options="['5', '10', '15']"
          />
        </div>
        <div class="col-6">
          <p>Entradas por páginas</p>
        </div>
      </div>
      <div class="row">
        <div v-if="Evaluaciones.length > 0" class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Hora y fecha</th>
                <th v-for="Pregunta in Evaluaciones[0].respuesta[0].respuestas" :key="Pregunta.preguntaID"
                  scope="col"
                  style="max-width: 270px"
                  class="text-truncate"
                >
                <el-tooltip placement="top" :content="Pregunta.pregunta" effect="customized">
                  {{ Pregunta.pregunta }}
                </el-tooltip>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="Respuesta in Evaluaciones[0].respuesta" :key="Respuesta.nombre">
                <td>{{FechaHora(Respuesta.respuestas[0].horaFinalRespuesta)}}</td>
                <td v-for="RespuestaColab in Respuesta.respuestas" :key="RespuestaColab" style="font-weight: bold; cursor: pointer;" class="text-primary">
                  <i v-if="TipoRespuesta(RespuestaColab.sRespuesta) == 'Calificacion'" class="fa fa-star"></i>{{RespuestaBooleana(RespuestaColab.sRespuesta)}} 
                  <div class="icon-container" @click="MostrarModal(RespuestaColab.pregunta,null)">
                    <i class="text-muted far fa-comment fa-lg" ></i> 
                     <span style="max-width: 10px;" class="badge d-flex justify-content-center">1</span>
                    </div>  
                  </td>
              </tr>

              <!-- <tr>
                <th scope="row">12/Febrero/2024. 1:45pm</th>
                <td style="font-weight: bold; cursor: pointer;" class="text-primary"><i class="fa fa-star"></i>4 <div class="icon-container" @click="MostrarModal(' ¿La eficiencia de Fer en sus tareas fue satisfactoria?',null)"><i class="text-muted far fa-comment fa-lg" ></i>  <span style="max-width: 10px;" class="badge d-flex justify-content-center">3</span></div>  </td>
                <td style="font-weight: bold; cursor: pointer;" class="text-primary">Otto <div class="icon-container"><i class="text-muted far fa-comment fa-lg"></i>  <span style="max-width: 10px;" class="badge d-flex justify-content-center">3</span></div></td>
                <td style="font-weight: bold; cursor: pointer;" class="text-primary">@mdo <div class="icon-container"><i class="text-muted far fa-comment fa-lg"></i>  <span style="max-width: 10px;" class="badge d-flex justify-content-center">3</span></div></td>
                <td style="font-weight: bold; cursor: pointer;" class="text-primary">@mdo <div class="icon-container"><i class="text-muted far fa-comment fa-lg"></i>  <span style="max-width: 10px;" class="badge d-flex justify-content-center">3</span></div></td>
              </tr>
              <tr>
                <th scope="row"></th>
                <td style="font-weight: bold; cursor: pointer;" class="text-primary">Jacob <div class="icon-container"><i class="text-muted far fa-comment fa-lg"></i>  <span style="max-width: 10px;" class="badge d-flex justify-content-center">3</span></div></td>
                <td style="font-weight: bold; cursor: pointer;" class="text-primary">Thornton <div class="icon-container"><i class="text-muted far fa-comment fa-lg"></i>  <span style="max-width: 10px;" class="badge d-flex justify-content-center">3</span></div></td>
                <td style="font-weight: bold; cursor: pointer;" class="text-primary">@fat <div class="icon-container"><i class="text-muted far fa-comment fa-lg"></i>  <span style="max-width: 10px;" class="badge d-flex justify-content-center">3</span></div></td>
                <td style="font-weight: bold; cursor: pointer;" class="text-primary">@fat <div class="icon-container"><i class="text-muted far fa-comment fa-lg"></i>  <span style="max-width: 10px;" class="badge d-flex justify-content-center">3</span></div></td>
              </tr>
              <tr>
                <th scope="row"></th>
                <td style="font-weight: bold; cursor: pointer;" class="text-primary">Larry the Bird <div class="icon-container"><i class="text-muted far fa-comment fa-lg"></i>  <span style="max-width: 10px;" class="badge d-flex justify-content-center">3</span></div></td>
                <td style="font-weight: bold; cursor: pointer;" class="text-primary">@twitter <div class="icon-container"><i class="text-muted far fa-comment fa-lg"></i>  <span style="max-width: 10px;" class="badge d-flex justify-content-center">3</span></div></td>
                <td style="font-weight: bold; cursor: pointer;" class="text-primary">@twitter <div class="icon-container"><i class="text-muted far fa-comment fa-lg"></i>  <span style="max-width: 10px;" class="badge d-flex justify-content-center">3</span></div></td>
                <td style="font-weight: bold; cursor: pointer;" class="text-primary">@twitter <div class="icon-container"><i class="text-muted far fa-comment fa-lg"></i>  <span style="max-width: 10px;" class="badge d-flex justify-content-center">3</span></div></td>
              </tr> -->
            </tbody>
          </table>
        </div>
        <div class="w-100 text-center">
          Este colaborador no tiene ninguna evaluacion
        </div>
      </div>
    </div>
    <ModalComentarioEval :PromEval="PromEval" :Pregunta="Pregunta" :Comentarios="Comentarios" :Mostrar="Mostrar" @update:Mostrar="value => Mostrar = value "/>
    
  </div>
</template>

<script>
import moment from "moment";
moment.locale("es-mx");
 import { ElTooltip } from 'element-plus'
import ModalComentarioEval from './Modals/ModalComentarioEval.vue';
export default {
  components: {
    ElTooltip,
    ModalComentarioEval,
  },
  props: {
    Evaluaciones:{type:Object, default:null},
  },
  data(){
    return {
      Mostrar:false,
      PromEval: "0",
      Pregunta:null,
      Comentarios:null
    }
  },

  methods:{
    MostrarModal(Pregunta,Comentarios){
      this.Pregunta = Pregunta
      this.Comentarios = Comentarios
      this.Mostrar = true
    },
    TipoRespuesta(Respuesta){
      if (Respuesta.length == 1) {
        return "Calificacion"
      }
      if (Respuesta.includes("true") || Respuesta.includes("false")) {
        return "Si/No"
      }

      return "Predeterminada"
    },

    FechaHora(Fecha){
      return moment(Fecha).format("DD/MMMM/YYYY HH:MM a")
    },

    RespuestaBooleana(Respuesta){
      switch (Respuesta) {
        case "true":
          return "Si"
        case "false":
          return "No"
        default:
          return Respuesta
      }
    },
  },
};
</script>

<style >
.el-popper.is-customized {
  /* Set padding to ensure the height is 32px */
  padding: 6px 12px;
  border: 1px solid;
  border-radius: 10px;
  border-color: #2DCEC6;
}

.el-popper.is-customized .el-popper__arrow::before {
  border: 1px solid;
  border-radius: 10px;
  border-color: #2DCEC6;
  right: 0;
}

.icon-container {
  position: relative;
  display: inline-block;
}

.icon-container .badge {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #21c997;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1;
}

</style>
