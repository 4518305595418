<!-- @format -->

<!--
=========================================================
* Vue Argon Dashboard 2 PRO - v4.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<script setup>
import { computed,ref,onBeforeMount } from "vue";
import { useStore } from "vuex";
import moment from "moment";
moment.locale("es-mx");
import Sidenav from "./Vistas/Sidenav";
import Navbar from "@/Componentes/Navbars/Navbar.vue";
import AppFooter from "@/Vistas/Footer.vue";

const store = useStore();
const layout = computed(() => store.state.layout);
const showSidenav = computed(() => store.state.showSidenav);
const showNavbar = computed(() => store.state.showNavbar);
const showFooter = computed(() => store.state.showFooter);
const Fecha = ref({
  start:"",
  end:""
})

onBeforeMount(() => {
  Fecha.value.start = moment().subtract(5,"days").format("YYYY-MM-DD")
  Fecha.value.end = moment().format("YYYY-MM-DD")
});

</script>
<template>

  <div v-show="layout === 'landing'" class="landing-bg h-100 bg-gradient-primary position-fixed w-100"></div>
  <sidenav v-if="showSidenav" />
  <main class="main-content position-relative h-100 min-height-300">
    <navbar v-if="showNavbar" :Fecha="Fecha" @update:Fecha="(value) => Fecha = value" />
    <notifications></notifications>
    <div style="height: 100% !important;">
      <router-view :Fecha="Fecha" />
    </div>

    <app-footer v-show="showFooter" />
  </main>
</template>
