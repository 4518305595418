<template>
  <MazSpinner v-if="cargando" :size="'48'" color="secondary"></MazSpinner>
  <div v-else>
    <div class="m-2">
      <div class="container card">
        <div class="row p-4 d-flex justify-content-around">
          <div class="col">
            <div class="container">
              <div class="row">
                <div class="col-1">
                  <img
                    width="60"
                    height="60"
                    class="rounded-circle"
                    :src="Foto"
                    alt=""
                  />
                </div>
                <div class="col-11">
                  <div class="container">
                    <div class="row">
                      <div class="col">
                        <p>{{ DatosColab.colaboradorYaCalificaMe.etiqueta }}</p>
                        <small>{{
                          DatosColab.colaboradorYaCalificaMe.puesto
                        }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col d-flex justify-content-center align-items-center">
            <div class="container">
              <div class="row">
                <div class="col-2">
                  <router-link :to="{}">
                    <p class="p-0 m-0">
                      <b class="text-primary">{{ Calificacion }}</b>
                      <i class="text-muted fa fa-star ms-2"></i>
                    </p>
                  </router-link>
                </div>
                <div class="col-2">
                  <router-link :to="{}">
                    <p class="p-0 m-0 text-primary">
                      <b>{{ Encuestas }}</b>
                      <i class="text-muted fa fa-check-double ms-2"></i></p
                  ></router-link>
                </div>
                <div class="col-7">
                  <MenuCalificacionesColaboradores v-model="Seleccion" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="Seleccion == 'Calificaciones'"
        class="mt-4 row d-flex justify-content-between"
      >
        <!-- Inicia la importacion de componente CalificacionesGraficas -->
        <CalificacionesGraficas />
        <!-- Termina la importacion de componente CalificacionesGraficas -->
      </div>
      <div v-else class="mt-4 row d-flex justify-content-between">
        <!-- Inicia la importacion de componente EvaluacionesColaborador -->
        <EvaluacionesColaborador :Evaluaciones="PEvaluaciones" />
        <!-- Termina la importacion de componente EvaluacionesColaborador -->
      </div>
      <div class="d-flex justify-content-between">
            <router-link class="mt-2" :to="{}" @click.stop="Regresar"><i
                    class="fa fa-chevron-left me-2"></i>Cancelar</router-link>
        </div>
    </div>
  </div>
</template>

<script>
import CalificacionesGraficas from "@/Vistas/Personal/Componentes/CalificacionesGraficas.vue";
import EvaluacionesColaborador from "@/Vistas/Personal/Componentes/EvaluacionesColaborador.vue";
import MenuCalificacionesColaboradores from "@/Vistas/Personal/Componentes/MenuCalificacionesColaboradores.vue";
import Base from "@/api-services/base";
import Servicio from "@/api-services/colaboradores.yacalificame.service";
import Respuestas from "@/api-services/respuestas.service.js";
import MazSpinner from "maz-ui/components/MazSpinner";
import moment from "moment";
moment.locale("es-mx");

export default {
  name: "CalificacionesColaborador",
  components: {
    MazSpinner,
    CalificacionesGraficas,
    EvaluacionesColaborador,
    MenuCalificacionesColaboradores,
  },
  props: {
    registro: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Seleccion: "Calificaciones",
      Id: null,
      DatosColab: null,
      Foto: null,
      cargando: false,
      PEvaluaciones: null,
    };
  },
  computed: {
    Calificacion() {
      if (
        this.DatosColab != null &&
        this.DatosColab.colaboradorYaCalificaMe.calificacion > 0
      ) {
        return this.DatosColab.colaboradorYaCalificaMe.calificacion;
      }
      return 0;
    },
    Encuestas() {
      if (this.DatosColab != null && this.DatosColab.listado.length > 0) {
        return this.DatosColab.listado.length;
      }
      return 0;
    },
 
  },
  beforeMount() {
    this.Id = this.$route.params.id;
    let fechaI = moment().startOf("year").format("YYYY-MM-DD");
    let fechaF = moment().format("YYYY-MM-DD");
    Respuestas.get(
      this,
      this.Id,
      { FHDesde: fechaI, FHHasta: fechaF },
      (response) => {
        console.debug(
          "estos son los datos que recibo del colaborador",
          response
        );
        this.DatosColab = response.data;
        this.FotoURL(this.DatosColab.colaboradorYaCalificaMe);
        this.PEvaluaciones = this.Evaluaciones();
      }
    );
   let url = window.location.hash.substring(1)
   if (url && url == "califica-colaborador") {
    console.debug("Si tenia: ",url)
    this.Seleccion = "Calificaciones"
   }
   else if(url && url=="evalua-colaborador"){
    this.Seleccion = "Evaluaciones"
   }
   else {
    console.debug("No tiene")
   }
  },
  methods: {
    actualizarValor(nuevoValor) {
      this.Seleccion = nuevoValor;
      // console.debug(nuevoValor);
    },
    FotoURL(Registro) {
      let img = Servicio.FotoURL(Registro);
      this.Foto = img;
    },
    Evaluaciones() {
      let preguntasbase = this.DatosColab.encuestas;
      preguntasbase.forEach((encuesta) => {
        let preguntas = encuesta.listadoPreguntas;
        let respuesta = this.DatosColab.listado.filter(
          (x) => x.id == encuesta.encuestaID
        );
        encuesta.respuesta = respuesta;
        encuesta.respuesta.forEach((respuesta) => {
          respuesta.respuestas.forEach((resp) => {
            let enunciado = preguntas.filter((x) => x.id == resp.preguntaID)[0];
            if (enunciado) {
              let texto = enunciado.contenido.filter(
                (x) => x.idioma == "es-ES"
              )[0];
              resp.pregunta = texto.contenido;
            }
          });
        });
      });
      return preguntasbase;
    },
    Regresar() {
      this.$router.push("/personal"); 
    },
  },
};
</script>

<style lang="scss" scoped></style>
