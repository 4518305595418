/** @format */

import Base from "@/api-services/base";

import YaCalificaMe from "@/api-services/yaCalifica.me.service.js";
const BaseURL = `${YaCalificaMe.URL}/Suscripciones/{{SuscripcionID}}/Pedidos`;

export default {
  GetsResponse: null,
  Listado: [],
  
  gets(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      BaseURL,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  getPedido(Vista,ID, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/${ID}`,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },

  getsSelect(Vista,Filtro, OnCorrecto, OnErroneo, OnFinalizado) {
    console.debug("Esto me llega de filtro",Filtro)
    if (this.GetsResponse != null) OnCorrecto(this.GetsResponse);
    return Base.get(
      Vista,
      `${YaCalificaMe.URL}/Pedidos`,
      { params:Filtro },
      (response) => {
        this.GetsResponse = response;
        this.Listado = response.data.listado;
        if (OnCorrecto != undefined) OnCorrecto(response);
      },
      OnErroneo,
      OnFinalizado
    );
  },
  put(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.put(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  post(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.postBaseSuscripcion(
      Vista,
      BaseURL,
      Datos,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  get(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/${id}`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  delete(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.deleteBaseSuscripcion(
      Vista,
      `${BaseURL}/Gafete/${id}`,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  putCampos(Vista, Campos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Campos`);
    return Base.put(
      Vista,
      URL,
      Campos,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  getAvanzado(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/${id}/Avanzado`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  //quita lo borrado
  patch(Vista, ID, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.patch(Vista, URL, {}, {}, OnCorrecto, OnErroneo, OnFinalizado);
    //return Base.patchBaseSuscripcion(Vista, BaseURL, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado);
  },
};
