<!-- @format -->

<template>
  <Modal v-if="MostrarModal" :show="MostrarModal" modal-classes="modal-dialog-centered modal-dialog-scrollable"
    :type="type" @close="closeModal">
    <template #header>
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h3 class="card-title text-center text-primary">Encuestas (Links)</h3>
    </template>

    <template #body>
      <div class="p-3">
        <Cargando :cargando="cargando" :msg-error="msgError">
          <div class="d-grid gap-1">
            <div v-if="$slots.nuevo">
              <slot name="nuevo"></slot>
            </div>
            <div v-else>
              <p>Estas encuestas serán aparte de la que tu colaborador ya tiene en su gafete</p>
              <strong>Comparte tus encuestas</strong>
              <div v-if="info && info.length">
                <CardLinksEncuestas v-for="encuesta in info" :key="encuesta.id" :encuesta="encuesta" @editar="Editar" @borrar="Borrar" @reactivar="Reactivar" />
                <!-- <CardLinksEncuestas v-for="encuesta  in localListadoPreguntas" :key="encuesta.id" :encuesta="encuesta" v-model="localListadoPreguntas[index]"
                  @editar="Editar" @borrar="Borrar" @reactivar="Reactivar" /> -->

                <div class="card">
                  <div class="card-body">
                    <div class="position-absolute end-2">
                      <router-link :to="{}" class="p-2 mt-4 text-secondary" data-effect="fadeOut"
                        @click.stop="Borrar(encuesta)"><i class="fa fa-times"></i></router-link>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <SelectEncuestas v-model="localEncuesta"
                          @update:model-value="(value) => update('encuestaID', value)" @actualiza="ActualizaDato" />
                      </div>
                      <div class="col-6 pt-1">
                        <a name="" id="" class="btn btn-success" href="#" role="button">Guardar cambios</a>
                      </div>
                    </div>
                  </div>
                </div>
                <AgregarEncuesta @nueva-encuesta="OnAgregarEncuesta" class="mt-3" />
                <ModalBorrar v-if="MostrarBorrar" :tipo="tipo" :etiqueta="RegistroABorrar.etiqueta"
                  :reactivar="RegistroABorrar.borrado" @update:mostrar="(value) => (RegistroABorrar = null)"
                  @borrar="(value) => BorrarSinPreguntar()" />
              </div>
              <p v-else>No hay encuestas disponibles</p>
              <!-- <p>Datos del colaborador por id: {{ info }}</p> -->
            </div>
            <hr />

            <!-- <button class="btn btn-success mb-2" @click="AgregarEncuesta">Agregar encuesta</button> -->
          </div>
        </Cargando>
      </div>
    </template>
    <p><!--Vacio para generar--></p>
  </Modal>
</template>

<script>
import Servicio from "@/api-services/vinculos.yacalificame.service";
//import Encuestas from "@/api-services/puestos.service"
import Modal from "@/Componentes/Modal.vue";
import Cargando from "@/Componentes/Cargando.vue";
import CardLinksEncuestas from "../../Vistas/Personal/Componentes/CardLinksEncuestas.vue";
import AgregarEncuesta from './../../Vistas/Personal/Componentes/AgregarEncuesta.vue'
import ModalBorrar from "@/Componentes/Listados/ModalBorrar.vue";
import SelectEncuestas from "@/Vistas/Encuestas/Select.vue";


export default {
  components: {
    AgregarEncuesta,
    Cargando,
    CardLinksEncuestas,
    Modal,
    ModalBorrar,
    SelectEncuestas,
  },
  props: {
    mostrarIrAEdicion: {
      type: Boolean,
      default: true,
      description: "Indica si se mostrará la opción de ir a edición",
    },
    msgError: {
      type: String,
      default: "",
      description: "Muestra el mensaje de error",
    },

    validarDatos: {
      type: Function,
      default: () => {
        return true;
      },
    },
    mostrar: {
      type: Boolean,
      default: true,
    },
    //ej. Mercadotecnia
    etiqueta: {
      type: String,
      required: true,
      default: "Mercadotecnia",
    },
    //ej. departamento
    tipo: {
      type: String,
      required: true,
      default: "departamento",
    },
    tipoIcono: {
      type: String,
      required: true,
      default: "fas fa-sitemap",
    },
    //Indica que se mostrará la opción de reactivar
    reactivar: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini", "large"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|large|"") ',
    },
    listadoPreguntas: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["update:mostrar", "agregar", "update:datos", 'update:ir-a-edicion',
    "update:listadoPreguntas",
  ],

  data() {
    return {
      info: null,
      //Muestra el cargando
      cargando: true,
      //Indica que hay error
      encuesta: null,

      MsgError: "",
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },
      IrAEdicion: true,
      MostrarModal: this.mostrar,
      localListadoPreguntas: this.ActualizaPreguntas(this.listadoPreguntas),
      RegistroABorrar: null,
      eventos: {
        onEditar: this.Editar,
        onBorrar: this.Borrar,
        onReactivar: this.Reactivar,
      },
      localEncuesta: this.encuestaID,
    };
  },
  watch: {
    IrAEdicion(valor) {
      this.$emit("update:ir-a-edicion", valor);
    },
    listadoPreguntas(valor) {
      this.localListadoPreguntas = this.ActualizaPreguntas(this.listadoPreguntas);
    },
  },
  computed: {
    slots() {
      return Object.keys(this.$slots);
    },
    MostrarBorrar() {
      return this.RegistroABorrar !== null;
    },
  },

  beforeMount() {
    Servicio.get(this, this.$route.params.id, (response) => {
      console.debug("respuesta de vinculos", response);
      this.info = response.data;
    });
  },
  mounted() {
    this.ActualizaDatos();
  },
  methods: {
    ActualizaPreguntas(listadoPreguntas) {
      var R = [];
      console.debug(R);
      if (listadoPreguntas != null) R = listadoPreguntas;
      var Yo = this;
      R.forEach((e) => {
        console.debug(e);
        if (e.id == null) {
          e.id = `ID${Yo.Ultimo}`;
          Yo.Ultimo++;
        }
      });
      console.debug(R);
      return R;
    },
    onBorrar(registro) {
      console.log("onBorrar", registro);
    },
    ActualizaDatos() {
      this.$emit("update:datos", this.Datos);
    },
    Borrar(registro) {
      console.log("Borrar", registro);
      this.RegistroABorrar = registro;
      //this.$emit("borrar", registro);
    },
    BorrarSinPreguntar() {
      // Encuentra el índice del elemento a eliminar
      let index = this.localListadoPreguntas.indexOf(this.RegistroABorrar);

      // Si el elemento está en el array, elimínalo
      if (index !== -1) {
        this.localListadoPreguntas.splice(index, 1);
      }
      this.RegistroABorrar = null;
      this.localListadoPreguntas
      this.$emit("update:listadoPreguntas", this.localListadoPreguntas);

      /*
      this.$emit("borrar", {
        registro: this.RegistroABorrar,
        OnBorrado: this.OnBorrado,
      });*/
    },

    OnBorrado(response) {
      setTimeout(() => {
        this.$nextTick(() => {
          this.Actualiza();
          this.RegistroABorrar = null;
        });
      }, 100);
      /*    this.$nextTick(() => {
        this.Actualiza();
        this.RegistroABorrar = null;
      });*/
    },
    OnError(MsgError, Error) {
      console.debug("Error", MsgError);
      if (Error && Error.response)
        console.debug("Error.response", Error.response.data);
    },
    update(campo, value) {
      // console.debug(`Actualizando ${campo} a ${value}`);
      this.$emit(`update:${campo}`, value);
    },
    closeModal() {
      this.MostrarModal = false;
      this.$emit("update:mostrar", this.MostrarModal);
    },

    Agregar() {
      if (this.validarDatos != null && !this.validarDatos(this.Datos)) {
        return;
      }
      console.log("Agregar", JSON.stringify(this.Datos));
      this.$emit("agregar", this.Datos);
    },
    updateListado() {
      this.$emit(`update:listadoPreguntas`, this.localListadoPreguntas);
    },
    OnAgregarEncuesta(nuevaEncuesta) {
      // Agregar la nueva encuesta al listado
      if (this.info) {
        this.info.push(nuevaEncuesta);
      } else {
        this.info = [nuevaEncuesta];
      }
    },

  },
};
</script>
