<!-- @format -->

<template>
  <div class="card bg-light bg-gradient p-2">
    <div class="d-flex justify-content-between">
      <router-link :to="{ path: RutaDestino }" @click="Editar(registro)">
        <div class="p-2">
          <h4 class="mb-0 pb-0 text-break">{{ Etiqueta }}</h4>
          <p class="mb-0 pb-0 text-muted">{{ Descripcion }}</p>
        </div>
      </router-link>

      <router-link
        v-if="registro.borrado"
        :to="{}"
        class="p-2 text-secondary"
        data-effect="fadeOut"
        @click="Reactivar(registro)"
        ><i class="fa fa-check"></i
      ></router-link>
      <router-link
        v-else
        :to="{}"
        class="p-2 text-secondary"
        data-effect="fadeOut"
        @click="Borrar(registro)"
        ><i class="fa fa-times text-secondary"></i
      ></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardRegistro",
  props: {
    registro: {
      type: Object,
      required: true,
    },
  },
  emits: ["editar", "borrar", "reactivar"],
  computed: {
    RutaDestino() {
      return `${this.$route.path}/${this.registro.id}`;
    },
    Etiqueta() {
      if (this.registro == null) return "{Sin registro}";
      return this.registro.etiqueta;
    },
    Descripcion() {
      if (this.registro == null) return "{Sin registro}";
      return this.registro.descripcion;
    },
  },
  methods: {
    Editar(registro) {
      //console.log("Editar", registro);
      this.$emit("editar", registro);
    },
    Borrar(registro) {
      // console.log("Borrar", registro);
      this.$emit("borrar", registro);
    },
    Reactivar(registro) {
      // console.log("reactivar", registro);
      this.$emit("reactivar", registro);
    },
  },
};
</script>
