<!-- @format -->

<template>
  <CardTitulo titulo="Domicilio" id="domicilio">
    <DomicilioComp  v-model="localdDireccion" @update:model-value="(value) => update('localdDireccion', value)" />
  </CardTitulo>
</template>

<script>
import CardTitulo from "../../../Componentes/CardTitulo.vue";

import DomicilioComp from "@/Componentes/Domicilio.vue";

export default {
  components: {
    CardTitulo,
    DomicilioComp,
  },
  props: {
    dDireccion: {
      type: Object,
      default: () => ({
        pais: null,
        dirCalle: null,
        dirCalleNo: null,
        dirInterior: null,
        colonia: null,
        tipoAsentamiento: null,
        municipio: null,
        ciudad: null,
        estado: null,
        cp: null,
        gps: null,
      }),
    },
  },
  emits: ["update:dDireccion"],
  data() {
    return {
      localdDireccion: this.dDireccion,
    };
  },

  methods: {
    update(campo, value) {
      this.localdDireccion = value;
      this.$emit(`update:dDireccion`, this.localdDireccion);
    },
    cambiarMensaje() {
      this.mensaje = "¡Hola, Vue!";
    },
  },
};
</script>
