<template>
    <div style="height: 70vh;" class="card mx-2 p-3 mx-lg-4 p-lg-4">
        <div>
            <div style="gap: 10px;" class="d-flex w-90 justify-content-between">
                <div class="align-middle">
                    <MazSwitch class="m-0" style="color: #2dce89" v-model="VistaSwitch" color="secondary">
                        {{ TextoSwitch }}
                    </MazSwitch>
                </div>
                <div style="margin: 0 auto; " class="d-flex mt-n2 ">
                    <h2 style="color: #2dce89;flex-grow: 1;" class="p-0 m-0">{{ TextoCentro }}</h2>
                </div>

            </div>
        </div>
        <div v-if="VistaSwitch" style="height: 90%;" class="w-100">
            <div style="height: 100%;" v-if="VistaFoto">
                <!-- <TomaFacial  :v-model:Mostrar="MostrarTomaFoto" v-on:guardar="OnGuardarFoto"
                        Categoria="Checar Asistencia" Titulo="Reconocimiento facial" CerrarMsg="Cancelar"
                        :AutoGuardado="true" /> -->
                <CamaraFacial />
            </div>
            <div v-else style="max-height: 95%" class="mt-5 d-flex w-50 mx-auto justify-content-center">
                <vueQr  style="max-width: 645px;" :text="ObtenUrl" :qid="ObtenUrl" width="100%" :size="2000" :correctLevel="3"
                        logoSrc="/img/Ya_Calificame_Logo.png" />
            </div>
        </div>
        <div v-else ref="filecont" style="height: 90%; " class="w-100">
            <div>
                <div style="background-color: #2dce89; border-radius: 10px; height: 90%; " class="mt-2 w-50 mx-auto">
                    <div class="mx-2 my-2">
                        <ArchivoSelect v-model:value="FotoTomada" v-model:archivo="FotoArchivo"
                            @update:value="FotoTomada = $event" @update:archivo="FotoArchivo = $event"
                            :altura="TamanioCont" />
                    </div>
                </div>
            </div>
        </div>

        <div class="w-100 mt-3 d-flex justify-content-end">
            <ArgonButton v-if="!VistaSwitch" @click="Importar">Guardar</ArgonButton>
        </div>
    </div>
</template>

<script>
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import MazSwitch from "maz-ui/components/MazSwitch";
import ArchivoSelect from "./Campos/ArchivoSelect.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import CamaraFacial from './Blobs/CamaraFacial.vue';
// import TomaFacial from '@/Vistas/Personal/Componentes/Blobs/TomaFacial'
export default {
    name: "CardTomaFoto",
    components: {
        MazSwitch, ArchivoSelect, ArgonButton,
        vueQr,
        CamaraFacial,
    },
    props: {

    },
    data() {
        return {
            VistaSwitch: true,
            FotoTomada: null,
            TamanioCont: null,
            Mostrar: true,
            MostrarTomaFoto: true,
            VistaFoto: false,
            MuestraQR: false,
            FotoArchivo: null,
        }
    },
    computed: {
        TextoSwitch() {
            if (this.VistaSwitch) {
                return "Camara"
            }
            return "Elegir imagen"
        },
        TextoCentro() {
            if (this.VistaSwitch) {
                return "Toma de fotografia"
            }
            return "Asigna una imagen"
        },

        ObtenUrl() {
            return window.location.href
        },

    },
    watch: {
        VistaSwitch(Valor) {
            console.debug("Se detecto un cambio")
            this.MuestraQR = !this.MuestraQR
            this.ObtenerMedidas()
        }
    },
    mounted() {
        this.ObtenerMedidas()
        window.addEventListener("resize", this.ObtenerMedidas)
    },
    methods: {
        ObtenerMedidas() {
            if (window.innerWidth <= 992) {
                this.VistaFoto = true
            }
            else {
                this.VistaFoto = false
            }
            // console.debug("Esto tiene la ref",this.$refs.filecont)
            if (this.$refs.filecont != undefined) {

                this.TamanioCont = (this.$refs.filecont.clientHeight * 0.86) + "px"
            }
        },
        OnGuardarFoto(Foto) {
            console.debug("Esta es la foto", Foto)
        },
        Importar() {
            if (this.FotoArchivo == null) {
                this.$notify({ type: "danger", title: "Sube un archivo para importar" });
            }

            else {
                this.$emit("importar",this.FotoArchivo)
            }
        },
    },
}
</script>

<style scoped>
.photo-view {
    display: none;
}

.Correccion {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 992px) {
    .photo-view {
        display: flex;
    }
}
</style>