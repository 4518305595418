<!-- @format -->

<!-- eslint-disable no-unused-vars -->
<!-- @format -->

<template>
  <CardTitulo titulo="Personales">
    <div class="row">
      <div class="col">
        <div class="row">
          <MazInput
            v-model="localNombre"
            type="text"
            :class="EstiloCampo"
            label="Nombre(s)"
            color="secondary"
            @update:model-value="(value) => update('nombre', value)"
          />
          <MazInput
            v-model="localApellidoP"
            type="text"
            :class="EstiloCampo"
            label="Apellido Paterno"
            color="secondary"
            @update:model-value="(value) => update('apellidoP', value)"
          />
          <MazInput
            v-model="localApellidoM"
            type="text"
            :class="EstiloCampo"
            label="Apellido Materno"
            color="secondary"
            @update:model-value="(value) => update('apellidoM', value)"
          />
        </div>
        <div class="row">
          <MazInput
            v-model="localLinkID"
            type="text"
            :class="EstiloCampo"
            label="Id único"
            color="secondary"
            @update:model-value="(value) => update('linkID', value)"
            required
          />

          <CURPInput
            v-model="localCurp"
            label="CURP"
            :class="EstiloCampo"
            color="secondary"
            @Actualiza="CurpActualizado"
          />

          <MazPicker
            v-model="localFechaNacimiento"
            type="text"
            :class="EstiloCampo"
            label="Fecha de nacimiento"
            dateFormat="YYYY-MM-DD"
            :picker="pickerOptions"
            format="YYYY-MM-DD"
            color="secondary"
            @update:model-value="(value) => update('fechaNacimiento', value)"
          >
            <div
              class="flex items-center"
              style="width: 100%; gap: 1rem; margin-left: 2rem"
            ></div>
          </MazPicker>
        </div>
        <div class="row">
          <GenerosSelect
            v-model="localGenero"
            :class="EstiloCampo"
            color="secondary"
            @update:model-value="(value) => update('genero', value)"
          />
          <PaisSelect
            v-model="localNacionalidad"
            :nacionalidad="true"
            :class="EstiloCampo"
            placeholder="Nacionalidad"
            campoValor="iso3"
            color="secondary"
            @update:model-value="(value) => update('nacionalidad', value)"
            @actualiza="ActualizaDato"
          />
        </div>
      </div>
      <div class="col-auto d-none d-sm-block d-md-block">
        <div class="position-relative d-inline-block">
          <img
            :src="
              localUrlMiniatura ? localUrlMiniatura : '/img/default-avatar.png'
            "
            alt="No hay foto-"
            class="profile-img img-fluid"
          />
          <router-link
            class="bg-white edit-btn m-0 rounded-circle"
            :to="RutaFoto"
          >
            <i
              style="color: #2dcec6; background-color: #ffffff"
              class="fas fa-pencil-alt"
            ></i>
          </router-link>
          <!-- <button class="bg-white edit-btn m-0 rounded-circle">
            <i
              style="color: #2dcec6; background-color: #ffffff"
              class="fas fa-pencil-alt"
            ></i>
          </button> -->
        </div>
      </div>
    </div>
  </CardTitulo>
</template>


<script>
import { watch } from "vue";
import { ref } from "vue";
import CardTitulo from "../../../Componentes/CardTitulo.vue";
import CURPInput from "@/Componentes/CURPInput.vue";
import GenerosSelect from "@/Componentes/GenerosSelect.vue";
import PaisSelect from "@/Componentes/PaisSelect.vue";
import Base from "@/api-services/base";
/* import TomaFoto from "../TomaFoto.vue"; */
import Servicio from "@/api-services/colaboradores.yacalificame.service";


export default {
  components: {
    CardTitulo,
    CURPInput,
    GenerosSelect,
    PaisSelect,
    /* TomaFoto, */
  },
  props: {
    linkID: {
      type: String,
      default: "",
    },
    nombre: {
      type: String,
      default: "",
    },
    apellidoP: {
      type: String,
      default: "",
    },
    apellidoM: {
      type: String,
      default: "",
    },
    genero: {
      type: String,
      default: "",
    },
    nacionalidad: {
      type: String,
      default: "",
    },

    curp: {
      type: String,
      default: "",
    },
    fechaNacimiento: {
      type: String,
      default: "",
    },
    urlMiniatura: {
      type: String,
      default: "",
    },

    loading: { type: Boolean, default: false },

    value: { type: String, default: null },
    id: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },

    // Same as MazInput (options: `sm|md|lg`)
    size: { type: String, default: null },

    optionSize: { type: String, default: null },

    // Etiqueta a mostrar
    campo: { type: String, default: "Genero" },

    // Etiqueta a mostrar
    placeholder: { type: String, default: "Genero" },
    // Enable the dark mode
    dark: { type: Boolean, default: false },
    // Use color
    color: { type: String, default: "primary" },

    success: { type: Boolean, default: false },

    open: { type: Boolean, default: false },

    // set the position of countries list (ex: `top`, `top right`, `bottom right`)
    position: { type: String, default: "left bottom" },

    clearable: { type: Boolean, default: true },
  },

  emits: [
    "update:linkID",
    "update:nombre",
    "update:apellidoP",
    "update:apellidoM",
    "update:genero",
    "update:nacionalidad",
    "update:curp",
    "update:fechaNacimiento",
    "update:urlMiniatura",
    "editar",
  ],
  setup() {
    const localFechaIngreso = ref(new Date());

    // Configuración del formato de fecha para mostrar solo mes y año
    const dateFormat = "MM/YYYY";

    // Opciones del picker
    const pickerOptions = {
      // Aquí puedes agregar más opciones según la documentación de maz-ui
    };

    return {
      localFechaIngreso,
      dateFormat,
      pickerOptions,
    };
  },
  data() {
    return {
      localLinkID: this.linkID,
      localNombre: this.nombre,
      localApellidoP: this.apellidoP,
      localApellidoM: this.apellidoM,
      localGenero: this.genero,
      localNacionalidad: this.nacionalidad,
      localCurp: this.curp,
      localFechaNacimiento: this.fechaNacimiento,
      localUrlMiniatura: this.urlMiniatura,
      MostrarNuevo: false,
      selectedItem: null,
      cargando: false,

      inputValue: "",
      Erroneo: "",
      Datos: {
        nombre: "",
        etiqueta: "",
        descripcion: "",
        departamentoID: "",
        /* FotoURL: "", */
      },
    };
  },
  // Usar el hook created puede ser útil para inicializar datos o estados
  created() {
    watch(
      () => this.linkID,
      (newVal) => {
        this.localLinkID = newVal;
      }
    );
    watch(
      () => this.nombre,
      (newVal) => {
        this.localNombre = newVal;
      }
    );
    watch(
      () => this.apellidoP,
      (newVal) => {
        this.localApellidoP = newVal;
      }
    );
    watch(
      () => this.apellidoM,
      (newVal) => {
        this.localApellidoM = newVal;
      }
    );
    watch(
      () => this.genero,
      (newVal) => {
        this.localGenero = newVal;
      }
    );
    watch(
      () => this.nacionalidad,
      (newVal) => {
        this.localNacionalidad = newVal;
      }
    );
    watch(
      () => this.curp,
      (newVal) => {
        this.localCurp = newVal;
      }
    );
    watch(
      () => this.fechaNacimiento,
      (newVal) => {
        this.localFechaNacimiento = newVal;
      }
    );
    watch(
      () => this.urlMiniatura,
      (newVal) => {
        this.localUrlMiniatura = newVal;
      }
    );
  },
  computed: {
    error() {
      if (this.required)
        if (this.selectedItem == "Desconocido" || this.value == null)
          return true;
      return false;
    },
    Config() {
      {
        return {
          labelKey: "label",
          searchKey: "label",
          valueKey: "value",
        };
      }
    },
    MazPhoneNumber() {
      return Base.MazPhoneNumber;
    },
    EstiloCampo() {
      return "col-lg-4 col-12 col-sm-12 col-md-12 px-2 mb-campo";
    },
    FotoURL() {
      let img = Servicio.FotoURL(this.localUrlMiniatura);
      return img;
    },
    RutaDestino() {
      return `${this.$route.path}/${this.Datos.id}`;
    },
    RutaFoto(){
      return `/personal/${this.$route.params.id}/tomafoto`
    },
  },

  watch: {
    value(valor) {
      this.selectedItem = valor;
      console.debug(valor);
    },
    selectedItem(valor) {
      //this.$emit('update:value', valor);
      this.$emit("input", valor);
      //  this.$set(this, 'value', valor);
      // this.value = valor;
    },
  },
  methods: {
    update(campo, value) {
      // console.debug(`Actualizando ${campo} a ${value}`);
      this.$emit(`update:${campo}`, value);
    },
    cambiarMensaje() {
      this.mensaje = "¡Hola, Vue!";
    },

    OnGuardado(Datos) {
      this.valores.push(Datos);
      this.selectedItem = Datos.id;
      /*
          //Actualiza todo el listado
        this.Actualiza(() => {
          this.selectedItem = Datos.id;
        });
        */
    },

    Actualiza(OnFinalizado) {},
    ActualizaDato(Valor) {
      console.debug("Este es el valor que me llega", Valor);
      this.update("nacionalidad", Valor);
    },
    CurpActualizado(valor) {
      this.update("curp", valor);
    },
    EditarFoto(registro) {
      console.log("Editar", registro);
      this.$emit("editar", registro);
    },
  },
  mounted() {
    if (this.value != null) this.selectedItem = this.value;
    this.Actualiza();
  },
};
</script>
<style scoped>
.option {
  margin-top: auto;
  margin-bottom: auto;
}
.outer {
  width: 100% !important;
  height: 100% !important;
  max-width: 150px !important; /* any size */
  max-height: 150px !important; /* any size */
  margin: auto;
  background-color: #6eafd4;
  border-radius: 100%;
  position: relative;
}

.inner {
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  right: 0;
}

.profile-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.edit-btn {
  position: absolute;
  bottom: -1rem;
  right: 2rem;
  bottom: 0;
  right: 0;
  border-radius: 60%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
