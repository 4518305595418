<template>
  <div>
    <div class="container">
      <div class="w-100 row mr-sm-2">
        <div v-if="Graficas.Calificaciones" style="transition: 0.5s all ease;"
          :class="!Pantalla.Calificaciones ? ' col-sm-12 mr-sm-5 col-lg-5  card mb-3 ms-4' : 'col-12 mr-sm-5 card mb-3 ms-4 PantallaCompleta'">
          <Calificaciones :Fecha="Fecha" @Expandir="Expandir" />
        </div>
        <div v-if="Graficas.Historial" style="transition: 0.5s all ease;"
          :class="!Pantalla.Historial ? 'col-sm-12 col-lg-6 card mb-3 ms-4 Grafichica' : 'col-12 card mb-3 ms-4 PantallaCompleta Graficagrande'">
          <GradientLineChart :Fecha="Fecha" id="chart-line" title="Line chart with gradient" @Expandir="Expandir" />
        </div>
      </div>
      <div class="w-100 row">
        <div v-if="Graficas.Seguimiento" style="transition: 0.5s all ease;"
          :class="!Pantalla.Seguimiento ? 'col-sm-12 col-lg-3 card mb-3 ms-4' : 'col-12  card mb-3 ms-4 PantallaCompleta '">
          <!-- Inicia Seguimiento de evaluaciones -->

          <DoughnutChart :Fecha="Fecha" @Expandir="Expandir" />
        </div>
        <div v-if="Graficas.Indice" style="transition: 0.5s all ease;"
          :class="!Pantalla.Indice ? 'col-sm-12 col-lg-4 card mb-3 ms-4 Grafichica' : 'col-12 card mb-3 ms-4 PantallaCompleta Graficagrande'">
          <BubbleChart :Fecha="Fecha" title="Bubble Chart" height="350"  @Expandir="Expandir" />
        </div>
        <div v-if="Graficas.Distribucion" style="transition: 0.5s all ease;"
          :class="!Pantalla.Distribucion ? 'col-sm-12 col-lg-4 card mb-3 ms-4 Grafichica ' : 'col-12 card mb-3 ms-4 PantallaCompleta Graficagrande'">
          <DefaultLineChart :Fecha="Fecha" id="line-chart" title="Distribución Género de los clientes" @Expandir="Expandir" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BubbleChart from "./Componentes/BubbleChart.vue";
import Calificaciones from "./Componentes/Calificaciones.vue";
/* import ChannelsChartCard from "./Componentes/ChannelsChartCard.vue"; */
/* import Charts from "./Componentes/Charts.vue"; */
import DefaultLineChart from "./Componentes/DefaultLineChart.vue";
/* import DistribucionGenero from "./Componentes/DistribucionGenero.vue"; */
import DoughnutChart from "../Dashboards/Componentes/DoughnutChart.vue";
import GradientLineChart from "./Componentes/GradientLineChart.vue";
/* import IndiceRetorno from "./Componentes/IndiceRetorno.vue"; */
/* import RevenueChartCard from "./Componentes/RevenueChartCard.vue"; */
/* import ReportsDoughnutChart from "@/Vistas/Dashboards/Componentes/ReportsDoughnutChart.vue"; */
import ServiciosDashboard from "@/api-services/dashboardsgeneral.yacalificame.service";

export default {
  name: "Dashboard",
  components: {
    BubbleChart,
    Calificaciones,
    /* ChannelsChartCard, */
    /* Charts, */
    DefaultLineChart,
    /* DistribucionGenero, */
    DoughnutChart,
    GradientLineChart,
    /* IndiceRetorno, */
    /* RevenueChartCard, */
    /* ReportsDoughnutChart, */
  },
  props:{
    Fecha:{type:Object,default:null},
  },
  watch:{
    
  },
  data() {
    return {
      Graficas: {
        Calificaciones: true,
        Historial: true,
        Seguimiento: true,
        Indice: true,
        Distribucion: true,
      },
      Pantalla: {
        Calificaciones: false,
        Historial: false,
        Seguimiento: false,
        Indice: false,
        Distribucion: false
      },
      Tamanio: "300",
      info: null,
      cargando: true,
      MsgError: "",
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },
    }
  },
  computed: {
    chartData() {
      return {
        labels: this.infovisit.ejeX,
        datasets: [
          {
            label: 'Projects',
            data: this.infovisit.visitas,
            backgroundColor: [
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              '#34CEA0',
              '#182B4D'
            ],
          },
        ],
      };
    },
  },
  mounted() {

  },
  methods: {
    Expandir(Grafica) {
      this.ActualizarGraficas(Grafica)
      this.ActualizarVista(Grafica)
    },
    ActualizarGraficas(Grafica) {
      if (Grafica == "Todas") {
        for (let Graf in this.Graficas) {
          this.Graficas[Graf] = true
          this.Tamanio = "300"
        }
        return
      }
      for (let Graf in this.Graficas) {
        this.Graficas[Graf] = false
      }

      if (Object.prototype.hasOwnProperty.call(this.Graficas, Grafica)) {
        this.Graficas[Grafica] = true
        this.Tamanio = "600"
      }
    },
    ActualizarVista(Grafica) {
      if (Grafica == "Todas") {
        for (let Graf in this.Pantalla) {
          this.Pantalla[Graf] = false
          this.Tamanio = "300"
        }
        return
      }
      for (let Graf in this.Pantalla) {
        this.Pantalla[Graf] = false
      }

      if (Object.prototype.hasOwnProperty.call(this.Pantalla, Grafica)) {
        this.Pantalla[Grafica] = true
        this.Tamanio = "600"
      }
    },

  },
};
</script>

<style scope lang="scss">
.PantallaCompleta {
  height: 85vh !important;
}

.Graficagrande canvas {
  height: 70vh !important;
}

.Grafichica canvas {
  height: 300px !important;
}
</style>
