<template>
  <router-link class="card mb-3" :to="{ path: RutaDestino }" @click.stop="Editar(encuesta)">
    <div class="card-body">
      <div class="position-absolute end-2">
        <router-link v-if="encuesta.borrado" :to="{}" class="p-2 mt-4 text-secondary" data-effect="fadeOut"
          @click.stop="Reactivar(encuesta)"><i class="fa fa-check"></i></router-link>
        <router-link v-else :to="{}" class="p-2 mt-4 text-secondary" data-effect="fadeOut"
          @click.stop="Borrar(encuesta)"><i class="fa fa-times"></i></router-link>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-6">
          <h4 class="card-title">{{ encuesta.encuesta.etiqueta }}</h4>
          <p class="card-text">Descripción: {{ encuesta.encuesta.descripcion }}</p>
          <a name="" id="" class="btn" href="#" role="button" @click.prevent="copiarLink(encuesta.vinculo)">
            <i class="fa fa-copy text-primary"></i>
          </a>
          <button v-if="linkCopiado" class="btn btn-success ms-2">Link copiado</button>
        </div>
        <div class="col-6">
          <a name="" id="" class="btn ms-4" href="#" role="button">
            <i class="fab fa-whatsapp text-primary"></i>
          </a>
          <a name="" id="" class="btn ms-4" href="#" role="button">
            <i class="far fa-envelope text-primary"></i>
          </a>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import Servicio from "@/api-services/encuestas.service";

export default {
  props: {
    encuesta: {
      type: Object,
      required: true
    }
  },
  emits: ["editar", "borrar", "reactivar"],
  data() {
    return {
      linkCopiado: false,
    };
  },
  computed: {
    RutaDestino() {
      return `${this.$route.path}/${this.encuesta.id}`;
    },
    MostrarBorrar() {
      return this.RegistroABorrar !== null;
    },
  },
  methods: {
    Editar(encuesta) {
      console.log("Editar", encuesta);
      this.$emit("editar", encuesta);
      console.debug(encuesta.encuesta.etiqueta);
    },
    Borrar(encuesta) {
      console.log("Borrar", encuesta);
      this.$emit("borrar", encuesta);
    },
    Reactivar(encuesta) {
      // console.log("reactivar", registro);
      this.$emit("reactivar", encuesta);
    },
    copiarLink(link) {
      navigator.clipboard.writeText(link)
        .then(() => {
          this.linkCopiado = true;
          setTimeout(() => {
            this.linkCopiado = false;
          }, 2000); // Ocultar el mensaje después de 2 segundos
        })
        .catch(err => {
          console.error('Error al copiar el enlace', err);
        });
    }
  }
};
</script>
