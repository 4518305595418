<template>
  <div>
    <Modal
    v-if="MuestraModal"
    :show="MuestraModal"
    modal-classes="modal-dialog-centered fondo-modal"
     @close="MuestraModal = false"
  >
    <template v-slot:header>
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <div class="w-100 text-center">
        <h3 class=" card-title  text-primary">Evaluaciones</h3>
        <h3 class=" text-primary"><i style="margin-right: 0.3rem;" class=" fa fa-star"></i>{{PromEval}}</h3>
      </div>
    </template>
    <template v-slot:body>
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <div class="p-3">
     <div style="border: 1px solid #f5f7f8; border-radius: 10px; background-color: #f5f7f8;" class="w-100">
        <p style="width: 80%;" class="mx-auto my-auto">{{Pregunta}}</p>
     </div>
     <div class="mt-2 w-100 text-center ">
        <h4 class="text-primary"> <i style="margin-right: 0.3rem;" class=" fas fa-comment fa-lg " ></i>Comentarios</h4>
     </div>
     <div class="mt-1">
        <div style="border: 1px solid #f5f7f8; border-radius: 10px; background-color: #f5f7f8;" class="w-100 d-flex">
            <div style="width: 15%;" class="d-flex justify-content-center">
                <img
            src="/img/default-avatar.png"
            alt="Sin foto"
            style="width: 80%; height:80%;"
            class="profile-img img-fluid rounded-circle my-auto"
          />
            </div>
            <div style="width: 85%;"  class="d-flex flex-column">
                <p class="m-0 text-muted">Fer</p>
                <div>
                    Estos son los comentarios
                </div>
            </div>
        </div>
     </div>

     <div class="mt-3">
        <MazInput v-model="NuevoComentario" label="Agrega un comentario" class="mb-3">
        <template #left-icon>
            <i  class=" fas fa-comment fa-lg " ></i>
        </template>
      </MazInput>
     </div>

     <MazBtn class="w-100 btn btn-success mt-3" @click="OnFiltrar">Comentar</MazBtn>
        <slot></slot>
      </div>
    </template>
    <slot> </slot>
  </Modal>
  </div>
</template>

<script>
import Modal from "@/Componentes/Modal.vue";
export default {
name: "ModalComentarioEval",
components: {
Modal,
},
props: {
PromEval:{type:String, default:null},
Pregunta:{type:String,  default:null},
Comentarios:{type:Array, default:null},
Mostrar: {type:Boolean, default:false},
},
emits:["update:Mostrar"],
watch: {
    MuestraModal(Valor){
        this.$emit("update:Mostrar",Valor)
    },
    Mostrar(Valor){
       this.MuestraModal = Valor
    },
},
data() {
return {
    MuestraModal:false,
    NuevoComentario:"",
}
},
beforeMount(){
    this.MuestraModal = this.Mostrar
},
mounted(){

},
methods:{
},
}
</script>

<style lang="scss" scoped>
.fondo-modal{
    background-color: #F6F7F5  !important;
}
</style>