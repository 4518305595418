<template>
  <div class="card p-3">
    <div class="row">
      <div :class="EstiloCampo">
        <MazInput
          v-model="Pregunta.nombre"
          label="Texto de la pregunta"
          color="secondary"
          autocomplete="off"
          class="px-2 mb-campo col-12 col-md-12 col-sm-4"
        />
        <router-link
          :to="{}"
          class="p-2 position-absolute top-0 end-0 p-3"
          data-effect="fadeOut"
          @click="Borrar"
        >
          <i class="fa fa-times text-primary"></i>
        </router-link>
        <MazCheckbox
          v-model="Pregunta.requerida"
          color="secondary"
          class="px-2 col-md-12 mb-campo"
        >
          <small>Pregunta obligatoria</small>
        </MazCheckbox>
        <MazCheckbox
          v-model="Pregunta.califica"
          color="secondary"
          class="px-2 col-md-12 mb-campo"
        >
          <small>Contará en la puntuación de tu colaborador</small>
        </MazCheckbox>
      </div>
      <div :class="EstiloCampo">
        <h6 class="text-primary">Tipo de calificación</h6>
        <MazRadio
          value="Rango"
          color="secondary"
          name="tipoPregunta"
          v-model="Pregunta.tipoPregunta"
        >
          De rango
        </MazRadio>
        <MazRadio
          value="Cerrada"
          color="secondary"
          name="tipoPregunta"
          v-model="Pregunta.tipoPregunta"
        >
          Cerrada (Si o No)
        </MazRadio>
        <MazRadio
          value="Abierta"
          color="secondary"
          name="tipoPregunta"
          v-model="Pregunta.tipoPregunta"
        >
          Abierta
        </MazRadio>
        <MazRadio
          value="Opciones"
          color="secondary"
          name="tipoPregunta"
          v-model="Pregunta.tipoPregunta"
        >
          Opciones
        </MazRadio>
      </div>
      <div v-if="Pregunta.tipoPregunta === 'Rango'" class="col-md-6">
        <div class="row">
          <!-- <div class="col-6">
            <h6 class="text-primary">¿Qué icono te gustaría utilizar?</h6>
            <MazRadio value="Emoticonos" name="tipoIcono" color="secondary" v-model="Pregunta.tipoIcono">
              <i class="fas fa-star"></i>
            </MazRadio>
            <MazRadio value="Estrellas" name="tipoIcono" color="secondary" v-model="Pregunta.tipoIcono">
              <i class="fas fa-smile-beam"></i>
            </MazRadio>
          </div>  -->
          <div class="col-6">
            <h6 class="text-primary">Rango máximo</h6>
            <MazRadio
              name="rangoMaximo"
              value="2"
              color="secondary"
              v-model="Pregunta.rangoMaximo"
              >2</MazRadio
            >
            <MazRadio
              name="rangoMaximo"
              value="3"
              color="secondary"
              v-model="Pregunta.rangoMaximo"
              >3</MazRadio
            >
            <MazRadio
              name="rangoMaximo"
              value="4"
              color="secondary"
              v-model="Pregunta.rangoMaximo"
              >4</MazRadio
            >
            <MazRadio
              name="rangoMaximo"
              value="5"
              color="secondary"
              v-model="Pregunta.rangoMaximo"
              >5</MazRadio
            >
            <MazSlider
              v-model="Pregunta.rangoComentarios"
              size="10px"
              color="secondary"
              :min="0"
              :max="5"
              :step="1"
              :title="customTooltips[index]"
              @mouseover="showTooltip = true"
              @mouseleave="showTooltip = false"
            />
            <br />
            <div class="tooltip-container" v-if="showTooltip">
              <span class="tooltip"
                >Elige a partir de qué calificación tus clientes deberán
                escribir un comentario.</span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="Pregunta.tipoPregunta === 'Opciones'"
        :class="EstiloCampo"
      >
        <div>
          <h6 class="text-primary">Opciones</h6>
          <MazInputTags v-model="ListaOpciones" color="secondary" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MazInput from "maz-ui/components/MazInput";
import MazCheckbox from "maz-ui/components/MazCheckbox";
import MazRadio from "maz-ui/components/MazRadio";
import MazSlider from "maz-ui/components/MazSlider";
import MazInputTags from "maz-ui/components/MazInputTags";

export default {
  components: {
    MazInput,
    MazCheckbox,
    MazRadio,
    MazSlider,
    MazInputTags,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    nuevoValor: {
      type: String,
      default: "",
    },
    NP: { type: Number, default: null },
  },
  emits: ["borrar", "ActualizaOpciones"],
  data() {
    return {
      ListaOpciones: [],
      Pregunta: {},
      localNombre: this.nombre,

      /* tags: ['tags 1', 'tags 2'], */

      tags: [],
      value: 0,
      customTooltips: ["Custom Tooltip 1"],
      showTooltip: false,
    };
  },

  computed: {
    Nombre() {
      return "Radio";
    },
    EstiloCampo() {
      return "col-lg-3 col-md-3 px-2 mb-campo";
    },
  },
  watch: {
    ListaOpciones(nuevoValor) {
      //Listado de opciones actuales
      var ListaOpciones = this.Pregunta.opciones.map((opcion) => opcion.nombre);

      //Identificar los que ya no están y eliminarlos de la lista de opciones

      const Eliminar = ListaOpciones.filter(
        (cadena) => !nuevoValor.includes(cadena)
      );
      console.debug("Estos son los elementos que se deben eliminar", Eliminar);

      this.Pregunta.opciones = this.Pregunta.opciones.filter(
        (opcion) => !Eliminar.includes(opcion.nombre)
      );

      //Identificar nuevos y agregarlos a la lista de opciones
      const Agregar = nuevoValor.filter(
        (cadena) => !ListaOpciones.includes(cadena)
      );
      console.debug("Estos son los elementos que se deben agregar", Agregar);
      Agregar.forEach((opcion) => {
        this.Pregunta.opciones.push({
          nombre: opcion,
          idiomas: [{ contenido: opcion, idioma: "es-ES" }],
        });
      });
    },

    "Pregunta.nombre": {
      handler(Valor) {
        if (this.Pregunta.contenido.length > 0) {
          this.Pregunta.contenido.forEach((idioma) => {
            if (idioma.idioma == "es-ES") {
              idioma.contenido = Valor;
            }
          });
        }
      },
      deep: true,
    },

    "Pregunta.contenido": {
      handler(Valor){
        console.debug("Cambio algo del contenido")
      },
      deep:true,
    },
  },

  beforeMount() {
    this.FormatoPregunta(this.modelValue);
    this.Pregunta = this.modelValue;
    console.debug("Este es el contenido de la pregunta", this.Pregunta);
    if (this.Pregunta.rangoMaximo != null) {
      this.Pregunta.rangoMaximo = this.Pregunta.rangoMaximo.toString();
    }
  },

  mounted() {},
  methods: {
    Borrar() {
      this.$emit("borrar", this.NP);
    },
    FormatoPregunta(valor) {
      if (valor == null) return {};
      // Verificar si rangoComentarios es nulo y establecerlo a 0 si es así
      if (valor.rangoComentarios == null) valor.rangoComentarios = 0;

      // Verificar si existen opciones, si no, devolver un objeto vacío
      if (!valor.opciones) valor.opciones = [];

      // Mapear las opciones y devolver solo los nombres
      this.ListaOpciones = valor.opciones.map((opcion) => opcion.nombre);
      console.debug(
        "Estas son las opciones",
        valor.opciones,
        this.ListaOpciones
      );
      return valor;
    },
    updateTooltip(event) {
      event.target.nextElementSibling.textContent = event.target.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-top: 1rem;
  text-decoration: underline;
}

.tooltip {
  visibility: hidden;
  width: 250px;
  background-color: #ffffff;
  border: 1px solid #2dcec6;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container .tooltip {
  visibility: visible;
  opacity: 1;
}
@media only screen and (min-width: 1200px) {
  .tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-top: 3rem;
    text-decoration: underline;
  }
}
</style>
