/** @format */

import Axios from "axios";
import LiAuth from "@/api-services/liauth.service";
import Usuarios from "@/api-services/usuarios.service";
import BaseService from "@/api-services/base.service";
import SuscripcionesUsuarios from "@/api-services/suscripciones.usuarios.service";
import Msal from "@/api-services/msal.service";
const isProd = process.env.NODE_ENV === "production";

import moment from "moment";
//var moment = require('moment');
moment.locale("es-mx");

function IsProd() {
  // return true;
  return isProd;
}
export default {
  MazPhoneNumber: {
    Traduccion: {
      countrySelectorLabel: "Código de país",
      countrySelectorError: "Erroneo",
      phoneNumberLabel: "Número de teléfono",
      example: "Ejemplo :",
      countrySelectorSearchPlaceholder: "Buscar País",
    },
    PaisesPreferidos: ["MX", "US", "DE"],
    PaisPredeterminado: "MEX",
  } /*
  TelefonoFormat(NoTelefono) {
    if (NoTelefono == null || NoTelefono == "")
      return "";
    if (NoTelefono.length <= 8) {
      var Pos = NoTelefono.length / 2;
      return NoTelefono.substring(0, Pos)+' ' + NoTelefono.substring(Pos);
    }
    if (NoTelefono.length <= 10) {
      var Pos = NoTelefono.length / 2;
      return NoTelefono.substring(0, 2) + ' ' + NoTelefono.substring(2,6) + ' ' + NoTelefono.substring(6);
    }

  },*/,
  get Version() {
    return BaseService.Version;
  },
  get AuthorityURL() {
    return BaseService.AuthorityURL;
  },
  get AuthURL() {
    return BaseService.AuthURL;
  },
  get URL() {
    return BaseService.URL;
  },
  get SignalRURL() {
    return BaseService.SignalRURL;
  },
  get EsSSO() {
    return BaseService.EsSSO;
  },
  set EsSSO(valor) {
    BaseService.EsSSO = valor;
  },
  watchPositionID: null,
  FechaJson2MesDia(FechaJson, Default) {
    var Fecha = FechaJson;

    //  console.debug(Fecha);
    if (Fecha == null || Fecha == "")
      if (Default == null) return "";
      else return Default;

    var SoloFecha = Fecha.split("T");
    return SoloFecha[0].substring(5, 10);
  },
  //URL: ((IsProd()) ? "https://apiv6.eclock.com.mx/api" : "https://debug.apiv6.eclock.com.mx/api"),
  // UrlSignalR: ((IsProd()) ? "https://apiv6.eclock.com.mx/SignalRHub" : "https://apiv6.eclock.com.mx/SignalRHub"),
  //Transforma una fecha que priene de una WebApi de Json a Solo Fecha
  //Ej. 1967-10-25T00:00:00 a 1967-10-25
  //Si no tiene valor default regresa T
  FechaJson2SoloFecha(FechaJson, Default) {
    var Fecha = FechaJson;

    //  console.debug(Fecha);
    if (Fecha == null || Fecha == "")
      if (Default == null) return "T";
      else return Default;
    var SoloFecha = Fecha.split("T");
    return SoloFecha[0];
  },
  //Transforma de Solo fecha a un objeto fecha Json
  //Ej 1967-10-25 a 1967-10-25T00:00:00
  SoloFecha2FechaJson(SoloFecha) {
    if (SoloFecha == null || SoloFecha == "" || SoloFecha == "T") return null;
    var NuevaFecha = `${SoloFecha}T00:00:00`;
    //console.debug(NuevaFecha);

    return NuevaFecha;
  },
  fechaAddMinutos(Fecha, Minutos) {
    var R = new Date(Fecha.getTime() + Minutos * 60000);
    return R;
  },
  fechaAddSegundos(Fecha, Segundos) {
    //  console.debug(`Fecha = ${Fecha} ${Segundos}`);
    //4console.debug(`Fecha.getTime()  = ${Fecha.getTime()}, ${Segundos}`);
    //console.debug(`Fecha.getTime()  = ${Fecha.getTime()}`);
    var R = new Date(Fecha.getTime() + Segundos * 1000);
    //console.debug(`R = ${R}`);
    return R;
  },

  ///Obtiene la fecha y hora en string con timezon local
  sFechaHora(FechaHoraString) {
    var desde = this.fecha(FechaHoraString);
    return `${desde.toLocaleString()}`;
  },
  ///Obtiene la fecha  en string con timezon local
  sFecha(FechaHoraString) {
    var desde = this.fecha(FechaHoraString);
    return desde.toLocaleDateString();
  },
  ///Obtiene la  hora en string con timezon local
  sHora(FechaHoraString) {
    var desde = this.fecha(FechaHoraString);
    return desde.toLocaleTimeString();
  },
  ///Convierte una Fecha (solo fecha ) de D/M/Y a date
  fechaDMY(FechaString) {
    var Componentes = FechaString.split("-");
    if (Componentes.length < 3) Componentes = FechaString.split("/");
    if (Componentes.length < 3) return new Date(FechaString);
    return new Date(Componentes[2], Componentes[1] - 1, Componentes[0]);
  },
  fecha(FechaString) {
    var Fecha = "";
    Fecha = Fecha + FechaString;
    if (!Fecha.includes("Z")) Fecha = Fecha + "Z";
    return new Date(Fecha);
  },
  formatoFecha(FechaString) {
    var LaFecha = this.fecha(FechaString);
    var todayDate = LaFecha.toISOString();
    //  console.debug(todayDate.slice(11, 16));
    return `${todayDate.slice(0, 10)} ${todayDate.slice(11, 16)}`;
  },
  periodo(FechaDesde, FechaHasta) {
    var FD = this.fecha(FechaDesde);
    var FH = this.fecha(FechaHasta);
    return `${FD.toLocaleString()} - ${FH.toLocaleString()}`;
  },
  /*
   * hace get a un endpoint y solo recibe la subruta
   * */
  getBaseSuscripcion(Vista, SubRuta, OnCorrecto, OnErroneo, OnFinalizado) {
    return this.get(Vista, this.ObtenURLSuscripcion(SubRuta), null, OnCorrecto, OnErroneo, OnFinalizado);
  },

  /*
 
   *     public class BaseGetsListado
    {
        /// <summary>
        /// Indica si se incluirán los borrados
        /// </summary>
        public bool Borrados { get; set; }
        /// <summary>
        /// Pagina a obtener, empieza desde 0
        /// </summary>
        public int PaginaNo { get; set; } = 0;
        /// <summary>
        /// Longitud de la página default 25
        /// </summary>
        public int PaginaLen { get; set; } = 25;
    }}
   * */
  getsBaseSuscripcion(Vista, SubRuta, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = this.ObtenURLSuscripcion(SubRuta);
    /*var queryString = Object.keys(BaseGetsListado).map(key => key + '=' + BaseGetsListado[key]).join('&');
    URL = `${URL}?${queryString}`;*/
    return this.get(Vista, URL, { params: BaseGetsListado }, OnCorrecto, OnErroneo, OnFinalizado);
  },
  /**
   * Agraga un nuevo registro
   * @param {*} Vista
   * @param {*} SubRuta
   * @param {*} Cuerpo
   * @param {*} Opciones
   * @param {*} OnCorrecto
   * @param {*} OnErroneo
   * @param {*} OnFinalizado
   * @param {*} SuscripcionID si esta vacio obtiene la suscriocionID actual
   * @returns
   */
  postBaseSuscripcion(Vista, SubRuta, Cuerpo, Opciones, OnCorrecto, OnErroneo, OnFinalizado, SuscripcionID) {
    var URL = this.ObtenURLSuscripcion(SubRuta, SuscripcionID);
    return this.post(Vista, URL, Cuerpo, Opciones, OnCorrecto, OnErroneo, OnFinalizado);
  },
  putBaseSuscripcion(Vista, SubRuta, Cuerpo, Opciones, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = this.ObtenURLSuscripcion(SubRuta);
    return this.put(Vista, URL, Cuerpo, Opciones, OnCorrecto, OnErroneo, OnFinalizado);
  },
  patchBaseSuscripcion(Vista, SubRuta, Cuerpo, Opciones, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = this.ObtenURLSuscripcion(SubRuta);
    return this.patch(Vista, URL, Cuerpo, Opciones, OnCorrecto, OnErroneo, OnFinalizado);
  },
  deleteBaseSuscripcion(Vista, SubRuta, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = this.ObtenURLSuscripcion(SubRuta);
    //console.debug(OnCorrecto);
    return this.delete(Vista, URL, OnCorrecto, OnErroneo, OnFinalizado);
  },
  ObtenErrorMsg(error) {
    var MsgError = "";
    // console.debug(error);
    if (error.response == undefined) {
      MsgError = "Intente nuevamente en un par de minutos";
    } else
      switch (error.response.status) {
        case 400:
          {
            if (error.response.data) {
              if (error.response.data.title) MsgError = error.response.data.title;
              else MsgError = error.response.data;
            } else MsgError = "Error desconocido en el servidor, contacte a soporte técnico";
          }
          break;
        case 401:
          //No borraré la sesión actual para validar
          //localStorage.sesion = ""; // error.response.data;
          MsgError = "Sesión no válida, intente iniciar sesión nuevamente";
          setTimeout(() => {
            window.location.href = "/login";
          }, 3000);
          break;
        case 402:
          MsgError = "Se requiere pago";
          break;
        case 404:
          MsgError = "No encontrado";
          break;
        case 409:
          MsgError = error.response.data
          break;
        case 500:
          MsgError = "Error en servidor, contacte a soporte con los pasos que siguio";
          break;
        default:
          if (
            error.response === undefined ||
            error.response.data === undefined ||
            error.response.data.error === undefined
          ) {
            console.error(error);
            MsgError = "Intente nuevamente";
          } else {
            console.error(error.response.data.error);
            MsgError = error.response.data.error.mensaje;
          }
          break;
      }

    return MsgError;
  },
  /*
   * Vista = la pagina de Vue actual, se utiliza para alertar sobre los errores y más
   * Opciones pueden contener
   * {
   * params:{}
   * headers:{}
   *responseType: 'blob'
   * }
   *
   * */
  get(Vista, URL, Opciones, OnCorrecto, OnErroneo, OnFinalizado) {
    const Comando = "GET";

    this.SeguimientoIniciar(Vista, URL, Comando);
    // si no es SSO o no tiene parámetro de la vista
    if (!this.EsSSO || !Vista) return this.getBase(Vista, URL, Opciones, OnCorrecto, OnErroneo, OnFinalizado);
    Msal.ActualizaToken((Token) => {
      return this.getBase(Vista, URL, Opciones, OnCorrecto, OnErroneo, OnFinalizado);
    });
  },
  getBase(Vista, URL, Opciones, OnCorrecto, OnErroneo, OnFinalizado) {
    const Comando = "GET";

    Opciones = this.ObtenOpciones(Opciones);
    //  console.debug(Opciones);
    Axios.get(URL, Opciones)
      .then((response) => {
        if (OnCorrecto != undefined) OnCorrecto(response);
      })
      .catch((error) => {
        this.SeguimientoError(Vista, OnErroneo, URL, Comando, error);
      })
      .finally(() => {
        this.SeguimientoFinalizar(Vista, URL, Comando);
        if (OnFinalizado != undefined) OnFinalizado();
      });
  },
  post(Vista, URL, Datos, Opciones, OnCorrecto, OnErroneo, OnFinalizado) {
    const Comando = "POST";

    this.SeguimientoIniciar(Vista, URL, Comando);
    // si no es SSO o no tiene parámetro de la vista
    if (!this.EsSSO || !Vista) return this.postBase(Vista, URL, Datos, Opciones, OnCorrecto, OnErroneo, OnFinalizado);
    Msal.ActualizaToken((Token) => {
      return this.postBase(Vista, URL, Datos, Opciones, OnCorrecto, OnErroneo, OnFinalizado);
    });
  },
  postBase(Vista, URL, Datos, Opciones, OnCorrecto, OnErroneo, OnFinalizado) {
    const Comando = "POST";

    Opciones = this.ObtenOpciones(Opciones);

    Axios.post(URL, Datos, Opciones)
      .then((response) => {
        if (OnCorrecto != undefined) OnCorrecto(response);
      })
      .catch((error) => {
        this.SeguimientoError(Vista, OnErroneo, URL, { Comando: Comando, Datos: Datos, Opciones: Opciones }, error);
      })
      .finally(() => {
        this.SeguimientoFinalizar(Vista, URL, Comando);
        if (OnFinalizado != undefined) OnFinalizado();
      });
  },
  patch(Vista, URL, Datos, Opciones, OnCorrecto, OnErroneo, OnFinalizado) {
    var Comando = "PATCH";

    this.SeguimientoIniciar(Vista, URL, Comando);

    // si no es SSO o no tiene parámetro de la vista
    if (!this.EsSSO || !Vista) return this.patchBase(Vista, URL, Datos, Opciones, OnCorrecto, OnErroneo, OnFinalizado);
    Msal.ActualizaToken((Token) => {
      return this.patchBase(Vista, URL, Datos, Opciones, OnCorrecto, OnErroneo, OnFinalizado);
    });
  },
  patchBase(Vista, URL, Datos, Opciones, OnCorrecto, OnErroneo, OnFinalizado) {
    var Comando = "PATCH";

    Opciones = this.ObtenOpciones(Opciones);

    Axios.patch(URL, Datos, Opciones)
      .then((response) => {
        if (OnCorrecto != undefined) OnCorrecto(response);
      })
      .catch((error) => {
        this.SeguimientoError(Vista, OnErroneo, URL, Comando, error);
      })
      .finally(() => {
        this.SeguimientoFinalizar(Vista, URL, Comando);
        if (OnFinalizado != undefined) OnFinalizado();
      });
  },
  put(Vista, URL, Datos, Opciones, OnCorrecto, OnErroneo, OnFinalizado) {
    var Comando = "PUT";

    this.SeguimientoIniciar(Vista, URL, Comando);

    // si no es SSO o no tiene parámetro de la vista
    if (!this.EsSSO || !Vista) return this.putBase(Vista, URL, Datos, Opciones, OnCorrecto, OnErroneo, OnFinalizado);
    Msal.ActualizaToken((Token) => {
      return this.putBase(Vista, URL, Datos, Opciones, OnCorrecto, OnErroneo, OnFinalizado);
    });
  },
  putBase(Vista, URL, Datos, Opciones, OnCorrecto, OnErroneo, OnFinalizado) {
    var Comando = "PUT";

    Opciones = this.ObtenOpciones(Opciones);
    /*   console.debug(URL);
    console.debug(JSON.stringify(Datos));
    */
    Axios.put(URL, Datos, Opciones)
      .then((response) => {
        if (OnCorrecto != undefined) OnCorrecto(response);
      })
      .catch((error) => {
        this.SeguimientoError(Vista, OnErroneo, URL, { Comando: Comando, Datos: Datos, Opciones: Opciones }, error);
      })
      .finally(() => {
        this.SeguimientoFinalizar(Vista, URL, Comando);
        if (OnFinalizado != undefined) OnFinalizado();
      });
  },
  deleteParams(Vista, URL, Params, OnCorrecto, OnErroneo, OnFinalizado) {
    var Comando = "DELETE";

    this.SeguimientoIniciar(Vista, URL, Comando);
    // si no es SSO o no tiene parámetro de la vista
    if (!this.EsSSO || !Vista) return this.deleteParamsBase(Vista, URL, Params, OnCorrecto, OnErroneo, OnFinalizado);
    Msal.ActualizaToken((Token) => {
      return this.deleteParamsBase(Vista, URL, Params, OnCorrecto, OnErroneo, OnFinalizado);
    });
  },
  deleteParamsBase(Vista, URL, Params, OnCorrecto, OnErroneo, OnFinalizado) {
    var Comando = "DELETE";

    var Opciones = this.ObtenOpciones({ params: Params });

    Axios.delete(URL, Opciones)
      .then((response) => {
        // console.debug(OnCorrecto);
        if (OnCorrecto != undefined) OnCorrecto(response);
      })
      .catch((error) => {
        this.SeguimientoError(Vista, OnErroneo, URL, Comando, error);
      })
      .finally(() => {
        this.SeguimientoFinalizar(Vista, URL, Comando);
        if (OnFinalizado != undefined) OnFinalizado();
      });
  },
  deleteBodyBase(Vista, URL,Datos, Params, OnCorrecto, OnErroneo, OnFinalizado) {

    console.debug("Asi llega ",Datos)
    
    var Comando = "DELETE";

    var Opciones = this.ObtenOpciones({ params: Params, data:Datos });

    Axios.delete(URL, Opciones)
      .then((response) => {
        // console.debug(OnCorrecto);
        if (OnCorrecto != undefined) OnCorrecto(response);
      })
      .catch((error) => {
        this.SeguimientoError(Vista, OnErroneo, URL, Comando, error);
      })
      .finally(() => {
        this.SeguimientoFinalizar(Vista, URL, Comando);
        if (OnFinalizado != undefined) OnFinalizado();
      });
  },

  delete(Vista, URL, OnCorrecto, OnErroneo, OnFinalizado) {
    var Comando = "DELETE";

    this.SeguimientoIniciar(Vista, URL, Comando);

    // si no es SSO o no tiene parámetro de la vista
    if (!this.EsSSO || !Vista) return this.deleteBase(Vista, URL, OnCorrecto, OnErroneo, OnFinalizado);
    Msal.ActualizaToken((Token) => {
      return this.deleteBase(Vista, URL, OnCorrecto, OnErroneo, OnFinalizado);
    });
  },
  deletebody(Vista, URL,Datos,Params, OnCorrecto, OnErroneo, OnFinalizado) {

    console.debug("Asi llega el body ",Datos)
    var Comando = "DELETE";

    this.SeguimientoIniciar(Vista, URL, Comando);

    // si no es SSO o no tiene parámetro de la vista
    if (!this.EsSSO || !Vista) return this.deleteBodyBase(Vista, URL,Datos,Params, OnCorrecto, OnErroneo, OnFinalizado);
    Msal.ActualizaToken((Token) => {
      return this.deleteBodyBase(Vista, URL,Datos,Params, OnCorrecto, OnErroneo, OnFinalizado);
    });
  },
  deleteBase(Vista, URL, OnCorrecto, OnErroneo, OnFinalizado) {
    var Comando = "DELETE";

    this.SeguimientoIniciar(Vista, URL, Comando);

    var Opciones = this.ObtenOpciones({});

    Axios.delete(URL, Opciones)
      .then((response) => {
        // console.debug(OnCorrecto);
        if (OnCorrecto != undefined) OnCorrecto(response);
      })
      .catch((error) => {
        this.SeguimientoError(Vista, OnErroneo, URL, Comando, error);
      })
      .finally(() => {
        this.SeguimientoFinalizar(Vista, URL, Comando);
        if (OnFinalizado != undefined) OnFinalizado();
      });
  },
  ObtenOpciones(Opciones) {
    if (Opciones == null) {
      Opciones = LiAuth.header();
    } else {
      if (Opciones.headers == null) {
        Opciones.headers = LiAuth.header().headers;
      } else {
        /*      var LH = LiAuth.header();
              var Headers = LH.headers;
              var Propiedades = Object.keys(Headers);
              console.debug(Headers);*/
        var Headers = LiAuth.header().headers;
        for (var name in Headers) {
          //  alert(name);
          var value = Headers[name];
          //   console.debug(value);
          Opciones.headers[name] = value;
          //alert(value);
        }
        /*
                for (var i = 0; i < Propiedades.length; i++) {
                  console.debug(Object[Propiedades[i]]);
                 // Opciones.headers.push(Headers[i]);
                }*/
      }
    }

    //console.debug(Opciones);
    return Opciones;
  },
  SeguimientoIniciar(Vista, URL, Comando) {
    if (!isProd) {
      console.debug(
        JSON.stringify({
          Estado: "Iniciando",
          name: Vista != null ? Vista.$options.name : null,
          URL: URL,
          Comando: Comando,
        })
      );
    }
    if (Vista != null) {
      if (Usuarios.PerfilLocal != null) {
        ///https://github.com/Microsoft/ApplicationInsights-JS/issues/571
        //El usuario seleccioinado, no estoy seguro de la opción correcta
        //Vista.$appInsights.context.user.id = `${UsuarioPerfil.usuario.usuarioID}`;
        //Vista.$appInsights.context.user.userId = `${UsuarioPerfil.usuario.usuarioID}`;
        if (Vista.$appInsights)
          Vista.$appInsights.context.user.user_AuthenticatedId = `${Usuarios.PerfilLocal.usuario.usuarioID}`;
      }
      //La suscripción seleccionada
      if (Vista.$appInsights) {
        Vista.$appInsights.context.user.accountId = localStorage.suscripcionID;

        Vista.$appInsights.trackEvent({ name: Vista.$options.name }, { URL: URL, Comando: Comando });
      }
      if (Vista.cargando != undefined) Vista.cargando = true;
      if (Vista.MsgError != undefined) Vista.MsgError = "";
    }
  },
  SeguimientoError(Vista, OnErroneo, URL, Comando, Error) {
    var status = 0;
    //  console.debug(Comando);
    console.error(Error);
    var MsgError = this.ObtenErrorMsg(Error);

    if (OnErroneo != undefined) {
      var R = OnErroneo(MsgError, Error);
      // console.debug(R);
      if (R != null) MsgError = R;
    }

    if (Error.response != undefined) status = Error.response.status;
    if (Vista) {
      if (Vista.$appInsights)
      Vista.$appInsights.trackEvent(
        { name: Vista.$options.name },
        {
          URL: URL,
          Comando: Comando,
          ErrorMsg: MsgError,
          ErrorStatus: status,
          Error: Error,
        }
      );
      Vista.$notify({ type: "danger", title: MsgError });

      if (Vista.MsgError != undefined) Vista.MsgError = MsgError;
    }
  },
  SeguimientoFinalizar(Vista, URL, Comando, Error) {
    if (!isProd) {
      console.debug(
        JSON.stringify({
          Estado: "Finalizado",
          name: Vista != null ? Vista.$options.name : null,
          URL: URL,
          Comando: Comando,
        })
      );
    }
    if (Vista != null) {
      //Vista.$notify({ type: 'info', title: "Finalizar Vista" });
      if (Vista.cargando != undefined) Vista.cargando = false;
      /*   const container = document.querySelector('.main-panel');
   container.scrollTop = 0;*/
      //      SeguimientoFinalizar
    }
  },
  ObtenURLSuscripcion(SubRuta, SuscripcionID) {
    
    var Suscripcion = SuscripcionID == null ? SuscripcionesUsuarios.getSuscripcionID() : SuscripcionID; // localStorage.suscripcionID;

    if(SubRuta.includes("{{SuscripcionID}}"))
      {
        return SubRuta.replace("{{SuscripcionID}}", Suscripcion);
      }
    return `${this.URL}/Suscripciones/${Suscripcion}/${SubRuta}`;
  },

  ObtenIcono(Llave) {
    switch (Llave) {
      case "puestos":
      case "Puestos":
        return "fas fa-user-md";
      case "Departamentos":
      case "departamentos":
        return "fas fa-sitemap";
      case "Grupos":
      case "grupos":
        return "fas fa-users";
      case "CentrosCostos":
      case "centrosCostos":
        return "fas fa-dollar-sign";
      case "Sucursales":
      case "sucursales":
        return "fas fa-building";
      case "Empresas":
      case "empresas":
        return "fas fa-registered";
      case "Turnos":
      case "turnos":
        return "fas fa-clock";
      case "TurnosDirios":
      case "turnosDirios":
        return "fas fa-clock";
      case "Ubicaciones":
      case "ubicaciones":
        return "fas fa-map-marker-alt";
      case "Dispositivos":
      case "dispositivos":
        return "fas fa-fingerprint";
      case "TiposAccesos":
      case "tiposAccesos":
        return "fas fa-sign-in-alt";
      case "TiposIncidencias":
      case "tiposIncidencias":
        return "fas fa-car-crash";
      case "TiposDiarios":
      case "tiposDiarios":
        return "tim-icons icon-time-alarm";
      case "TiposRetardos":
      case "tiposRetardos":
        return "fas fa-clock";
      case "Regiones":
      case "regiones":
        return "fas fa-city";
      case "Gestiones":
      case "gestiones":
        return "fas fa-columns";
      case "Divisiones":
      case "divisiones":
        return "fas fa-project-diagram";
      case "ReglasVacaciones":
      case "reglasVacaciones":
        return "fas fa-umbrella-beach";
      case "TiposNominas":
      case "tiposNominas":
        return "fas fa-money-check-alt";
      case "TiposContratos":
      case "tiposContratos":
        return "fas fa-file-signature";
      case "TiposTrabajadores":
      case "tiposTrabajadores":
        return "fas fa-street-view";
      case "Generos":
      case "generos":
        return "fas fa-restroom";
      case "EstadosCiviles":
      case "estadosCiviles":
        return "fas fa-vest";
      case "GradosEstudios":
      case "gradosEstudios":
        return "fas fa-user-graduate";
    }
    return "";
  },
  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  },

  Response2FileDownload(response) {
    //No hay Datos
    if (response.status == 204) {
      return;
    }
    //console.debug(response.headers["content-type"]);

    //console.debug(response);
    var contentDisposition = response.headers["content-disposition"];
    var filename = contentDisposition.split(";")[1].split("=")[1].replace('"', "").replace('"', "");

    //console.debug(filename);

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);

    // console.debug("Creado Link");
    //link.download = label;

    link.setAttribute("download", filename);
    document.body.appendChild(link);

    link.click();
    // console.debug("Finalizado");
  },
  ObtenElemento(ID, Catalogo) {
    var C = Catalogo.filter((x) => x.id == ID);
    if (C.length > 0) return C[0];
    return null;
  },
  Moneda(Valor) {
    let pesoMX = Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      useGrouping: true,
      // maximumSignificantDigits: 2,
    });
    return pesoMX.format(Valor);
  },
  //Formato de fecha y hora con nombre de día
  fFechaHoraDia(fecha) {
    return this.fFechaDetalle(fecha, "ddd, DD/MMM/YY, h:mm:ss a");
  },
  fFechaDia(fecha) {
    return this.fFechaDetalle(fecha, "ddd, DD/MMM/YY");
  },
  //Formato de fecha
  fFecha(fecha) {
    return this.fFechaDetalle(fecha, "DD/MMM/YYYY");
  },
  //Formato de fecha
  fFechaDetalle(fecha, Formato) {
    var Fecha = moment(fecha);
    return Fecha.format(Formato);
  },
  fHora(FechaHora) {
    var Entrada = "";
    if (FechaHora && FechaHora != "0001-01-01T00:00:00+00:00") {
      var Fecha = moment(FechaHora);
      Entrada = Fecha.format("H:mm");
    }
    return Entrada;
  },
  ObtenSinPunto(ID) {
    if (ID == null) return null;
    return ID.replace(",", "");
  },
  AgregaSeparador(Cadena, CadenaAdicional, Separador) {
    if (Cadena == null || Cadena == "") Cadena = "";
    else Cadena = `${Cadena}${Separador}`;
    return `${Cadena}${CadenaAdicional}`;
  },
  ObtenerRutaSinUltimoSegmento(path) {
    // Dividir el path por "/" para obtener sus segmentos.
    const segmentos = path.split('/');
  
    // Eliminar el último segmento. Nota: No hacer nada si el path es solo "/".
    if (segmentos.length > 1) {
      segmentos.pop();
    }
  
    // Unir los segmentos restantes en una cadena, precedidos por "/".
    const rutaResultante = segmentos.join('/') || '/';
  
    return rutaResultante;
  }
};
