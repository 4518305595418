/** @format */

import Base from "@/api-services/base";

import YaCalificaMe from "@/api-services/yaCalifica.me.service.js";
const BaseURL = `${YaCalificaMe.URL}/Idiomas`;

export default {
 

  get(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.get(
      Vista,
      `${BaseURL}`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },

  post(Vista,Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.post(
      Vista,
      `${BaseURL}`,
      Datos,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },

  put(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.put(
      Vista,
      `${BaseURL}`,
      Datos,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
 
};
