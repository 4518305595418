<template>
  <div>
    <li
      class="nav-item dropdown d-flex align-items-center"
      :class="isRTL ? 'ps-2' : 'pe-2'"
    >
      <a
        id="dropdownMenuButton"
        href="#"
        :class="`p-0 nav-link ${
          isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
        } ${showMenu ? 'show' : ''}`"
        data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
        aria-expanded="false"
        @click="showMenu = true"
      >
        <i class="cursor-pointer fa fa-plus text-primary"></i>
      </a>
      <div
        style="overflow-y: auto; max-height: 40vh"
        class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
        :class="showMenu ? 'show' : ''"
        aria-labelledby="dropdownMenuButton"
      >
        <p>Busca un idioma</p>
        <MazInput
          class="mb-5"
          v-model="Busqueda"
          color="secondary"
          left-icon="banknotes"
          placeholder="Buscar"
        >
          <template #left-icon>
            <div class="">
              <i class="fas fa-search p-2"></i>
            </div>
          </template>
        </MazInput>

        <button
          v-for="Pais in PaisesDispo"
          :key="Pais.iso"
          class="btn w-100"
          :disabled="IdiomaElegido(Pais.iso)"
          @click.prevent.stop="ElegirPais(Pais)"
        >
          {{ Pais.Bandera + " " + Pais.Nombre }}
        </button>
      </div>
    </li>
  </div>
</template>

<script>
import { countryCodeToUnicodeFlag } from "maz-ui";
import { getCountries } from "libphonenumber-js";
import locale from "locale-codes";
export default {
  name: "NavbarIdioma",
  components: {},
  props: {
    Idioma: { type: String, default: "" },
    Info: { type: Object, default: null },
    IdiomasElegidos:{type:Array, default:null},
  },
  emits: ["AgregarIdioma"],
  data() {
    return {
      Preguntas: [],
      ListaPaises: [],
      PaisesDispo: [],
      Busqueda: null,
      showMenu:false
    };
  },
  watch: {
    Busqueda(Valor) {
      console.debug("Este es el valor", Valor);
      if (Valor == null || Valor == "") {
        this.PaisesDispo = structuredClone(this.ListaPaises);
      }

      this.PaisesDispo = this.ListaPaises.filter((x) =>
        this.PalabraNormalizada(x.Nombre).includes(
          this.PalabraNormalizada(Valor)
        )
      );
    },
  },
  beforeMount() {
    this.ObtenPaises(window.navigator.language);
    /* for (let index = 0; index < this.Info.length; index++) {
      let pregunta = this.Info[index].contenido.filter(
        (x) => x.idioma == this.Idioma
      )[0];
      this.Preguntas.push(pregunta);
    } */
  },
  mounted() {
    let dropdownButton = document.getElementById("dropdownMenuButton");
    dropdownButton.addEventListener("show.bs.dropdown", (event) => {
      event.relatedTarget.setAttribute("data-bs-auto-close", "inside");
    });
  },
  methods: {
    ObtenPaises(Idioma) {
      //listado de paises e idiomas
      //   let listaIdioma = locale.all
      // let idiomas = listaIdioma.filter(x => x.tag.includes("-"))
      // console.debug("Esta es la lista que cumple con el formato", idiomas)
      // console.debug("Esta es la lista de idiomas: ", locale.all )
      //  this.ListaPaises = idiomas.map(({tag,name,local}) => ({["iso"] : tag, ["Nombre"]: local + `(${name})`,["Bandera"]:countryCodeToUnicodeFlag(tag.split("-")[0])}))
      //  this.PaisesDispo = structuredClone(this.ListaPaises);

      //listado de paises

      // let iso = getCountries();
      // for (let index = 0; index < iso.length; index++) {
      //   let Pais = new Intl.DisplayNames([Idioma ?? "es-ES"], {
      //     type: "region",
      //   }).of(iso[index]);
      //   if (Pais) {
      //     this.ListaPaises.push({
      //       iso: iso[index] + "-" + iso[index],
      //       Nombre: Pais,
      //       Bandera: countryCodeToUnicodeFlag(iso[index]),
      //     });
      //   }
      // }
      // this.PaisesDispo = structuredClone(this.ListaPaises);

      this.ListaPaises = [
        {
          iso: "es-ES",
          Nombre: "Español",
          Bandera: countryCodeToUnicodeFlag("MX"),
        },
        {
          iso: "en-US",
          Nombre: "Ingles",
          Bandera: countryCodeToUnicodeFlag("US"),
        },
        {
          iso: "fr-FR",
          Nombre: "Frances",
          Bandera: countryCodeToUnicodeFlag("FR"),
        },
        {
          iso: "it-IT",
          Nombre: "Italiano",
          Bandera: countryCodeToUnicodeFlag("IT"),
        },
        {
          iso: "pt-PT",
          Nombre: "Portuges",
          Bandera: countryCodeToUnicodeFlag("PT"),
        },
        {
          iso: "zh-CN",
          Nombre: "Chino",
          Bandera: countryCodeToUnicodeFlag("CN"),
        },
        {
          iso: "ja-JP",
          Nombre: "Japones",
          Bandera: countryCodeToUnicodeFlag("JP"),
        },
        {
          iso: "ru-RU",
          Nombre: "Ruso",
          Bandera: countryCodeToUnicodeFlag("RU"),
        },
      ];
      this.PaisesDispo = structuredClone(this.ListaPaises);
    },

    ElegirPais(Pais) {
      this.$emit("AgregarIdioma", Pais.iso);
      this.$notify({ type: "success", title: `Idioma ${Pais.Nombre} agregado` });
      this.showMenu = false
    },
    PalabraNormalizada(Cadena) {
      return Cadena.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    },
    IdiomaElegido(Codigo){
      if (this.IdiomasElegidos.includes(Codigo)) {
        return true
      }
      return false
    },
    /* ObtenBandera(Pais) {
      if (Pais == "es-ES") {
        let bandera = "MX";
        return countryCodeToUnicodeFlag(bandera);
      }

      let bandera2 = Pais.split("-")[1];
      return countryCodeToUnicodeFlag(bandera2);
    }, */
  },
};
</script>

<style lang="scss" scoped></style>
