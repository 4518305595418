<template>
  <li
    class="nav-item d-flex align-items-center"
    :class="isRTL ? 'ps-2' : 'pe-2'"
  >
    <a
      id="dropdownMenuButton"
      href="#"
      :class="`p-0 nav-link ${
        isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
      } ${showMenu ? 'show' : ''}`"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click="showMenu = !showMenu"
    >
      <i class="fa-2x fas fa-eye text-primary"></i>
    </a>
    <ul
      class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4 border"
      :class="showMenu ? 'show' : ''"
      aria-labelledby="dropdownMenuButton"
    >
      <li class="mb-2">
        <div style="background-color: #2dce89; width: 100%; height: 20%">
          <div class="d-flex justify-content-center">
            <div class="position-relative d-inline-block">
              <img
                :src="
                  localUrlMiniatura
                    ? localUrlMiniatura
                    : '/img/default-avatar.png'
                "
                alt="No hay foto-"
                class="profile-img img-fluid"
              />
              <router-link
                class="bg-white edit-btn m-0 rounded-circle text-primary"
                to="/editafoto"
              >
                <i
                  class="fas fa-pencil-alt"
                ></i>
              </router-link>
            </div>
          </div>
        </div>

        <p class="text-center text-muted">Colaborador</p>
        <p class="text-center">Nombre empresa</p>
        <div class="card">
          <MenuIdiomas />
        </div>
        <!-- Inicia seccion epuntuacion y evaluaciones -->
        <div class="row border rounded">
          <div class="col border border-4">
            <div class="container">
              <div class="row">
                <div class="col text-center">
                  <i class="fa fa-star"></i>
                </div>
              </div>
              <div class="row">
                <div class="col text-center ">
                  <p>Puntuación</p>
                </div>
              </div>
              <div class="row">
                <div class="col text-center text-primary">
                  <p>4.5</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col border border-4">
            <div class="container">
              <div class="row">
                <div class="col text-center">
                  <i style="color: #2dce89" class="fa fa-user-check"></i>
                </div>
              </div>
              <div class="row">
                <div class="col text-center">
                  <p>Evaluaciones</p>
                </div>
              </div>
              <div class="row">
                <div class="col text-center">
                  <p style="color: #2dce89">134</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Termina seccion epuntuacion y evaluaciones -->

        <div class="nav-wrapper end-0">
          <div class="container justify-content-center">
            <ul class="nav nav-pills nav-fill p-1" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link mb-0 px-0 py-1 active active"
                  data-bs-toggle="tab"
                  href=""
                  role="tab"
                  aria-selected="true"
                >
                  <img
                    src="@/assets/img/logosya/flag.png"
                    alt=""
                    width="30"
                    height="30"
                  />
                  Español</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link mb-0 px-0 py-1"
                  data-bs-toggle="tab"
                  href="#"
                  role="tab"
                  aria-selected="false"
                >
                  <img
                    src="@/assets/img/logosya/united-states-of-america.png"
                    alt=""
                    width="30"
                    height="30"
                  />
                  Ingles</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link mb-0 px-0 py-1"
                  data-bs-toggle="tab"
                  href=""
                  role="tab"
                  aria-selected="false"
                >
                  <img
                    src="@/assets/img/logosya/japan.png"
                    alt=""
                    width="30"
                    height="30"
                  />
                  Japones</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="container mt-1" style="background-color: #e9ecef">
          <div
            class="row mt-4 bordered rounded"
            style="background-color: #ffffff"
          >
            <div class="col-12">
              <p>¿Cómo calificarías la calidad de nuestro servicio?</p>
              <i v-for="star in 5" :key="star" class="fa fa-star"></i>
              <p>
                ¿Cómo calificarías la limpieza y presentación de nuestras
                instalaciones
              </p>
              <i v-for="star in 5" :key="star" class="fa fa-star"></i>
              <p>
                ¿La eficiencia de Alejandra en sus tareas fue satisfactoria?
              </p>
              <i v-for="star in 5" :key="star" class="fa fa-star"></i>
              <p>¿La comunicación de Alejandra fue efectiva?</p>
              <i v-for="star in 5" :key="star" class="fa fa-star"></i>
              <p>¿Que tan satisfecho/a estas con el trabajo de Alejandra?</p>
              <i v-for="star in 5" :key="star" class="fa fa-star"></i>
            </div>
          </div>
        </div>
        <a class="dropdown-item border-radius-md" href="javascript:;"> </a>
      </li>
    </ul>
  </li>
</template>

<script>
import Servicio from "@/api-services/colaboradores.yacalificame.service";


export default {
  setup() {
    return {};
  },
  computed: {
    EstiloCampo() {
      return "col-lg-4 col-12 col-sm-12 col-md-12 px-2 mb-campo";
    },
    FotoURL() {
      let img = Servicio.FotoURL(this.localUrlMiniatura);
      return img;
    },
    RutaDestino() {
      return `${this.$route.path}/${this.Datos.id}`;
    },
  },
};
</script>

<style scoped>
.option {
  margin-top: auto;
  margin-bottom: auto;
}
.outer {
  width: 100% !important;
  height: 100% !important;
  max-width: 150px !important; /* any size */
  max-height: 150px !important; /* any size */
  margin: auto;
  background-color: #6eafd4;
  border-radius: 100%;
  position: relative;
}

.inner {
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  right: 0;
}

.profile-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.edit-btn {
  position: absolute;
  bottom: -1rem;
  right: 2rem;
  bottom: 0;
  right: 0;
  border-radius: 60%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
