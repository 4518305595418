<template>
  <div class="card d-flex justify-content-center align-items-center pt-3 cardAgregarPregunta">
    <div class="row">
      <div class="col">
        <button @click="Agregar">
          <p class="cardAgregarPregunta__parrafo">
            <i class="fa-1x fas fa-plus" style="color: #2dcec6"></i> Agregar encuesta
          </p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Encuestas: [],
    };
  },
  methods: {
    Agregar() {
      const nuevaEncuesta = {
        etiqueta: "Nueva Encuesta",
        descripcion: "Descripción de la nueva encuesta",
        vinculo: "URL de la nueva encuesta",
      };
      this.$emit("nueva-encuesta", nuevaEncuesta);
    },
  },
};
</script>

<style lang="scss" scoped>
.cardAgregarPregunta {
  border: 1px dashed #2dcec6;
  background-color: #effffe;
}
.cardAgregarPregunta__parrafo {
  color: #2dcec6;
  font-size: 1.2rem;
}
</style>