<template>
  <div>
    <div class="container">
      <div class="row mt-4 d-flex justify-content-start align-items-center">
        <div class="w-100 d-flex justify-content-between">
          <div class="" style="
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #21c997;
            display: flex;
            justify-content: center;
            align-items: center;
          ">
          <i  class="fa fa-check" style="color: white; font-size: 1.5rem"></i> 
      </div>
          <h4 class="mt-2">Calificaciones</h4>
          <button style="opacity: 0;" class="btn rounded-circle mt-1">
            <i class="fa fa-expand" v-if="!isExpanded"></i>
            <i class="fa fa-compress" v-else></i>
          </button>
        </div>
        <!-- <p>Informacion calificaciones: {{ infocal }}</p> -->
        <MazSpinner v-if="cargando" :size="'48'" color="secondary">
        </MazSpinner>
         <div v-else>
           <div v-if="infocal && infocal.length">
             <table class="table">
               <thead>
                 <tr>
                   <th>Etiqueta</th>
                   <th>Calificacion</th>
                 </tr>
               </thead>
               <tbody>
                 <tr v-for="(item, index) in infocal" :key="index">
                   <td>{{ item.etiqueta }}</td>
                   <td>
                     <span v-if="item.calificacion">
                       {{ item.calificacion.join(', ') }}
                     </span>
                     <span v-else>
                       Sin evaluaciones
                     </span>
                   </td>
                 </tr>
               </tbody>
             </table>
           </div>
           <div v-else>
             <p class="w-100 text-center">No hay encuestas calificadas</p>
           </div>
         </div>

        <!-- <div v-if="isExpanded" class="w-100 row">
          <div class="col-md-7 col-sm-12">
            Soy la parte de la izquierda
          </div>
          <div style="height: 55vh;" class="vr p-0 divider"></div>
          <div class="col-md-4 col-sm-12">
            Soy la parte de la derecha
          </div>
        </div> -->
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import MazSpinner from "maz-ui/components/MazSpinner";
import Calificaciones from "@/api-services/dashboards.yacalificame.service";
import ServiciosDashboard from "@/api-services/dashboardsgeneral.yacalificame.service";

export default {
  name: "Calificaciones",
  components:{
    MazSpinner,
  },
  props:{
    Fecha:{type:Object, default:null}
  },
  data() {
    return {
      isExpanded: false,
      infocal: null,
      info: [
        {
          etiquetas: "Servicio",
          description: "¿Cómo calificarías la calidad de nuestro servicio?",
          evaluaciones: [5.3]
        },
        {
          etiquetas: "Limpieza",
          description: "¿Cómo calificarías la limpieza y presentación de nuestras instalaciones?",
          evaluaciones: [4.5]
        },
        {
          etiquetas: "Eficiencia",
          description: "¿La eficiencia de los colaboradores en sus tareas fue satisfactoria?",
          evaluaciones: []  // Sin evaluaciones
        },
        {
          etiquetas: "Comunicación",
          description: "¿La comunicación de los colaboradores fue efectiva?",
          evaluaciones: [3.4]
        },
        {
          etiquetas: "Satisfacción",
          description: "¿Qué tan satisfecho/a estás con el trabajo de los colaboradores?",
          evaluaciones: [5]
        }
      ],
      cargando: true,
      MsgError: "",
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },
    };
  },

  watch: {
    Fecha(Valor){
      if (Valor.start!= undefined && Valor.end!=undefined) {
        this.ObtenDatos()
      }
    },
  },

  beforeMount() {
  this.ObtenDatos()
  },
  methods: {
    ObtenDatos(){
      ServiciosDashboard.getsCalificaciones(this, {FechaDesde: this.Fecha.start, FechaHasta: this.Fecha.end}, (response) => {
      console.debug("Dahsboard", response);
      this.infocal = response.data;
    });
    },
    fetchCalificaciones() {
     
    },
    agregarEstrella(index) {
      if (this.info[index] && this.info[index].evaluaciones) {
        this.info[index].evaluaciones.push(1);
      } else {
        this.$set(this.info[index], 'evaluaciones', [1]);
      }
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      this.$emit('expand', this.isExpanded); // Emitir evento expand
    },
    Expandir() {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded == false) {
        this.$emit("Expandir", "Todas");
        return;
      }
      this.$emit("Expandir", "Calificaciones");
    }
  }
};
</script>

<style scoped lang="scss">
.divider {
  display: block;
}

@media (max-width: 575.98px) {
  .divider {
    display: none;
  }
}
</style>
