
<template>
  <div
    class="p-3 bg-white card shadow p-3 mb-5 bg-body rounded multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content rounded" style="">
      <div class="d-flex flex-column flex-md-row align-items-center">
        <div  class="w-100 d-flex justify-content-center">
          <img class="img-girada" src="@/assets/img/logosya/Gafetes-21.png" alt="No imagen gafetes" />
        </div>
        <div class="w-100 text-center">
          <i class="fas text-success fa-id-card fa-5x mb-3"></i>
          <h4 class="mb-3"><b>¡Estamos creando tu gafete en breve te lo enviaremos!</b></h4>
          <div class="row mb-3">
            <div class="col">
              <MazInput
                v-model="FechaSoli"
                type="text"
                label="Fecha de solicitud"
                readonly
              />
            </div>
            <div class="col">
              <MazInput
                v-model="FechaEnt"
                type="text"
                label="Fecha estimada de entrega"
                readonly
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <button class="btn btn-lg btn-success" type="button" @click="VerListado">
                Ir a listado del personal
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="mt-4 button-row d-flex col-12">
        <argon-button
          color="secondary"
          variant="gradient"
          class="mb-0 js-btn-prev"
          title="Prev"
          @click="emit('prevStep')"
          >Prev</argon-button
        >
        <argon-button
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Next"
          @click="emit('nextStep')"
          >Next</argon-button
        >
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router';
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const emit = defineEmits(["nextStep", "prevStep"]);
const props = defineProps({
  FechaSolicitud: String,
  FechaEntrega:String,
})
const FechaSoli = ref(props.FechaSolicitud)
const FechaEnt = ref(props.FechaEntrega)
const router = useRouter()

function VerListado() {
  router.push("/personal")
}


</script>

<style lang="scss">
.img-girada{
  transform: rotate(30deg);
  width: 13em !important;
  height: auto;
}
@media screen and (max-width: 576px) {
  .img-girada{
  transform: rotate(30deg);
  width: 8em !important;
  height: auto;
}
    }
</style>