<template>
  <div class="card shadow-lg bg-light p-3">
    <h3 style="color: #2dcec6 !important;">{{ titulo }}</h3>
    <slot></slot>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
  props: {
    titulo: {
      type: String,
      default: "Listado General",
    },
  },
};
</script>

<style lang="css" scoped>
/* .titulo_azul {
  color: #2dcec6 !important;
} */
</style>