
<template>
  <div
    class="p-3 bg-white card shadow p-3 mb-5 bg-body rounded multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content rounded" style="border: 2px solid #2DCEC6;">
      <div class="d-flex justify-content-evenly align-items-center">
        <div class="col-1">
          <img class="img-girada" src="@/assets/img/logosya/Gafetes-21.png" alt="No imagen gafetes">
        </div>
        <div class="col-5 text-center">
          <i class="fas text-success fa-check-circle fa-5x"></i>
          <h4 class="mt-3"><b>¡Tu gafete ha sido enviado!</b></h4>
          <p class="mt-3">Tu gafete será enviado mediante entrega de UBER, aquí te dejamos el link</p>
          <button class="btn btn-lg btn-success mt-3" type="button" @click="VerListado">
            Ir a listado del personal
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
import { useRouter } from 'vue-router';
const router = useRouter()
const emit = defineEmits(["prevStep"]);

function VerListado() {
  router.push("/personal")
}

</script>

<style>
.img-girada{
  transform: rotate(30deg);
  width: 13rem;
  height: auto;
}
</style>
