
<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="shadow-lg p-3 mb-5 bg-body rounded">
        <div class="bg-success rounded">
          <div class="row p-4">
            <div class="col text-center">
              <h2 class="mb-3 text-white">
                <b>¡Tu gafete ha sido entregado!</b>
              </h2>
              <div class="row mb-3 d-flex justify-content-center m-1">
                <div class="col-3">
                  <MazInput
                    v-model="FechaSoli"
                    type="text"
                    label="Fecha de solicitud"
                    readonly
                  />
                  <!-- <MazInput v-model="localFecha" type="date" /> -->
                </div>
                <div class="col-3">
                  <MazInput
                    v-model="FechaEnt"
                    type="text"
                    label="Fecha y hora de entrega:"
                    readonly
                  />
                </div>
                <div class="col-3">
                  <MazInput
                    v-model="Recept"
                    type="text"
                    label="Recibió"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-grid gap-2 col-3 mx-auto pt-5">
        <button class="btn btn-md btn-success" type="button" @click="VerListado">
          Ir a listado del personal
        </button>
      </div>

      <div class="mt-3 d-flex justify-content-center align-items-center"></div>
    </div>
  </div>
</template>
<script>
import Servicio from "@/api-services/gafetes.yacalificame.service";
export default {
  name: "Step5GafeteEntregado",
  components: {},
  props: {
    registro: {
      type: Object,
      required: true,
    },
    Datos: {
      type: Object,
      default: () => ({ etiqueta: "" }),
    },
    dateValue: {
      type: String,
      default: "",
    },
    fechaSolicitado: {
      type: String,
      default: "",
    },
    fechaEntrega: {
      type: String,
      default: "",
    },
    destinatario: {
      type: String,
      default: "",
    },
    receptor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
     
      info: null,
      FechaSoli:this.fechaSolicitado,
      FechaEnt:this.fechaEntrega,
      Recept: this.receptor,
      //Muestra el cargando
      cargando: true,
      //Indica que hay error
      MsgError: "",
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },
    };
  },
  emits: ["nextStep", "prevStep"],
  methods: {
    VerListado() {
  this.$router.push("/personal")
}
  },
  beforeMount() {
    Servicio.get(this,{}, (response) => {
      console.debug(response);
      this.info = response.data;
    });
  },
};
</script>
<style>
</style>
