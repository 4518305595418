<!-- @format -->

<template>
  <CardTitulo titulo="Identificación y datos de gafete" id="laborales">
    <div class="row">
      <div class="col">
        <p>
          Es importante que llenes correctamente estos datos, ya que será la
          forma para identificar a tus colaboradores.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3 px-2">
        <p style="color: #2dcec6 !important">
          <small>*Nombre que aparecerá en reportes</small>
        </p>
        <MazInput v-model="localEtiqueta" type="text" label="Etiqueta" color="secondary" required
          @update:model-value="(value) => update('etiqueta', value)" />
      </div>
      <div class="col-md-6 mb-3 px-2">
        <p style="color: #2dcec6 !important">
          <small>*Nombre que aparecerá en el gafete</small>
        </p>
        <MazInput v-model="localSobrenombre" type="text" label="Sobrenombre" color="secondary" required
          @update:model-value="(value) => update('sobreNombre', value)" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <SelectEncuestas v-model="localEncuesta" color="secondary" required
          @update:model-value="(value) => update('encuestaID', value)" @actualiza="ActualizaDato" />
      </div>
      <div class="col-md-3">
        <router-link class="btn btn-outline-succcess btn-identifica" :to="{}" v-if="formulariosCompleto"
          @click="EditarGafete" type="submit">Generar gafete
        </router-link>
        <p v-else>Para generar el gafete, completa los datos requeridos (*) del colaborador</p>
        <!-- <button type="button" class="btn-identifica">Imprimir gafete</button> -->
      </div>
    </div>

  </CardTitulo>
</template>

<script>
import { watch } from "vue";
import CardTitulo from "../../../Componentes/CardTitulo.vue";
import SelectEncuestas from "@/Vistas/Encuestas/Select.vue";
import Servicio from "@/api-services/colaboradores.yacalificame.service";

export default {
  components: {
    CardTitulo,
    SelectEncuestas,
  },
  props: {
    etiqueta: {
      type: String,
      default: "",
      required: true,
    },
    sobreNombre: {
      type: String,
      default: "",
      required: true,
    },
    encuestaID: {
      type: String,
      default: "",
      required: true,
    },
    formulariosCompleto: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["update:etiqueta", "update:sobreNombre", "update:encuestaID"],
  data() {
    return {
      localEtiqueta: this.etiqueta,
      localSobrenombre: this.sobreNombre,
      localEncuesta: this.encuestaID,
      Erroneo: "",
    };
  },
  // Usar el hook created puede ser útil para inicializar datos o estados
  created() {
    watch(
      () => this.etiqueta,
      (newVal) => {
        this.localEtiqueta = newVal;
      }
    );
    watch(
      () => this.sobreNombre,
      (newVal) => {
        this.localSobrenombre = newVal;
      }
    );
    watch(
      () => this.encuestaID,
      (newVal) => {
        this.localEncuesta = newVal;
      }
    );
  },
  watch: {
    value(valor) {
      this.selectedItem = valor;
      console.debug(valor);
    },
    selectedItem(valor) {
      //this.$emit('update:value', valor);
      this.$emit("input", valor);
      //  this.$set(this, 'value', valor);
      // this.value = valor;
    },
  },
  computed: {
    RutaDestino() {
      return `${this.$route.path}/solicitud/${this.$route.params.id}`;
    },
  },
  methods: {
    update(campo, value) {
      // console.debug(`Actualizando ${campo} a ${value}`);
      this.$emit(`update:${campo}`, value);
      console.debug(this.encuestaID);
    },
    cambiarMensaje() {
      //console.debug("Este es el valo: ", this.encuestaID);
      this.mensaje = "¡Hola, Vue!";
    },
    Actualiza(OnFinalizado) { },
    ActualizaDato(Valor) {
      console.debug("Este es ENCUESTA ID", Valor);
      this.update("encuestaID", Valor);
    },
    EditarGafete() {
      this.$router.push(`/solicitud/${this.$route.params.id}`)
    }
  },
  mounted() {
    if (this.value != null) this.selectedItem = this.value;
    this.Actualiza();
  },
};
</script>

<style scoped>
.btn-identifica {
  width: 100%;
  height: 3rem;
  border: 1px solid #2dcec6;
  border-radius: 10px;
  color: #FFFFFF !important;
}

.btn-identifica {
  color: #2dcec6 !important;
}

.btn-identifica:hover {
  width: 100%;
  height: 3rem;
  background-color: #2cce94;
  border-radius: 10px;
  color: #FFFFFF !important;
}
</style>
