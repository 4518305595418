<template>
  <div class="card p-3">
    <p>{{ titulo }}</p>
    <div class="d-flex justify-content-start ">
      <MazRadio v-if="mostrarNoSolicitar" value="NoSolicitar" :name="Nombre"  v-model="Seleccionado" color="secondary">
        No solicitar
      </MazRadio>
      <MazRadio  value="Opcional" :name="Nombre"  v-model="Seleccionado" color="secondary">
        Opcional
      </MazRadio>
      <MazRadio  value="Obligatorio" :name="Nombre"  v-model="Seleccionado" color="secondary">
        Obligatorio
      </MazRadio>
    </div>
    <slot></slot>
  </div>
</template>
  
<script>
import MazRadio from 'maz-ui/components/MazRadio'

export default {
  components: {
    MazRadio,
  },
  setup() {
    return {};
  },
  data(){
    return {
      Seleccionado: this.modelValue,
    }
  },
  props: {
    modelValue: { type: String, default: "Opcional" },
    titulo: {
      type: String,
      default: "Listado General",
    },
    mostrarNoSolicitar: {
      type: Boolean,
      default: true,
    },
  },
  computed:{
    Nombre(){
      return "Radio";
    },
    EstiloCampo() {
      return "col-lg-4 col-12 col-sm-12 col-md-12 px-2 mb-campo";
    },
  },
  watch: {
    Seleccionado(Valor){
      this.$emit("update:model-value", Valor);
    }
  }
};
</script>
  
  <style lang="css" scoped>
/* .titulo_azul {
    color: #2dcec6 !important;
  } */
</style>