<template>
    <ArchivoSelect v-model="urlMiniatura" MensajeCampo=""  @update:value="urlMiniatura = $event" />
    <img v-if="urlMiniatura" :src="urlMiniatura" alt="Logo">
    <!-- <div class="card d-flex justify-content-center align-items-center pt-3 cardAgregarPregunta">   
        <div class="row">
            <div class="col">


                <input class="d-none" type="file" ref="Fileupload" v-on:change="AgregarArchivo"
                    accept=".jpg, .jpeg, .png" />
                <button @click="$refs.Fileupload.click()">
                    <p class="cardAgregarPregunta__parrafo">
                        <i class="fa-1x fas fa-plus" style="color: #2dcec6"></i> Agrega logotipo
                    </p>
                    <img v-if="urlMiniatura" :src="urlMiniatura" alt="Logo">

                </button>
            </div>
        </div>
    </div> -->
</template>

<script>
import ServicioSucursales from "@/api-services/Ubicaciones.yacalificame.service";
import Archivos from '@/api-services/blobs.service';
import ArchivoSelect from '../../Personal/Componentes/Campos/ArchivoSelect2.vue';
export default {
  components: { ArchivoSelect},
  props: {
    value:{type:String,default:null},
  },
  emits:["update:value"],
    data() {
        return {
            urlMiniatura: null,
        };
    },
    beforeMount(){
        this.urlMiniatura = this.value
    },

    watch:{
        urlMiniatura(Valor){
            this.$emit("update:value",Valor)
        }
    },
    /* beforeMount() {
        ServicioSucursales.get(this, (response) => {
            console.debug("La respuesta es ", response);
            this.sucursal = response.data.listaSucursales;
        });
    }, */
    methods: {
        addLogo() {
            // Aqui debes llamar a la API para obtener la url del logo
            // y guardarla en la propiedad urlMiniatura
            /* this.urlMiniatura = "https://ejemplo.com/logo.png"; */
            ServicioSucursales.get(this, (response) => {
                console.debug("La respuesta es ", response);
                this.sucursal = response.data.listaSucursales;
            });
        },

        AgregarArchivo(file) {
            let archaaux = file.target.files[0]
            let nombre = archaaux.name.split(".")[0]
            let Archivo = new Blob([archaaux], { type: archaaux.type })
            Archivos.LogoArchivo(Archivo).then(response => {
                let url = response.data.archivoURL
                this.Archivos.push({ nombre: nombre, descripcion: "", url: url })
            })

        },
    },
}
</script>

<style lang="scss" scoped>
.cardAgregarPregunta {
    border: 1px dashed #2dcec6;
    background-color: #effffe;
}

.cardAgregarPregunta__parrafo {
    color: #2dcec6;
    font-size: 1.2rem;
}
</style>