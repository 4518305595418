<!-- @format -->

<template>
  <CardTitulo titulo="Laborales" id="laborales">
    <div class="row">
      <div class="col-lg-6 col-12 mb-3 col-sm-12">
        <MazPicker v-model="localFechaIngreso" :class="EstiloCampo" label="Fecha de ingreso" color="secondary" autoClose clearable required
          @update:model-value="(value) => update('fechaIngreso', value)"  />
      </div>
      <div class="col-lg-6 col-12 mb-3 col-sm-12">
        <SelectPuestos v-model="localPuesto" :class="EstiloCampo" color="secondary" required
          @update:model-value="(value) => update('puestoID', value)" />
      </div>
    </div>
    <div class="row">
      <SelectDepartamentos v-model="localAreaDepto" :class="EstiloCamposPerfil" color="secondary" required
        @update:model-value="(value) => update('departamentoID', value)" />
      <SelectSucursales v-model="localSucursalUbicacion" :class="EstiloCamposPerfil" color="secondary" required
        @update:model-value="(value) => update('ubicacionID', value)" />
    </div>
  </CardTitulo>
</template>

<script>
import { watch } from "vue";
import CardTitulo from "../../../Componentes/CardTitulo.vue";
import SelectPuestos from "../../Puestos/Select.vue";
import SelectDepartamentos from "../../Departamentos/Select.vue";
import SelectSucursales from "../../Ubicaciones/Select.vue";

export default {
  components: {
    CardTitulo,
    SelectDepartamentos,
    SelectPuestos,
    SelectSucursales,
  },
  props: {
    fechaIngreso: {
      type: String,
      default: "",
    },
    ubicacionID: {
      type: String,
      default: "",
    },
    puestoID: {
      type: String,
      default: "",
    },
    departamentoID: {
      type: String,
      default: "",
    },
    loading: { type: Boolean, default: false },

    value: { type: String, default: null },
    id: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },

    // Same as MazInput (options: `sm|md|lg`)
    size: { type: String, default: null },

    // Etiqueta a mostrar
    campo: { type: String, default: "Genero" },

    // Etiqueta a mostrar
    placeholder: { type: String, default: "Genero" },
    // Enable the dark mode
    dark: { type: Boolean, default: false },
    // Use color
    color: { type: String, default: "primary" },

    success: { type: Boolean, default: false },

    open: { type: Boolean, default: false },

    // set the position of countries list (ex: `top`, `top right`, `bottom right`)
    position: { type: String, default: "left bottom" },

    clearable: { type: Boolean, default: true },
  },
  emits: [
    "update:fechaIngreso",
    "update:ubicacionID",
    "update:puestoID",
    "update:departamentoID",
  ],
  data() {
    return {
      localFechaIngreso: this.fechaIngreso,
      localSucursalUbicacion: this.ubicacionID,
      localPuesto: this.puestoID,
      localAreaDepto: this.departamentoID,

      filtro1: null,
      filtro2: null,
      filtro3: null,
      opcionesFiltro1: [
        { value: "opcion1", label: "Opción 1" },
        { value: "opcion2", label: "Opción 2" },
        { value: "opcion3", label: "Opción 3" },
      ],
      opcionesFiltro2: [
        { value: "opcionA", label: "Opción A" },
        { value: "opcionB", label: "Opción B" },
        { value: "opcionC", label: "Opción C" },
      ],
      opcionesFiltro3: [
        { value: "opcionX", label: "Opción X" },
        { value: "opcionY", label: "Opción Y" },
        { value: "opcionZ", label: "Opción Z" },
      ],
    };
  },
  computed: {
    EstiloCamposPerfil() {
      return "col-lg-6 col-12 mb-3 col-sm-12 mt-2 mb-campo";
    },
  },
  // Usar el hook created puede ser útil para inicializar datos o estados
  created() {
    watch(
      () => this.fechaIngreso,
      (newVal) => {
        this.localFechaIngreso = newVal;
      }
    );
    watch(
      () => this.ubicacionID,
      (newVal) => {
        this.localSucursalUbicacion = newVal;
      }
    );
    watch(
      () => this.puestoID,
      (newVal) => {
        this.localPuesto = newVal;
      }
    );
    watch(
      () => this.departamentoID,
      (newVal) => {
        this.localAreaDepto = newVal;
      }
    );
  },

  methods: {
    update(campo, value) {
      // console.debug(`Actualizando ${campo} a ${value}`);
      this.$emit(`update:${campo}`, value);
    },
    cambiarMensaje() {
      this.mensaje = "¡Hola, Vue!";
    },
    OnGuardado(Datos) {
      this.valores.push(Datos);
      this.selectedItem = Datos.id;
      /*
          //Actualiza todo el listado
        this.Actualiza(() => {
          this.selectedItem = Datos.id;
        });
        */
    },
  },
};
</script>

<style scoped>
.MazSelect {
  max-height: 260px !important;
  width: 100% !important;
  max-width: 100% !important;
}

.maz-select-dropdown {
  font-size: 16px;
  /* Cambia el tamaño de fuente del dropdown */
}

.maz-select-dropdown .maz-dropdown-menu .maz-dropdown-item {
  font-size: 14px;
  /* Cambia el tamaño de fuente de cada opción */
}

/* Este es solo un ejemplo de CSS, puede necesitar ajustes según tu diseño y preferencias */
.custom-select {
  width: 200px;
  /* Cambia el ancho del select según tu preferencia */
}

.custom-select .maz-select-input,
.custom-select .maz-select-dropdown {
  font-size: 16px;
  /* Cambia el tamaño de fuente del select */
}

.custom-select .maz-select-dropdown .maz-dropdown-menu {
  max-height: 200px;
  /* Ajusta la altura máxima del menú desplegable según tu preferencia */
  overflow-y: auto;
  /* Añade scroll vertical si el menú desplegable es muy largo */
}

.custom-select .maz-select-dropdown .maz-dropdown-menu .maz-dropdown-item {
  font-size: 14px;
  /* Cambia el tamaño de fuente de cada opción */
}
</style>