<!-- @format -->

<template>
  <Modal v-if="MostrarModal" :show="MostrarModal"
    modal-classes="modal-dialog modal-dialog-centered modal-dialog-scrollable" :type="type" @close="closeModal"
    @agregar="Agregar" @editar="mostrarDireccionSucursal">
    <template #header>
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h2 class="card-title text-center text-primary mx-auto">
        {{
    mostrarDomicilio
      ? "Agregar ubicación de entrega"
      : mostrarDireccion
        ? "Editar ubicación de entrega"
        : "Selecciona tu ubicación de envío"
  }}
      </h2>
      <!-- <h2 class="card-title text-center text-primary">
        {{ modalTitle }}
      </h2> -->
    </template>

    <template #body>
      <div class="p-3">
        <MazSpinner class="w-100 d-flex justify-content-center" v-if="cargando" color="secondary" :size="'48'" />
        <div v-else class="d-grid gap-1">
          <AgregarUbicacion v-if="mostrarDomicilio" />
          <!-- <DireccionSucursal
            v-else-if="mostrarDireccion"
            :registro="registroEditando"
            :dDireccion="Datos.dDireccion"
        @update:dDireccion="(value) => (Datos.dDireccion = value)"
          /> -->
          <EdicionUbicacion v-else-if="mostrarDireccion" ref="EdiconUbi" v-model="registroEditando" @GuardaCambios="Guardar" />
          <div v-else>
            <CardRegistroSucursales v-for="(registro) in sucursal" :key="registro.id" :registro="registro"
              :SucSelect="SucursalElegida" @editar="mostrarDireccionSucursal"
              @update:SucSelect="(Valor) => (SucursalElegida = Valor)" />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="row" v-if="!mostrarDomicilio && !mostrarDireccion">
        <div class="col-8">
          <button class="btn btn-success" style="
              background-color: #effffe;
              color: #2dcec6;
              border: 1px dashed #2dcec6;
            " @click="mostrarDomicilio = true">
            <i class="fas fa-plus text-primary"></i> Agregar ubicación de
            entrega
          </button>
        </div>
        <div class="col-4">
          <button class="btn btn-success" @click="CambiaSucursal">Enviar aquí</button>
        </div>
      </div>
      <div class="row" v-else-if="mostrarDomicilio">
        <div class="col-6">
          <button @click="mostrarDomicilio = false">
            <i class="fa fa-chevron-left me-2"></i>Cancelar
          </button>
        </div>
        <div class="col-6">
          <button class="btn btn-success" @click="agregarUbicacion">
            Agregar ubicacion
          </button>
        </div>
      </div>
      <div class="row" v-else-if="mostrarDireccion">
        <div class="col-6">
          <button @click="mostrarDireccion = false">
            <i class="fa fa-chevron-left me-2"></i>Cancelar
          </button>
        </div>
        <div class="col-6">
          <button class="btn btn-success" @click="guardarCambios">
            Guardar cambios
          </button>
        </div>
      </div>
    </template>
    <p><!--Vacio para generar--></p>
  </Modal>
</template>

<script>
import Modal from "@/Componentes/Modal.vue";
//import Cargando from "@/Componentes/Cargando.vue";
import CardRegistroSucursales from "@/Vistas/Gafetes/Components/CardRegistroSucursales.vue";
import Servicio from "@/api-services/ubicaciones.service";
import ServicioSucursales from "@/api-services/Ubicaciones.yacalificame.service";
import MazSpinner from "maz-ui/components/MazSpinner";
import AgregarUbicacion from "./../../Vistas/Ubicaciones/Componentes/AgregarUbicacion.vue";
//import DireccionSucursal from "./../../Vistas/Ubicaciones/Componentes/DireccionSucursal.vue";
import EdicionUbicacion from "../../Vistas/Ubicaciones/Componentes/EdicionUbicacion.vue";

export default {
  components: {
    Modal,
    //Cargando,
    MazSpinner,
    CardRegistroSucursales,
    AgregarUbicacion,
    //DireccionSucursal,
    EdicionUbicacion,
  },
  props: {
    mostrarIrAEdicion: {
      type: Boolean,
      default: true,
      description: "Indica si se mostrará la opción de ir a edición",
    },
    msgError: {
      type: String,
      default: "",
      description: "Muestra el mensaje de error",
    },
    validarDatos: {
      type: Function,
      default: () => {
        return true;
      },
    },
    mostrar: {
      type: Boolean,
      default: true,
    },
    //ej. Mercadotecnia
    etiqueta: {
      type: String,
      required: true,
      default: "Mercadotecnia",
    },
    //ej. departamento
    tipo: {
      type: String,
      required: true,
      default: "departamento",
    },
    tipoIcono: {
      type: String,
      required: true,
      default: "fas fa-sitemap",
    },
    //Indica que se mostrará la opción de reactivar
    reactivar: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini", "large"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|large|"") ',
    },
    ListadoSucursales: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:mostrar", "agregar", "update:datos", "update:ir-a-edicion", "CambiaSucursal"],

  data() {
    return {
      info: null,
      sucursal: null,
      //Muestra el cargando
      cargando: false,
      IrAEdicion: true,
      MostrarModal: this.mostrar,
      SucursalElegida: null,
      //Indica que hay error
      MsgError: "",
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },
      Datos: {
        etiqueta: "",
        descripcion: "",
      },
      mostrarDomicilio: false,
      mostrarDireccion: false,
      registroEditando: null,
    };
  },
  beforeMount() {
    ServicioSucursales.get(this, (response) => {
      console.debug("La respuesta es ", response);
      this.sucursal = response.data.listaSucursales;
    });
  },
  computed: {
    slots() {
      return Object.keys(this.$slots);
    },
  },
  watch: {
    IrAEdicion(valor) {
      this.$emit("update:ir-a-edicion", valor);
    },
  },
  mounted() {
    this.ActualizaDatos();
  },
  methods: {
    ActualizaDatos() {
      this.$emit("update:datos", this.Datos);
    },
    closeModal() {
      this.MostrarModal = false;
      this.$emit("update:mostrar", this.MostrarModal);
    },

    Agregar() {
      if (this.validarDatos != null && !this.validarDatos(this.Datos)) {
        return;
      }
      console.log("Agregar", JSON.stringify(this.Datos));
      this.$emit("agregar", this.Datos);
    },
    
    agregarUbicacion() {
      if (this.validarDatos != null && !this.validarDatos(this.Datos)) {
        return;
      }
    
      return
      // Servicio.post(
      //   this,
      //   Parametros.registro,
      //   (response) => {
      //     if (Parametros.OnAgregado != null) Parametros.OnAgregado(response);
      //     //    console.log("info", this.info);
      //   },
      //   (MsgError, Error) => {
      //     if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
      //   }
      // );
      // console.log("Agregar", JSON.stringify(this.Datos));
      // this.$emit("agregar", this.Datos);
      // console.log("Ubicación agregada");
      // this.mostrarDomicilio = false;
    },
    mostrarDireccionSucursal(registro) {
      console.debug("Este es el registro",registro)
      this.mostrarDireccion = true;
      this.registroEditando = registro;
    },
    CambiaSucursal() {
      this.$emit("CambiaSucursal", this.SucursalElegida)
      this.MostrarModal = false
    },
    guardarCambios(){
      this.$refs.EdiconUbi.Revisaformulario()
    },
    Guardar(){
      this.registroEditando.sDatos = JSON.stringify(this.registroEditando.datos)
      console.debug("Esto es lo que se debe de guardar",this.registroEditando) 
      Servicio.put(this,this.registroEditando.id,this.registroEditando,(response) => {
        console.debug("Esto me dio de respuesta", response.data)
        this.mostrarDomicilio = false
        this.mostrarDireccion = false
        this.$notify({type:"success",title:"Ubicacion actualizada"})
      }, (response) => {
        this.$notify({type:"danger",title:"Ocurrio un error, intente mas tarde"})
      })
    }
  },
};
</script>
