<template>
  <div class="card p-3 m-3">
    <h2 class="text-center text-primary">Solicitud de gafete</h2>
    <Steps />
  </div>
</template>

<script>
import Servicio from "@/api-services/colaboradores.yacalificame.service";
// import StepSolicitud from '../Personal/Componentes/StepSolicitud.vue';
import Steps from '@/Vistas/Gafetes/Components/Steps.vue';
export default {
  name: "SolicitudGafete",
  components: {
    Steps,
    // StepSolicitud,
  },
  props: {
    datos: {
      type: Array,
      required: true,
    },
    registros: {
      type: Array,
      required: true,
    },
    listado: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
      info: {},
      //Muestra el cargando
      cargando: true,
      //Indica que hay error
      MsgError: "",
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },
      colaborador: {
        etiqueta: "",
      }
    };
  },
  computed: {
    Servicio() {
      return Servicio;
    },
  },
  
};
</script>

<style lang="scss" scoped>
</style>