<script setup>
import { onBeforeMount, onMounted, ref, watch } from "vue";
import MazSpinner from "maz-ui/components/MazSpinner";
import Chart from "chart.js/auto";
import ServiciosDashboard from "@/api-services/dashboardsgeneral.yacalificame.service";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  height: {
    type: String,
    default: "250",
  },
  title: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  chart: {
    type: Object,
    required: true,
    labels: Array,
    datasets: {
      type: Array,
      label: String,
      data: Array,
    },
  },
  Fecha:{type:Object, default:null},

  // infovisit: {
  //   type: Object,
  //   required: true,
  //   labels: Array,
  //   datasets: {
  //     type: Array,
  //     label: String,
  //     data: Array,
  //   },
  // },
});

const emit = defineEmits(["Expandir"])

const isExpanded = ref(false)

const infovisit = ref(null)

const cargando = ref(true)

const Expandir = () => {
  isExpanded.value = !isExpanded.value
  if (isExpanded.value == false) {
    emit("Expandir", "Todas")
    return
  }
  emit("Expandir", "Historial")
}

const CrearGrafica = () => {
  var gradientLineChart = document.getElementById(props.id).getContext("2d");

  var gradientStroke1 = gradientLineChart.createLinearGradient(0, 230, 0, 50);

  gradientStroke1.addColorStop(1, "rgba(203,12,159,0.2)");
  gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
  gradientStroke1.addColorStop(0, "rgba(203,12,159,0)"); //purple colors

  var gradientStroke2 = gradientLineChart.createLinearGradient(0, 230, 0, 50);

  gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
  gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
  gradientStroke2.addColorStop(0, "rgba(20,23,39,0)"); //purple colors

  let chartStatus = Chart.getChart(props.id);
  if (chartStatus != undefined) {
    chartStatus.destroy();
  }
  console.debug("Esto tiene el objeto", infovisit.value)
  
  if (infovisit.value.datasets.length == 2) {
    new Chart(gradientLineChart, {
      type: "line",
      data: {
        labels: infovisit.value.labels,
        datasets: [
          {
            label: infovisit.value.datasets[0].label,
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            borderColor: "#4BB543 ",
            backgroundColor: gradientStroke1,
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            fill: true,
            data: infovisit.value.datasets[0].data,
            maxBarThickness: 6,
          },
          {
            label: infovisit.value.datasets[1].label,
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            borderColor: "#3A416F",
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            backgroundColor: gradientStroke2,
            fill: true,
            data: infovisit.value.datasets[1].data,
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            type: "linear",
            position: "left", // Colocar la escala del eje Y a la izquierda
            display: true,
            title: {
              display: true,
              text: "Visitas",
            },
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#fbfbfb",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
              min: 0, // Establecer el valor mínimo del eje Y
              max: 1000, // Establecer el valor máximo del eje Y
              /* stepSize: 200, */
              /* callback: function(value, index, values) {
                Obtener los valores personalizados para el eje Y
                const customLabels = [1000, 800, 600, 400, 200, 0];
                return customLabels[index];
              } */
            },
          },
          /* y1: {
            type: "linear",
            position: "right",
            title: {
              display: true,
              text: "Gastof",
            },
            ticks: {
              min: 0,
              max: 2000,
              stepSize: 20,
              callback: function (value) {
                return "$" + (value / 1000).toFixed(0) + "k";
              },
            },
          }, */
          x: {
            display: true,
            title: {
              display: true,
              text: "Gastos",
            },
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#ccc",
              padding: 20,
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    });
  } else if (infovisit.value.datasets.length == 1) {
    new Chart(gradientLineChart, {
      type: "line",
      data: {
        labels: infovisit.value.labels,
        datasets: [
          {
            label: infovisit.value.datasets[0].label,
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            borderColor: "#4BB543 ",
            backgroundColor: gradientStroke1,
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            fill: true,
            data: infovisit.value.datasets[0].data,
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#fbfbfb",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#ccc",
              padding: 20,
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    });
  }
}

const ObtenDatos = () => {
  cargando.value = true
  ServiciosDashboard.getsVisitas(this, {FechaDesde: props.Fecha.start, FechaHasta: props.Fecha.end}, (response) => {
    console.debug("Dashboard", response);
    // eslint-disable-next-line vue/no-mutating-props
    let respuesta = response.data
    let titulos = Object.getOwnPropertyNames(respuesta)
    titulos.slice(0, 1)
    infovisit.value = {
      labels: respuesta.ejeX,
      datasets: []
    }
    for (let index = 0; index < titulos.length; index++) {
      if (titulos[index] != "ejeX") {
        infovisit.value.datasets.push({
          label: titulos[index],
          data: respuesta[titulos[index]],
        })
      }
    }
    cargando.value = false
  });
}

onBeforeMount(() => {
ObtenDatos()
});

onMounted(() => {

});

watch(
  cargando, (newValue, oldValue) => {
  if (newValue == false) {
    setTimeout(() => {
      CrearGrafica()
    }, 1000);

  }
}
)

watch(() =>props.Fecha,(newValue,oldValue) =>{
if (newValue.start!= undefined && newValue.end!=undefined ) {
  ObtenDatos()
}
})
</script>
<template>

  <div class="container">
    <div class="w-100 d-flex justify-content-between mt-3">
      <div class="" style="
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #21c997;
            display: flex;
            justify-content: center;
            align-items: center;
          ">
        <i class="fa fa-file-invoice-dollar" style="color: white; font-size: 1.5rem"></i>
      </div>
      <div class="mt-2">
        <h4>Historial de visitas y gastos</h4>
      </div>
      <div>
        <button class="btn rounded-circle mt-1" @click="Expandir">
          <i class="fa fa-expand" v-if="!isExpanded"></i>
          <i class="fa fa-compress" v-else></i>
        </button>
      </div>
    </div>
  </div>
  <div class="w-100 mt-5">
    <div class="w-50 mx-auto my-auto d-flex justify-content-center">
      <MazSpinner v-if="cargando" :size="'48'" color="secondary">
      </MazSpinner>
    </div>
  </div>
  <div class="pb-0 card-header mb-0">
    <!--  eslint-disable-next-line vue/no-v-html -->
    <p v-if="props.description" class="text-sm" v-html="props.description" />
  </div>
  <div class="p-3 card-body">
    <div class="chart">
      <canvas :id="props.id" class="chart-canvas" :height="props.height"></canvas>
    </div>
  </div>
</template>
