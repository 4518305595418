<template>
    <div>
        <div :class="Editar ? 'card card-edition mt-4' : 'card  mt-4'">
            <div class="card-body" @mouseover="MuestraEdicion = true" @mouseleave="MuestraEdicion = false">
                <MazTextarea v-if="Editar" class="w-100" v-model="Pregunta.contenido" :debounce="1000" @input="cambio" />
                <p v-else class="card-text">
                    {{ Pregunta.contenido }}
                </p>
                <div v-if="OpDisponibles.length > 0" style="gap: 3px;" class="d-flex">
                    <div v-if="Editar" class="d-flex flex-column">
                        <MazInput v-for="(Option,index) in OpDisponibles" :key="index"  class="mt-1" v-model="Option.contenido">

                        </MazInput>
                    </div>
                    <div v-else style="gap: 4px" class="d-flex">
                        <MazBadge  v-for="Option in OpDisponibles" color="secondary" :key="Option.contenido">
                            {{Option.contenido}}
                        </MazBadge>
                    </div>
                </div>
                <Transition v-if="Editar == false" name="fade">
                    <div v-if="MuestraEdicion" style="cursor: pointer;"
                        class="w-100 d-flex justify-content-end text-primary" @click="Editar = true">
                        <i class="fas fa-pencil-alt"></i>
                    </div>
                </Transition>
                <div v-if="Editar" class="w-100 d-flex mt-2 justify-content-between">
                    <button class="btn btn-warning" @click="Cancelar">
                        Cancelar
                    </button>
                    <button class="btn btn-success" @click="Guardar">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MazBadge from 'maz-ui/components/MazBadge'
import MazTextarea from 'maz-ui/components/MazTextarea'
import MazInput from 'maz-ui/components/MazInput'

export default {
  name: "CardPregunta",
  components: {
    MazBadge,
    MazTextarea,
    MazInput,
  },
  props: {
    PreguntaObj: { type: Object, default: () => ({}) },
    Opciones: { type: Array, default: () => [] },
    Idioma: { type: String, default: null },
    info: { type: Array, default: () => [] },
  },
  emits: [
    "update:PreguntaObj",
    "update:Opciones",
    "updateInfo",
    "updateInfo",
  ],
  data() {
    return {
      Editar: false,
      MuestraEdicion: false,
      Pregunta: { ...this.PreguntaObj },
      TextoAnterior: '',
      OpDisponibles: [ ...this.Opciones ],  //clon del array
      OpcionesAnteriores: [],
      editableInfo: [...this.info],
    };
  },
  watch: {
    Editar(valor) {
      if (valor) {
        this.TextoAnterior = this.Pregunta.contenido;
        this.OpcionesAnteriores = JSON.parse(JSON.stringify(this.OpDisponibles));
      }
    },
  },
  beforeMount() {
    this.Pregunta = { ...this.PreguntaObj };
    this.OpDisponibles = this.Opciones.map(opcion => {
       const opcionFiltrada = opcion.idiomas.find(x => x.idioma === this.Idioma);
      return { ...opcionFiltrada };
    });
  },
  methods: {
    Cancelar() {
      this.Pregunta.contenido = this.TextoAnterior;
      this.OpDisponibles = JSON.parse(JSON.stringify(this.OpcionesAnteriores));
      this.Editar = false;
    },
    Guardar() {
      this.$emit('update:PreguntaObj', this.Pregunta.contenido);
      this.$emit('update:Opciones', this.OpDisponibles);
      this.Editar = false;
    },
  },
};

</script>

<style>
.card-edition {
    min-width: 200px !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>