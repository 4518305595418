<template>
    <div class="card mx-2 p-3 mx-lg-4 p-lg-4">
        <div class="d-flex justify-content-between">
            <registros-x-pagina :value="localBaseGetsListado.PaginaLen" @update="(value) => {
                localBaseGetsListado.PaginaLen = value;
                Actualiza(0);
            }
                " />

            <h3 class="m-0 p-0 text-primary mt-2">{{ Pedido.pedido.sucursal + " - " + CreaDireccion }} </h3>

            <div class="d-flex justify-content-end">
                <button class="btn btnCustom text-muted" :to="{}" @click="MostrarFiltro = !MostrarFiltro">
                    <i class="fas fa-filter text-primary"></i>
                </button>

                <button :to="{}" class="btn btnCustom text-muted" @click="Actualiza()">
                    <i class="fas fa-sync-alt text-primary"></i>
                </button>

                <!--Iconox-->
                <button v-if="PermiteAgregar" :to="{}" @click="MostrarNuevo = !MostrarNuevo"
                    class="btn btn-outline-success">
                    <i class="fas fa-plus"></i>
                </button>
            </div>
        </div>

        <div class="w-100 mt-2">
            <MazSpinner v-if="cargando" class=" w-100 d-flex justify-content-center" :size="'48'" color="secondary"></MazSpinner>
            <div v-else class="w-90 mx-auto d-flex flex-column">
                <div style="border-bottom: 1px solid #c4c4c4;" class="w-100 d-flex text-center">
                    <div style="width: 25%;" class="mb-4">
                        <div style="height: 90%; border-right: 1px solid #c4c4c4 " class="mt-3">
                            <h4 style="font-weight: bold; color: #c4c4c4;" class="text-muted">Numero de pedido:</h4>
                            <div style="font-weight: bold;" class="text-muted">
                                {{Pedido.pedido.noPedido}}
                            </div>
                        </div>
                    </div>
                    <div style="width: 25%;" class="mb-4">
                        <div style="height: 90%; border-right: 1px solid #c4c4c4 " class="mt-3">
                            <h4 style="font-weight: bold; color: #c4c4c4;" class="text-muted">Estatus de pedido:</h4>
                            <div :style="Estatus" class="w-80 mx-auto">
                                {{EstatusTexto}}
                            </div>
                        </div>
                    </div>
                    <div style="width: 25%;" class="mb-4">
                        <div style="height: 90%; border-right: 1px solid #c4c4c4 " class="mt-3">
                            <h4 style="font-weight: bold; color: #c4c4c4;" class="text-muted">Fecha de entrega:</h4>
                            <div style="font-weight: bold;" class="text-muted">
                                {{ FechaEntrega }}
                            </div>
                        </div>
                    </div>
                    <div style="width: 25%;" class="mb-4">
                        <div style="height: 90%" class="mt-3">
                            <h4 style="font-weight: bold; color: #c4c4c4;" class="text-muted">Quien recibió:</h4>
                            <div style="font-weight: bold;" class="text-muted">

                                {{Pedido.pedido.receptor != null ? Pedido.pedido.receptor : ""}}
                            </div>
                        </div>
                    </div>
                </div>

                <TablaDetalleGafete class="mt-5" :Lista="Pedido.gafetes.listado" :estatus="Pedido.pedido.eEstatusEnvio" @Imprimir="Imprimir" @Borrar="BorrarGafete" />
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <router-link class="mt-2" :to="{}" @click="Cancelar"><i
                    class="fa fa-chevron-left me-2"></i>Cancelar</router-link>
            <!-- <button class="btn btn-success mb-0" @click="Guardar()">Guardar</button> -->
        </div>

        <div class="d-lg-flex">
            <div class="ms-auto">
                <Paginacion :pagina-len="Pedido.gafetes.parametros.paginalen"
                    :pagina-no="Pedido.gafetes.parametros.paginaNo" :total-paginas="Pedido.gafetes.totalPaginas"
                    @update:pagina-no="(value) => Actualiza(value)" />
            </div>
        </div>
    </div>
</template>

<script>
import RegistrosXPagina from "@/Componentes/Listados/RegistrosXPagina.vue";
import Paginacion from "@/Componentes/Listados/Paginacion.vue";
import MazSpinner from "maz-ui/components/MazSpinner";
import TablaDetalleGafete from './TablaDetalleGafete.vue';

export default {
    name: "DetallesPedido",
    emits: ["Cancelar", "actualiza","BorrarGafete","Imprimir"],
    components: {
        RegistrosXPagina, Paginacion, MazSpinner,
        TablaDetalleGafete
    },
    props: {
        Pedido: { type: Object, default: null },
        cargando:{type:Boolean, default: false},
    },
    computed: {
        FechaEntrega(){
            if (this.Pedido.pedido.fechaEntrega == null) {
                return ""
            }
            let fecha = this.Pedido.pedido.fechaEntrega.split("T")[0]
            return fecha
        },
        CreaDireccion() {
            let direccion = JSON.parse(this.Pedido.pedido.direccionEntrega)
            let calle = direccion.DirCalle != null ? direccion.DirCalle : ""
            let num = direccion.DirCalleNo != null ? direccion.DirCalleNo : ""
            let colonia = direccion.Colonia != null ? direccion.Colonia : ""
            let estado = direccion.Estado != null ? direccion.Estado : ""
            return calle + " " + num + ", " + colonia + " " + estado
        },
        Estatus(){
        if (this.Pedido == null) {
            return ""
        }
        switch (this.Pedido.pedido.eEstatusEnvio) {
            
            case "RegistrarPago":
                
                return 'border-radius:10px; background-color: #ffdc7d; font-weight: bold; color: #ffa701; cursor:pointer;'
            case "Imprimir":
                
             return 'border-radius:10px; background-color: #22c997; font-weight: bold; color: white; cursor:pointer;'
            case "Verificar":
                
             return 'border-radius:10px; background-color: #95edcd; font-weight: bold; color: #22c997; cursor:pointer;'
            case "Entregar":
                
             return 'border-radius:10px; background-color: #a8efeb; font-weight: bold; color: #2ccec6; cursor:pointer;'
            case "Entregado":
                
             return 'border-radius:10px; background-color: white; font-weight: bold; color: #22c997;'

             default:
                return ''; 
        }
        
    },
    EstatusTexto(){
        if (this.Pedido == null) {
            return ""
        }
        switch (this.Pedido.pedido.eEstatusEnvio) {
            
            case "RegistrarPago":
                
                return "Registrar pago"

             default:
                return this.Pedido.pedido.eEstatusEnvio; 
        }
    },

    },
    data() {
        return {
            localBaseGetsListado: {
                Borrados: false,
                PaginaNo: 0,
                PaginaLen: 10,
                Filtro: "",
            },
        }
    },

    mounted() {

    },
    methods: {
        Cancelar() {
            this.$emit("Cancelar")
        },
        Actualiza(paginaNo) {

            if (paginaNo != null) {
                this.localBaseGetsListado.PaginaNo = paginaNo;
                // Emitir un evento con el valor seleccionado para que el componente padre pueda reaccionar
                this.$emit("actualiza", this.localBaseGetsListado);
            } else this.$emit("actualiza", this.localBaseGetsListado);
        },
        BorrarGafete(Datos){
            this.$emit("BorrarGafete",Datos)
        },
        Imprimir(Datos){
            this.$emit("Imprimir",Datos)
        },

    },
}
</script>

<style lang="scss" scoped>
.card {
    display: flex;
    /* Habilita flexbox para el contenido de la tarjeta */
    margin: 7px;
    /* Espaciado alrededor de cada tarjeta */
    flex-basis: calc(100% - 14px);
    /* Ajusta este valor según el número deseado de tarjetas por fila */
    min-height: 5.2em;
}

.btnCustom {
    box-shadow: none !important;
    border: none;
}
</style>