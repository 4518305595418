<template>
  <div class="card bg-light p-3">
    <div class="row">
      <div class="col">
        <MazRadio value="Punto" @change="updateModel" id="hu2" class="text-primary" color="secondary"
          v-model="Seleccion">
          <h3 class="text-primary mt-2">Recoger en un punto de entrega</h3>
        </MazRadio>
      </div>
      <div class="row">
        <div class="col">
          Sucursal Revolución
        </div>
      </div>
      <div class="row">
        <div class="col">
          {{
          puntosEntrega
            ? "" +
            puntosEntrega.dDireccion.dirCalle +
            " #" +
            puntosEntrega.dDireccion.dirCalleNo +
            " " +
            puntosEntrega.dDireccion.colonia +
            "," +
            puntosEntrega.dDireccion.municipio +
            "," +
            puntosEntrega.dDireccion.cp +
            " " +
            puntosEntrega.dDireccion.ciudad +
            "," +
            puntosEntrega.dDireccion.estado
            : ""
        }}
        </div>
        <div class="col">
          {{
            puntosEntrega
              ? "Entrega: " +
              puntosEntrega.datos.entrega +
              " " +
              puntosEntrega.datos.telEntrega
              : ""
          }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="text-secondary">Lu a Vi: 9:00 a 17:00</p>
        </div>
      </div>
      <div class="row">
        <div v-if="mostrarTelefono">
          <!-- <MazInput class="col-6 mb-3 px-2" label="Nombre y apellido de quién recoge"
            @update:model-value="(value) => update('cp', value)">
            <template #left-icon> </template>
<template #right-icon> </template>
</MazInput> -->
          <div class="col-6 mb-3 px-2">
            <Telefono />

          </div>
        </div>

        <div class="col mt-3">
          <a href="https://www.google.com/maps/place/eClock/@19.4365958,-99.1528583,17z/data=!3m1!4b1!4m6!3m5!1s0x85d1f9d226e2bf47:0xe47e00fe9ca9eaf0!8m2!3d19.4365958!4d-99.1528583!16s%2Fg%2F11qf_s_rjr?entry=ttu"
            target="_blank" class="text-primary" rel="noopener noreferrer">Ver punto en el mapa</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PuntoEntrega from "@/api-services/puntoentrega.yacalificame.service";
import MazRadio from 'maz-ui/components/MazRadio';
import Telefono from './../../../Componentes/Telefono.vue'

export default {
  components: {
    MazRadio,
    Telefono,
  },
  props: {
    modelValue: { type: String, default: "Opcional" },
    direccion:{type:Object,default:null}
  },
  watch: {
    modelValue(Valor){
      this.Seleccion = Valor
    },
  },
  emits: ["update:model-value"],
  data() {
    return {
      //direccion: null,
      puntosEntrega: null,
      radioSeleccionado: null,
      mostrarTelefono: false,
      Seleccion: this.modelValue,
    };
  },
  beforeMount() {
    this.puntosEntrega = this.direccion
    // PuntoEntrega.gets(this, {}, (response) => {
    //   console.debug("La respuesta de los puntos entrega es ", response);
    //   this.puntosEntrega = response.data[0];
    // });
  },
  methods: {
    updateModel() {
      this.$emit('update:model-value', this.Seleccion);
    },
  },
};
</script>

<style lang="scss" scoped></style>
