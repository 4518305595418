<!-- @format -->

<template>
  <div>
    <div class="row">
      <MazInput v-model="localdDireccion.etiqueta" class="col-12 mb-3 px-2" label="Etiqueta" required
        @update:model-value="(value) => update('cp', value)">
        <template #left-icon>
          <i class="fas fa-tag text-primary"></i>
        </template>
        <template #right-icon> </template>
      </MazInput>
    </div>
    <div class="row">
      <PaisSelect v-model="localdDireccion.pais" class="col-6 mb-3 px-2" campo-valor="iso2" />
      <MazInput v-model="localdDireccion.cp" class="col-6 mb-3 px-2" label="Código Postal"
        :autocomplete="AutoCompletarRandom" :error="ErrorCodigoPostal"
        @update:model-value="(value) => update('cp', value)">
        <template v-if="BuscarCP" #left-icon>
          <i class="fas fa-search"></i>
        </template>
        <template v-if="BuscarCP" #right-icon>
          <router-link :to="{}" class="text-primary small p-2" @click="OnMostrarBuscarCP">No conozco mi CP</router-link>
        </template>
      </MazInput>
    </div>
    <div class="row">
      <MazInput v-model="localdDireccion.municipio" class="col-5 mb-3 px-2" type="text" label="Municipio/Alcaldía"
        :autocomplete="AutoCompletarRandom" :disabled="BloquearMinucipio"
        @update:model-value="(value) => update('municipio', value)" />
      <MazInput v-model="localdDireccion.ciudad" class="col-4 mb-3 px-2" type="text" label="Ciudad"
        :autocomplete="AutoCompletarRandom" :disabled="BloquearMinucipio"
        @update:model-value="(value) => update('ciudad', value)" />
      <MazInput v-model="localdDireccion.estado" class="col-3 mb-3 px-2" type="text" label="Estado"
        :autocomplete="AutoCompletarRandom" :disabled="BloquearMinucipio"
        @update:model-value="(value) => update('estado', value)" />
    </div>
    <div class="row">
      <MazInput v-model="localdDireccion.colonia" type="text" label="Colonia" :autocomplete="AutoCompletarRandom"
        class="col-12 mb-3 px-2" @update:model-value="(value) => update('colonia', value)" />
    </div>
    <div class="row">
      <MazInput v-model="localdDireccion.dirCalle" type="text" label="Calle" :autocomplete="AutoCompletarRandom"
        class="col-5 mb-3 px-2" @update:model-value="(value) => update('dirCalle', value)" />

      <MazInput v-model="localdDireccion.dirCalleNo" type="text" label="Número exterior"
        :autocomplete="AutoCompletarRandom" class="col-4 mb-3 px-2"
        @update:model-value="(value) => update('dirCalleNo', value)" />
      <MazInput v-model="localdDireccion.dirInterior" type="text" label="Interior" :autocomplete="AutoCompletarRandom"
        class="col-3 mb-3 px-2" @update:model-value="(value) => update('dirInterior', value)" />
    </div>
    <div class="row">
      <MazInput v-model="localdDireccion.receptor" class="col-6 mb-3 px-2" label="Nombre y apellido de quién recibe"
        @update:model-value="(value) => update('cp', value)">
        <template #left-icon> </template>
        <template #right-icon> </template>
      </MazInput>
      <Telefono class="col-6 mb-3 px-2" />
    </div>
    <Modal v-if="MostrarBuscarCP" :show="MostrarBuscarCP" :auto-close="true" modal-classes="modal-dialog-centered"
      @close="MostrarBuscarCP = false">
      <template #header>
        <h5>Proximamente podrás buscarlo</h5>
      </template>
    </Modal>
  </div>
</template>

<script>
import PaisSelect from "@/Componentes/PaisSelect.vue";
import Telefono from "./../../../Componentes/Telefono.vue";
import cpMexico from "@/api-services/cpMexico.service";
import Modal from "@/Componentes/Modal.vue";
export default {
  name: "AgregarUbicacion",
  components: {
    PaisSelect,
    Telefono,
    Modal,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        receptor: null,
        etiqueta: null,
        dirCalle: null,
        dirCalleNo: null,
        dirInterior: null,
        colonia: null,
        tipoAsentamiento: null,
        municipio: null,
        ciudad: null,
        estado: null,
        pais: null,
        cp: null,
        gps: null,
      }),
    },

    // Your component's props definition goes here
  },
  emits: ["update:modelValue"],
  data() {
    return {
      localdDireccion: this.modelValue,
      MostrarBuscarCP: false,
      CPs: [],
      CPAlCargar: null,
    };
  },
  computed: {
    ErrorCodigoPostal() {
      if (!this.BuscarCP) return false;
      if (this.localdDireccion.cp == null || this.localdDireccion.cp == "")
        return false;
      return (
        this.localdDireccion.cp.length != 5 ||
        (this.CPs.length == 0 && this.CPAlCargar != this.localdDireccion.cp)
      );
    },
    AutoCompletarRandom() {
      return `${this.$options.name}-Auto-${Math.random()}`;
    },
    ColoniaEtiqueta() {
      return this.localdDireccion.tipoAsentamiento != null
        ? this.localdDireccion.tipoAsentamiento
        : "Colonia";
    },
    Class() {
      return {
        "col-4": true,
        "mb-3": true,
        "px-2": true,
      };
    },
    BloquearMinucipio() {
      return this.BuscarCP;
      if (!this.BuscarCP) return false;
      return this.CPs.length >= 1;
    },
    // Your component's computed properties go here
    BuscarCP() {
      return this.localdDireccion != null && this.localdDireccion.pais == "MX";
    },
  },
  mounted() {
    // Your component's mounted hook goes here
    if (
      this.localdDireccion != null &&
      (this.localdDireccion.pais == "" || this.localdDireccion.pais == null)
    ) {
      this.localdDireccion.pais = "MX";
    }
    if (this.localdDireccion.cp != null && this.localdDireccion.cp.length == 5)
      this.CPAlCargar = this.localdDireccion.cp;
    //* No se buscan los datos al cargar ya que podría perder los cambios previamente guardados
    /*
      if (this.localdDireccion.cp != null && this.localdDireccion.cp.length == 5)
        this.update("cp", this.localdDireccion.cp);*/
  },
  methods: {
    OnMostrarBuscarCP() {
      console.log("Mostrar");
      this.MostrarBuscarCP = true;
    },
    Cargar(CPs) {
      this.Limpiar();
      this.CPs = CPs;
      console.log(CPs);
      if (CPs.length >= 1) {
        this.localdDireccion.colonia = "";
        this.localdDireccion.municipio = CPs[0].municipio;
        this.localdDireccion.ciudad = CPs[0].ciudad;
        this.localdDireccion.estado = CPs[0].estado;
      }
      if (CPs.length == 1) {
        this.localdDireccion.colonia = CPs[0].asentamiento;
        this.localdDireccion.tipoAsentamiento = CPs[0].tipoAsentamiento;
        this.localdDireccion.zona = CPs[0].zona;
      }
    },
    Limpiar() {
      this.CPs = [];
      this.localdDireccion.tipoAsentamiento = null;
      this.localdDireccion.zona = null;
    },
    update(campo, value) {
      if (campo == "cp" && this.BuscarCP) {
        if (value.length == 5) {
          //buscar
          cpMexico.gets(this, value, (response) => {
            this.Cargar(response.data);
          });
        } else this.Limpiar();
      }
      // this.localdDireccion[campo] = value;
      this.$emit("update:modelValue", this.localdDireccion);
    },

    // Your component's methods go here
  },
  // Your component's JavaScript logic goes here
};
</script>

<style scoped>
/* Your component's CSS styles go here */
</style>