<template>
  <div class="card bg-light p-3 col-12">
    <div class="row">
      <div class="col">
        <MazRadio value="Domicilio" @change="updateModel" id="hu1" class="text-primary" color="secondary"
          v-model="Seleccion">
          <h3 class="text-primary mt-2">Enviar a domicilio</h3>
        </MazRadio>
      </div>
      <div class="row">
        <div class="col">{{ SucursalElegida.etiqueta }}</div>
        <hr />
      </div>
      <div class="row">
        <div class="col-6">
          {{ SucursalElegida.dDireccion.dirCalle }}
          {{ SucursalElegida.dDireccion.dirCalleNo }}
          <small v-if="SucursalElegida.dDireccion.dirInterior != null">{{
            SucursalElegida.dDireccion.dirInterior }}</small>
          {{ SucursalElegida.dDireccion.colonia }}
          {{ SucursalElegida.dDireccion.cp }}
          {{ SucursalElegida.dDireccion.municipio }}
          {{ SucursalElegida.dDireccion.ciudad }}
          {{ SucursalElegida.dDireccion.estado }}
          {{ SucursalElegida.dDireccion.pais }}
        </div>
        <div class="col-6">
          <p><strong>Recibe:</strong> {{ SucursalElegida.datos.receptor }} - {{ SucursalElegida.datos.telReceptor }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p style="cursor: pointer" class="text-primary" @click="MostrarListadoSucursales = !MostrarListadoSucursales">
            Editar o elegir otro domicilio
          </p>
        </div>
      </div>
    </div>

    <ModalListadoSucursales v-if="MostrarListadoSucursales" :msg-error="msgError" :cargando="cargando" :type="nuevoType"
      :mostrar-ir-a-edicion="mostrarIrAEdicion" @update:mostrar="(value) => (MostrarListadoSucursales = value)"
      @update:datos="(datos) => ActualizaDatosNuevo(datos)" @CambiaSucursal="CambiaSucursal"
      @EditaSucursal="EditaSucursal">
      <template v-if="$slots.nuevo" #nuevo>
        <slot name="nuevo"></slot>
      </template>
    </ModalListadoSucursales>
  </div>
</template>

<script>
import ModalListadoSucursales from "@/Componentes/Listados/ModalListadoSucursales.vue";
import MazRadio from 'maz-ui/components/MazRadio';

export default {
  name: "CardDomicilio",
  components: {
    ModalListadoSucursales,
    MazRadio,
  },
  props: {
    registro: {
      type: Object,
      required: true,
    },
    fechaSolicitud: {
      type: String,
      default: "",
    },
    fechayHoraEntrega: {
      type: String,
      default: "",
    },
    recibio: {
      type: String,
      default: "",
    },
    Domicilio: {
      type: Object,
      default: null,
    },
    DireccionSucursal: {
      type: Object,
      default: null,
    },
    mostrarIrAEdicion: {
      type: Boolean,
      default: true,
      description: "Indica si se mostrará la opción de ir a edición",
    },
    cargando: {
      type: Boolean,
      default: true,
    },
    msgError: {
      type: String,
      default: "",
    },
    nuevoType: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini", "large"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|large|"") ',
    },
    modelValue: { type: String, default: "Opcional" },
  },
  emits: ["EditarDom", "editar", "borrar", "reactivar", "update:datos-nuevo", "update:DireccionSucursal", "change", "update:model-value"],
  data() {
    return {
      Colaborador: null,
      Sucursal: null,
      MostrarListadoSucursales: false,
      SucursalElegida: null,
      puntosEntrega: null,
      radioSeleccionado: null,
      mostrarTelefono: false,
      Seleccion: null,
    };
  },

  computed: {
    slots() {
      return Object.keys(this.$slots);
    },
  },
  watch: {
    DireccionSucursal(Valor) {
      this.SucursalElegida = Valor
    },
    Seleccion(Valor) {
      this.$emit("update:model-value", Valor);
    },
    modelValue(Valor){
      this.Seleccion = Valor
    },
  },
  beforeMount() {
    this.SucursalElegida = this.DireccionSucursal
    this.Seleccion = this.value
  },
  mounted() {
    //this.Actualiza();
  },
  methods: {
    ActualizaDatosNuevo(Datos) {
      this.$emit("update:datos-nuevo", Datos);
    },
    Editar() {
      this.$emit("EditarDom");
    },
    CambiaSucursal(Valor) {
      this.$emit("update:DireccionSucursal", Valor)
    },
    updateModel() {
      this.$emit('update:model-value', this.Seleccion);
    },
  },
};
</script>

<style lang="scss" scoped>
/* .selected {
  border: 2px solid blue;
} */
</style>