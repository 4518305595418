<!-- @format -->

<template>
  <Edicion
    :servicio="Servicio"
    :mostrarEncargado="false"
    :mostrarTuLogotipo="false"
    @update:datos="(value) => (Datos = value)"
  >
    <template #Completo>
      <div class="d-flex justify-content-between">
        <h3>Personaliza tus preguntas</h3>
        <NavBarDatosColaborador />
      </div>
      <p>
        En esta sección podrás adaptar preguntas con tus preferencias, para que
        tus clientes cuenten su experiencia.
      </p>
      <small
        >Recomendamos agregar máximo 5 preguntas para una evaluación más
        efectiva</small
      >
      <div class="mb-3 mt-3">
        <MazSwitch :name="Datos.ticket" color="secondary">
          Quiero que mis clientes capturen ticket
        </MazSwitch>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-4">
          <MenuEncuestas v-model="Seleccion" />
        </div>
      </div>
      <div
        v-if="Seleccion == 'Preguntas'"
        class="mt-4 p-2 row d-flex justify-content-between"
      >
        <!-- Inicia la importacion de componente Preguntas -->
        <EdicionPreguntas
          :campoNombre="Datos.campoNombre"
          :campoEmail="Datos.campoEmail"
          :campoFecha="Datos.campoFecha"
          :listadoPreguntas="Datos.listadoPreguntas"
          @update:campoNombre="(value) => (Datos.campoNombre = value)"
          @update:campoEmail="(value) => (Datos.campoEmail = value)"
          @update:campoFecha="(value) => (Datos.campoFecha = value)"
          @update:listadoPreguntas="(value) => (Datos.listadoPreguntas = value)"
        />
        <!-- Termina la importacion de componente Preguntas -->
      </div>
      <div v-else class="mt-4 row d-flex justify-content-between">
        <!-- Inicia la importacion de componente Idiomas -->
        <EdicionIdiomas
          :listadoPreguntas="Datos.listadoPreguntas"
          @update:listadoPreguntas="(value) => (Datos.listadoPreguntas = value)"
        />
        <!-- Termina la importacion de componente Idiomas -->
      </div>
    </template>
  </Edicion>
</template>
  
<script>
import Servicio from "@/api-services/encuestas.service";
import Edicion from "@/Componentes/Listados/Edicion.vue";
import MazSwitch from "maz-ui/components/MazSwitch";
import MenuEncuestas from "../../Componentes/MenuEncuestas.vue";
import NavBarDatosColaborador from "@/Componentes/Navbars/NavBarDatosColaborador.vue";
import EdicionIdiomas from "@/Vistas/Encuestas/Componentes/EdicionIdiomas.vue";
import EdicionPreguntas from "@/Vistas/Encuestas/Componentes/EdicionPreguntas.vue";

export default {
  name: "EncuestasEdicion",
  components: {
    Edicion,
    EdicionIdiomas,
    EdicionPreguntas,
    MazSwitch,
    NavBarDatosColaborador,
    MenuEncuestas,
  },
  emits: [
    "update:campoNombre",
    "update:campoEmail",
    "update:campoFecha",
    "editar",
  ],
  data() {
    return {
      Datos: {},
      Seleccion: "Preguntas",
      localNombre: this.campoNombre,
      localCorreoElectronico: this.campoEmail,
      localFechaNac: this.campoFecha,
    };
  },
  computed: {
    Encuesta() {
      if (this.Datos.listado == null) return {};
      return this.Datos.listado;
    },
    Servicio() {
      return Servicio;
    },
    EstiloCampo() {
      return "col-lg-4 col-12 col-sm-12 col-md-12 px-2 mb-campo";
    },
  },
  methods: {
    update(campo, value) {
      // console.debug(`Actualizando ${campo} a ${value}`);
      this.$emit(`update:${campo}`, value);
    },
    ActualizaDatos(Datos) {
      this.$emit("update:model-value", Datos);
    },
  },
};
</script>
  
  <style lang="scss" scoped></style>
  