<!-- @format -->

<template>
  <div class="mb-4">
    <MazSelect
      v-model="selected"
      size="sm"
      label="Registros por página"
      :options="options"
      @update:model-value="onChange"
      color=secondary
    />
  </div>
</template>

<script>
import MazSelect from "maz-ui/components/MazSelect";

export default {
  name: "RegistrosXPagina",
  components: {
    MazSelect,
  },
  props: {
    // Puedes personalizar las opciones pasándolas como una prop
    options: {
      type: Array,
      default: () => [10, 20, 50, 100],
    },
    // Valor inicial seleccionado
    value: {
      type: Number,
      default: 10,
    },
  },
  emits: ["update"],
  data() {
    return {
      selected: this.value, // Usar el valor inicial como seleccionado
    };
  },
  methods: {
    onChange() {
      console.log("selected", this.selected);
      // Emitir un evento con el valor seleccionado para que el componente padre pueda reaccionar
      this.$emit("update", this.selected);
    },
  },
};
</script>
