<template>
  <div class="nav-wrapper end-0">
    <div class="container justify-content-center">
      <ul class="nav nav-pills nav-fill p-1" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link mb-0 px-0 py-1 active active nav-item"
            data-bs-toggle="tab"
            href=""
            role="tab"
            aria-selected="true"
            @click="cambiarValor('Preguntas')"
            >Preguntas</a
          >
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link mb-0 px-0 py-1"
            data-bs-toggle="tab"
            href="#"
            role="tab"
            aria-selected="false"
            @click="cambiarValor('Idiomas')"
            >Idiomas</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
  
  <script>
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "NavPill",
  components: {},
  props: {
    modelValue: {
      type: String,
      required: true,
      default: "Preguntas",
    },
  },
  data() {
    return {
      Seleccion: "Preguntas",
    };
  },
  mounted() {
    setNavPills();
  },

  methods: {
    cambiarValor(nuevoValor) {
      this.Seleccion = nuevoValor;
      // Emitir un evento para cambiar el valor
      this.$emit("update:modelValue", nuevoValor);
    },
  },
};
</script>
  
  <style lang="css" scoped>
</style>