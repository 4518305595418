<!-- @format -->

<template>
  <div>
    <div v-if="msgError || cargando" class="text-center mt-7">
      <argon-alert v-if="msgError" color="danger"> <strong>Error:</strong> {{ msgError }} </argon-alert>

      <MazSpinner v-if="cargando" :size="'48'" color="secondary"></MazSpinner>
    </div>

    <div v-if="$slots.default && !cargando" :class="bodyClasses">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ArgonAlert from "@/components/ArgonAlert.vue";
import MazSpinner from "maz-ui/components/MazSpinner";

export default {
  name: "Cargando",
  components: { MazSpinner, ArgonAlert },
  props: {
    msgError: {
      type: String,
      default: "",
      description: "Muestra el mensaje de error",
    },
    //Muestra que esta cargando
    cargando: { type: Boolean, default: true },
    bodyClasses: {
      type: [Object, String],
      default: "",
      description: "Modal Body css classes",
    },
  },
};
</script>

<style scoped></style>
