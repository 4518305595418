<!-- @format -->

<template>
  <div
    ref="modal"
    class="modal fade"
    @click.self="closeModal(true)"
    :class="[{ 'show d-block': show }, { 'd-none': !show }]"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div
      class="modal-dialog"
      role="document"
      :class="[
        { 'modal-notice': type === 'notice' },
        { 'modal-dialog-centered': centered },
        { 'modal-mini': type === 'mini' },
        { 'modal-lg': type === 'large' },
        modalClasses,
      ]"
    >
      <div class="modal-content" :class="[gradient ? `bg-gradient-${gradient}` : '', modalContentClasses]">
        <div class="modal-header" style="border: transparent" :class="[headerClasses]" v-if="$slots.header">
          <slot name="header"></slot>
          <slot name="close-button">
            <button
              type="button"
              class="close position-absolute top-0 end-0 p-3"
              v-if="showClose"
              @click="closeModal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i style="color: #2ecfb2;" class="fas fa-times "></i>
            </button>
          </slot>
        </div>

        <div v-if="$slots.default" class="modal-body p-0 m-0" :class="bodyClasses">
          <slot name="body"></slot>
        </div>

        <div class="modal-footer" style="display: inline-block; border: transparent;" :class="footerClasses" v-if="$slots.footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  components: {},
  props: {
    show: Boolean,
    showClose: {
      type: Boolean,
      default: true,
    },
    //Cierra el modal si das click afuera del mismo
    autoClose: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: true,
      description: "Whether modal should be appended to document body",
    },
    scrollToBottom: {
      type: Boolean,
      default: true,
      description: "Whether modal should scroll to it's bottom automatically",
    },
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini", "large"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|large|"") ',
    },
    modalClasses: {
      type: [Object, String],
      default: null,
      description: "Modal dialog css classes",
    },
    modalContentClasses: {
      type: [Object, String],
      default: null,
      description: "Modal dialog content css classes",
    },
    gradient: {
      type: String,
      default: null,
      description: "Modal gradient type (danger, primary etc)",
    },
    headerClasses: {
      type: [Object, String],
      default: null,
      description: "Modal Header css classes",
    },
    bodyClasses: {
      type: [Object, String],
      default: null,
      description: "Modal Body css classes",
    },
    footerClasses: {
      type: [Object, String],
      default: null,
      description: "Modal Footer css classes",
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration",
    },
    quitarModalOpen: {
      type: Boolean,
      default: true,
      description: "Indica si removerá el modal-open",
    },
  },
  emits: ["update:show", "close"],
  watch: {
    show(val) {
      this.Mostrar(val);
    },
  },
  mounted() {
    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
    if (this.show) this.Mostrar(true);
  },
  unmounted() {
    if (this.appendToBody && this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.Mostrar(false);
  },

  methods: {
    closeModal(EsFuera) {
      console.debug("closeModal", EsFuera);
      if (EsFuera != undefined && EsFuera == true) if (!this.autoClose) return;
      this.$emit("update:show", false);
      this.$emit("close");
      this.Mostrar(false);
    },
    scrollModalToBottom() {
      if (!this.scrollToBottom) return;
      let elm = this.$refs.modal;
      elm.scrollTop = elm.scrollHeight - elm.clientHeight;
      // console.debug(elm.scrollTop);
    },
    Mostrar(val) {
      let documentClasses = document.body.classList;
      // console.debug(documentClasses);
      if (val) {
        documentClasses.add("modal-open");
        this.$nextTick(this.scrollModalToBottom);
      } else {
        if (this.quitarModalOpen) documentClasses.remove("modal-open");
      }
    },
  },
};
</script>
<style>
.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
