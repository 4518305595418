<!-- @format -->

<template>
  <div class="card">
    <div class="card-body">
      <div class="col">
        <MazRadio :value="registro.etiqueta"  :name="Nombre" v-model="Seleccionado" color="secondary" style="font-size: 1rem;">
          Sucursal: {{ registro.etiqueta }}
        </MazRadio>
        <div class="row row-cols-12">
          <div class="col">
            <p v-html="direccionFormato"></p>
          </div>
        </div>
        <p>
          {{
          registro.datos && registro.datos.receptor
            ? registro.datos.receptor
            : "-"
        }}
          -
          {{
            registro.datos && registro.datos.telReceptor
              ? registro.datos.telReceptor
              : "-"
          }}
        </p>

        <button class="text-primary" @click="editarSucursal">Editar</button>
        <!-- <button class="text-primary" @click="EditaSucursal">Editar</button> -->
        
      </div>
    </div>
  </div>
</template>

<script>
import MazRadio from "maz-ui/components/MazRadio";
import Servicio from "@/api-services/ubicaciones.service";

export default {
  name: "SucursalesCardRegistro",
  components: {
    MazRadio,
  },
  props: {
    registro: {
      type: Object,
      required: true,
    },
    ListadoSucursales: {
      type: Array,
      default: null,
    },
    Nombre: {
      type: String,
      default: "flexRadioDefault1Sucursal",
    },
    modelValue: {
      type: String,
      default: "",
    },
    SucSelect: { type: String, default: null },
    SucEdit: { type: String, default: null },

  },
  data() {
    return {
      Sucursal: null,
      //Muestra el cargando
      cargando: false,
      //Indica que hay error
      MsgError: "",
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },
      Seleccionado: this.SucSelect,
      Editado: this.SucEdit,
    };
  },
  emits: ["editar", "borrar", "reactivar", "update:modelValue", "update:SucSelect", "update:SucEdit"],
  watch:{
    Seleccionado(Valor){
      this.$emit("update:SucSelect",Valor)
    },
    SucSelect(Valor){
      this.Seleccionado = Valor
    },
    Editado(Valor){
      this.$emit("update:SucEdit",Valor)
    },
    SucEdit(Valor){
      this.Editado = Valor
    },
  },
  beforeMount() {
    console.debug("El listado es: ", this.ListadoSucursales);
    this.Sucursal = this.ListadoSucursales;
  },
  computed: {
    direccionFormato() {
      const direccion = this.registro.dDireccion;
      return Servicio.linea1(direccion);
    },
  },
  mounted() {
    /* this.Actualiza(); */
  },
  methods: {
    validacionDatos() { },
    editarSucursal() {
      this.$emit("editar", this.registro); // Emitir evento editar con el registro
    },
    
  },
};
</script>

<style lang="scss" scoped>

h4.parrafo_azul {
  color: #343a40;
}

.text-primary .icons:hover {
  color: #343a40;
}

li {
  list-style: none;
}
</style>
