<template>
  <Edicion
    :servicio="Servicio"
    @update:datos="(value) => (Datos = value)"
    :mostrar-preguntas="false"
  >
    <template #Etiqueta>
      <MazInput
        v-model="Datos.ReceptInfo.Receptor"
        label="Receptor"
        class="mb-campo"
      >
        <template #left-icon> </template>
        <template #right-icon> </template>
      </MazInput>
      <Telefono v-model="Datos.ReceptInfo.TelReceptor" class="mb-campo" />
      <PersonalSelect class="mb-campo" v-model:value="Datos.adminsIDs" @update:value="(value) => (Personal = value)"/>
      <MazCheckbox class="mb-campo" v-model="Datos.esSucursal" size="sm"> Es sucursal </MazCheckbox>
      <AgregarLogotipo v-model:value="Datos.urlMiniatura" @update:value="(value) => (Datos.urlMiniatura = value)"/>
    </template>
    <template #Completo>
      <!-- <Mapa class="mt-3 mb-3" /> -->
      <DireccionSucursal
        id="domicilio"
        :dDireccion="Datos.dDireccion"
        @update:dDireccion="(value) => (Datos.dDireccion = value)"
        class="mt-3"
      />
    </template>
  </Edicion>
</template>
  
<script>
import AgregarLogotipo from "../../Vistas/Ubicaciones/Componentes/AgregarLogotipo.vue";
import Servicio from "@/api-services/ubicaciones.service";
// import Mapa from "@/Componentes/Mapa.vue";
import Telefono from "@/Componentes/Telefono.vue";
import Edicion from "@/Componentes/Listados/Edicion.vue";
import DireccionSucursal from "./Componentes/DireccionSucursal.vue";
import PersonalSelect from '../../Componentes/PersonalSelect.vue';

export default {
  name: "UbicacionesEdicion",
  components: {
    AgregarLogotipo,
    DireccionSucursal,
    Edicion,
    // Mapa,
    Telefono,
    PersonalSelect,
  },
  data() {
    return {
      sDatos: {
        receptor: "",
        telMovil: "",
      },
      datos: "",
      Datos: {},
      Personal:[],
    };
  },
  computed: {
    Servicio() {
      return Servicio;
    },
  },
  methods: {},
};
</script>
  
  <style lang="scss" scoped></style>