<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <div class="w-100">
      <div
        style="width: 90%"
        class="mx-auto d-flex flex-column flex-md-row justify-content-center align-items-center"
      >
        <img
          style="max-width: 100%; height: auto"
          class="img-girada"
          src="@/assets/img/logosya/Gafetes-21.png"
          alt="No imagen gafetes"
        />
        <div class="align-middle text-center text-md-left ml-md-4">
          <i class="fas fa-check-circle fa-5x text-primary"></i>
          <h3 class="mt-2 text-primary"><b>¡Tu solicitud fue enviada!</b></h3>
          <p>
            En breve nos comunicaremos contigo, para continuar con
            <span class="text-primary">el pago de tus credenciales</span>
          </p>
          <button
            class="btn btn-lg btn-success"
            type="button"
            @click="VerListado"
          >
            Ir a listado del personal
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
import { useRouter } from "vue-router";
const emit = defineEmits(["nextStep", "prevStep"]);
const router = useRouter();

function VerListado() {
  router.push("/personal");
}
</script>

<style scoped>
.img-girada {
  transform: rotate(30deg);
  width: 13rem;
  height: auto;
}
</style>
