<template>
    <div>
        <el-table :data="Lista" table-layout="fixed" fit :row-class-name="FilaEstilo"
            :header-row-class-name="ColumnaEstilo">
            <el-table-column v-for="Columna in Columnas" :key="Columna.colaboradorID" :prop="Columna.prop"
                :label="Columna.label">
                <template v-if="Columna.prop == 'fechaSolicitado' || Columna.prop == 'etiqueta'" #default="scope">
                    <div v-if="Columna.prop == 'fechaSolicitado'">
                        {{ FechaFormato(scope.row.fechaSolicitado) }}
                    </div>
                    <div v-else-if="Columna.prop == 'etiqueta'" style="gap: 10px;" class="d-flex">
                        <img :src="Foto(scope.row.miniaturaColaborador)" class="avatar avatar-sm rounded-circle" />
                        {{ scope.row.etiqueta }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column v-if="estatus != `Entregado`" label="Acciones">
                <template #default="scope">
                    <button v-if="estatus == 'Verificar'" class="btn text-muted" @click="Imprimir(scope.row)">
                        <i class="fas fa-print fa-lg text-primary"></i>
                    </button>
                    <button style="box-shadow: none; border: none;" class="btn text-muted"
                        @click="Borrar(scope.row)">
                        <i class="fas fa-times text-muted"></i>
                    </button>
                </template>
            </el-table-column>
        </el-table>

        <Modal v-if="MuestraModal == true" :show="MuestraModal" modal-classes="modal-dialog-centered"
            @close="MuestraModal = false">

            <template #header>

            </template>

            <template #body>

                <div class="w-100">
                    <div class="w-90 mx-auto d-flex flex-column justify-content-center">
                        <i style="color: #2ecfb2; font-size: 7em;"
                            class="d-flex justify-content-center fas fa-question-circle fa-lg"></i>
                        <h2 class="text-center mt-3 mb-3">
                            ¿Deseas eliminar a ({{GafeteBorrar.etiqueta}}) de este pedido?
                        </h2>
                        <MazTextarea style="height: 90%;" v-model="ComentarioBorrado" label="Motivo" color="secondary"
                        class="mt-2 mb-4" />
                        <button style="background-color: #2ecfb2; color: white;" class="w-100 btn" @click="MandarBorrar">Eliminar del pedido</button>
                        <router-link class="text-center mb-3" :to="{}" @click="MuestraModal = false"><i
                                class="fa fa-chevron-left me-2 "></i>Cancelar</router-link>
                    </div>
                </div>

            </template>
            <p><!--Vacio para generar--></p>
        </Modal>

    </div>
</template>

<script>
import { ElTable, ElTableColumn } from 'element-plus'
import Modal from "@/Componentes/Modal.vue";
import moment from "moment";
moment.locale("es-mx");
export default {
    name: "TablaDetalleGafete",
    emits:["Borrar","Imprimir"],
    components: {
        ElTable, ElTableColumn, Modal,
    },
    props: {
        Lista: { type: Array, default: null },
        estatus:{type:String,default: ""},
    },
    watch: {
        MuestraModal(Valor){
            if (Valor == true) {
                this.ComentarioBorrado= ""
            }
        },
    },
    data() {
        return {
            Columnas: [
                {
                    prop: "etiqueta",
                    label: "Nombre"
                },
                {
                    prop: "colaboradorID",
                    label: "ID Colaborador"
                },
                {
                    prop: "fechaSolicitado",
                    label: "Fecha y hora de solicitud"
                },
                {
                    prop: "solicito",
                    label: "Nombre de quien solicitó"
                },
            ],
            MuestraModal: false,
            GafeteBorrar:null,
            ComentarioBorrado:"",
        }
    },
    mounted() {

    },
    methods: {
        FechaFormato(fecha) {
            return moment(fecha).format("DD MMMM YYYY - HH:mm")
        },
        Foto(url) {
            if (url == null || url == "") {
                return "/img/default-avatar.png"
            }
            return url
        },

        FilaEstilo(row, rowIndex) {
            return "text-center align-center"
        },
        ColumnaEstilo(row, rowIndex) {
            return "text-center align-center text-muted"
        },

        Borrar(Fila){
            this.GafeteBorrar = Fila
            this.MuestraModal = true
        },
        Imprimir(Fila){
            this.$emit("Imprimir",Fila.colaboradorID)
        },
        MandarBorrar(){
            let GBorrar = {
                gafete:this.GafeteBorrar,
                comentario: this.ComentarioBorrado
            }
            this.$emit("Borrar",GBorrar)
        }
    },
}
</script>

<style lang="scss" scoped></style>