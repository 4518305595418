<!-- @format -->

<template>
  <div class="nav-item dropdown d-flex align-items-center" :class="isRTL ? 'ps-2' : 'pe-2'">
    <a
      id="dropdownMenuButton"
      href="#"
      :class="`p-0 nav-link ${
        isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
      } ${showMenu ? 'show' : ''}`"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click="showMenu = !showMenu"
    >
      <MazAvatar no-clickable-icon :src="urlFotoMiniatura" tabindex="-1" />
    </a>
    <ul
      class="px-2 py-3 dropdown-menu dropdown-menu-end "
      :class="showMenu ? 'show' : ''"
      aria-labelledby="dropdownMenuButton"
    >
      
      <navbar-drop-down-item @click="PerfilUsuario">
        <i class="far fa-user" ></i>
               Mi Perfil
      </navbar-drop-down-item>
      <li  class="nav-link" v-for="suscripcionUsuario in SuscripcionesUsuario"
          v-bind:key="suscripcionUsuario.suscripcionID">
          <a href="#" class="nav-item dropdown-item"
            @click="CambiaSuscripcion(suscripcionUsuario.suscripcionID)">
            {{ suscripcionUsuario.suscripcion.etiqueta }}({{
              ObtenEtiquetaPerfil(suscripcionUsuario.perfilID)
            }})
          </a>
        </li>
      <!-- <li><hr class="dropdown-divider"></li> -->
      <!-- <navbar-drop-down-item>
        <i class="fa fa-clock me-2"></i>
                1 day
      </navbar-drop-down-item> -->

    </ul>
  </div>
</template>

<script>
import MazAvatar from "maz-ui/components/MazAvatar";
import NavbarDropDownItem from "./NavbarDropDownItem.vue";
import Usuarios from "@/api-services/usuarios.service";
import { mapState, mapMutations, mapActions } from "vuex";
import { inject } from 'vue';
import SuscripcionesUsuarios from "@/api-services/suscripciones.usuarios.service";
const swal = inject("$swal");
export default {
    components: {
        MazAvatar,
        NavbarDropDownItem
    },
    props: {
        urlFotoMiniatura: {
            type: String,
            required: true
        }
    },
  data() {
    return {
      showMenu: false,
      SuscripcionesUsuario:null,
    };
  },
  computed: {
 
 
    ...mapState(["isRTL", "isNavFixed", "darkMode"]),

  },

  mounted(){
 
    Usuarios.getPerfilLocal((UsuarioPerfil) => {
      console.debug("Este es el perfil local",UsuarioPerfil);
      console.debug("Estas son sus suscripciones: ", UsuarioPerfil.usuario.suscripcionesUsuario)
      this.SuscripcionesUsuario = UsuarioPerfil.usuario.suscripcionesUsuario
      this.$appInsights.context.user.id = `${UsuarioPerfil.usuario.usuarioID}`;
      this.$appInsights.context.user.userId = `${UsuarioPerfil.usuario.usuarioID}`;
      this.$appInsights.context.user.accountId = localStorage.suscripcionID;
      this.SuscripcionesUsuario = UsuarioPerfil.usuario.suscripcionesUsuario;
     
      if (UsuarioPerfil.usuario.suscripcionesUsuario < 1) {
        var Ubicacion = window.location.href;
        var NUbicacion = "/#/suscripciones/pregunta";
        window.location.href = NUbicacion;
        return;
      }
      var Suscripcion = SuscripcionesUsuarios.getSuscripcion();
      let permisos =  UsuarioPerfil.usuario.suscripcionesUsuario.filter(Sus => Sus.suscripcionID == Suscripcion.suscripcionID)
      console.debug("Los permisos de la suscripcion son: ", permisos)
    });
  },

  methods:{
    PerfilUsuario(){
      let sus = SuscripcionesUsuarios.getSuscripcionID()
      let id = SuscripcionesUsuarios.getColaboradorID(sus)
      this.$router.push(`/personal/${id}`)
      // location.reload()
    },
    CambiaSuscripcion(SuscripcionID) {
      console.debug(SuscripcionID);
      SuscripcionesUsuarios.setSuscripcionID(SuscripcionID);
      Usuarios.getPerfilLocal((UsuarioPerfil) => { }, true);
      window.location.reload(false);
      // swal({
      //     title: "Listo",
      //     text: "Se ha cambiado la suscripción seleccionada.",
      //     icon: "success",
      //     customClass: {
      //       confirmButton: "btn btn-fill",
      //     },
      //     buttonsStyling: false,
      //   })
      //   .then(() => {
      //     window.location.reload(false);
      //   });
    },
    ObtenEtiquetaPerfil(PerfilID) {
      return SuscripcionesUsuarios.ObtenEtiquetaPerfil(PerfilID);
    },

  },
};
</script>
