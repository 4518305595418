<template>
    <div>
        <MazSelect v-model="ListaSelect" :options="ListaPersonal" :label="label" searchPlaceholder="Buscar colaborador"
            option-value-key="id" option-label-key="etiqueta" option-input-value-key="etiqueta" multiple search
            @update:model-value="$emit('update:value', $event)">
            <template #no-results>
             No se encontro al colaborador
            </template>
        
            <template v-slot:icon-left >
        <div v-if="ListaSelect.length > 0" >
           <div v-for="Colab in ListaSelect" :key="Colab.id">
          <img style="width: 2rem;" class="img-fluid rounded-circle"
                    :src="Colab.urlMiniatura != null ? Colab.urlMiniatura : '/img/default-avatar.png'"
                    alt="profile card" />
           </div>
        </div>
     </template>
            <template v-slot="{ option }">
                <img style="width: 2rem;" class="img-fluid rounded-circle"
                    :src="option.urlMiniatura != null ? option.urlMiniatura : '/img/default-avatar.png'"
                    alt="profile card" />
                <strong>{{ option.etiqueta }}</strong>
            </template>

            <!-- <div class="d-flex items-center" style="width: 80%; gap: 1rem;">
        <img
        style="width: 2rem;"
          class="img-fluid rounded-circle"
          :src="option.urlMiniatura != null ? option.urlMiniatura : '/img/default-avatar.png'"
          alt="profile card"
        />
        <strong>{{ option.etiqueta }}</strong>
     </div> -->

        </MazSelect>

    </div>
</template>

<script>
import { MazSelect } from "maz-ui";
import Colaboradores from "@/api-services/colaboradores.service"
export default {
    name: "PersonalSelect",
    components: {
        MazSelect
    },
    props: {
        value: { type: Array, default: null },
        label: { type: String, default: "Supervisor(es)" },
    },
    emits: ["update:value"],
    data() {
        return {
            ListaPersonal: null,
            ListaSelect: [],

        }
    },
    beforeMount() {
        Colaboradores.gets(this, { PaginaLen: 1000 }, (response) => {
            this.ListaPersonal = response.data.listado
           if (this.value != null && this.value.length > 0) {
            this.ListaSelect = this.value
           }
        })
    },
    mounted() {

    },
    methods: {
    },
}
</script>

<style></style>