<!-- @format -->

<template>
  <div id="navbar-personal-edicion" class="nav-wrapper position-relative end-0">
    <ul class="nav nav-pills nav-fill p-1" role="tablist">
      <li class="nav-item">
        <a class="nav-link active active mb-0 px-0 py-1" href="#personal">Personales</a>
      </li>
      <li class="nav-item">
        <a class="nav-link mb-0 px-0 py-1" href="#datos-acceso">Datos de acceso</a>
      </li>
      <li class="nav-item">
        <a class="nav-link mb-0 px-0 py-1" href="#domicilio">Domicilio</a>
      </li>
      <li class="nav-item">
        <a class="nav-link mb-0 px-0 py-1" href="#laborales">Laborales</a>
      </li>
      <li class="nav-item">
        <a href="#encuesta" class="nav-link mb-0 px-0 py-1" @click.prevent="MostrarEncuestas = !MostrarEncuestas">
          Encuestas
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link mb-0 px-0 py-1" href="#identificacion">Gafete</a>
      </li>
      <li class="nav-item">
        <a class="nav-link mb-0 px-0 py-1" href="">Guardar</a>
      </li>
    </ul>
    <ModalEncuestas v-if="MostrarEncuestas">
      <template v-if="$slots.nuevo" #nuevo>
        <slot name="nuevo"></slot>
      </template>
    </ModalEncuestas>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import ModalEncuestas from './../../../Componentes/Listados/ModalEncuestas.vue'

export default {
  components: {
    ModalEncuestas,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
      default: "Personales",
    },
  },
  data() {
    return {
      Seleccion: "Personales",
      IrAEdicion: true,
      MostrarEncuestas: false,
    };
  },
  mounted() {
    setNavPills();
  },

  methods: {
    cambiarValor(nuevoValor) {
      this.Seleccion = nuevoValor;
      // Emitir un evento para cambiar el valor
      this.$emit("update:modelValue", nuevoValor);
    },
  },
};
</script>