<template>
  <MazSpinner class="w-100 d-flex justify-content-center" v-if="cargando" color="secondary" :size="'48'" />
  <div v-else class="p-3 bg-white multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <div class="multisteps-form__content">
      <div class="row d-flex justify-content-between">
        <div class=" col-lg-9 col-12">
          <h4 class="text-primary">Elige la forma de entrega</h4>
          <CardDomicilio class="mb-3" :DireccionSucursal="sucursal" :Domicilio="info.colaborador"
            @update:DireccionSucursal="CambiaSucursal" v-model="Opcion" @update:model-value="ActualizaOpcion" />
          <CardPuntoEntrega class="mb-3" :direccion="puntosEntrega" v-model="Opcion"
            @update:model-value="ActualizaOpcion" />
          <div class="d-grid gap-2">
            <p class="text-center">
              Con los datos obtenidos crearemos tu gafete
            </p>
            <button class="btn btn-lg btn-success" type="button" @click="Agregar">
              Solicitar ahora
            </button>
          </div>
        </div>
        <div class=" col-lg-3 col-sm-12 mt-5 d-flex flex-column justify-content-center
        ">
          <div v-if="!CargaGafete"> 
            <div  :class="{ flipped: showBack }">
              <div class="card-inner">
                <div class="front">
                  <img style="width: 17rem" class="img-fluid" :src="credencial.frente"
                    alt="profile card" />
                </div>
                <div class="back">
                  <img style="width: 17rem" class="img-fluid" :src="credencial.atras"
                    alt="profile card" />
                </div>
              </div>
            </div>
            <div class=" w-100 d-flex  justify-content-center">
              <button class="btn btn-success rounded-circle" type="button" @click="toggleCard">
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
          <div v-else>
            <MazSpinner class="w-100 d-flex justify-content-center" v-if="CargaGafete" color="secondary" :size="'48'" />
          </div>
        </div>
     
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
moment.locale("es-mx");
import { defineProps, defineEmits, ref, onMounted } from "vue";
import Quill from "quill";
import Choices from "choices.js";
import CardDomicilio from "./CardDomicilio.vue";
import CardPuntoEntrega from "./CardPuntoEntrega.vue";
import Servicio from "@/api-services/colaboradores.yacalificame.service";
import ServicioSucursales from "@/api-services/Ubicaciones.yacalificame.service";
import Gafetes from "@/api-services/gafetes.yacalificame.service";
import Puestos from "@/api-services/puestos.service"
import PuntoEntrega from "@/api-services/puntoentrega.yacalificame.service";
import Impresiones from '@/api-services/impresiones.yacalificame.service';
//import ListadoGeneral from "@/Componentes/ListadoGeneral.vue";

import MazSpinner from "maz-ui/components/MazSpinner";

export default {
  name: "Step1Solicitar",
  components: {
    CardDomicilio,
    CardPuntoEntrega,
    MazSpinner,
    //ListadoGeneral,
  },
  props: {
    registro: {
      type: Object,
      required: true,
    },
  },
  watch: {
    Opcion(Valor) {

    }
  },
  data() {
    return {
      info: null,
      sucursal: null,
      ListaSucursales: null,
      puntosEntrega: null,
      Puesto: null,
      //Muestra el cargando
      cargando: true,
      //Indica que hay error
      MsgError: "",
      Opcion: null,
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },
      Datos: {
        etiqueta: "",
        descripcion: "",
        departamentoID: "",
      },
      Parametros: {
        fechaSolicitado: null,
        colaboradorID: null,
        ubicacionID: null,
        puntoRecogida: {
          puntoRecogidaID: null,
          receptor: null,
          telReceptor: null,
        },
        sobreNombre: null,
      },
      CargaGafete:true,
      credencial: {
        frente:null,
        atras:null
      }
    };
  },
  emits: ["next-step", "update:model"],
  setup(props, { emit }) {
    const showFront = ref(true);
    const showBack = ref(false);
    const MuestraEdit = ref(false);
    const FotoURLFront = "@/assets/img/logosya/Gafetes-22.png";
    const FotoURLBack = "@/assets/img/logosya/Gafetes-21.png";
    const NombreColaborador = ref("Nombre colaborador");

    const toggleCard = () => {
      showBack.value = !showBack.value;
    };

    const Editar = () => {
      MuestraEdit.value = true;
    };

    onMounted(() => {
      if (document.getElementById("edit-description")) {
        const quill = new Quill("#edit-description", {
          theme: "snow",
        });
      }
      if (document.getElementById("choices-category")) {
        const element = document.getElementById("choices-category");
        new Choices(element, {
          searchEnabled: false,
          allowHTML: true,
        });
      }
      if (document.getElementById("choices-sizes")) {
        const element = document.getElementById("choices-sizes");
        new Choices(element, {
          searchEnabled: false,
          allowHTML: true,
        });
      }
    });

    return {
      showFront,
      showBack,
      MuestraEdit,
      FotoURLFront,
      FotoURLBack,
      NombreColaborador,
      toggleCard,
      Editar,
    };
  },
  beforeMount() {
    /* Servicio.get(this, this.$route.params.id, (response) => {
      console.debug(response);
      this.info = response.data;
    }); */
    Servicio.get(null, this.$route.params.id, async (response) => {
      console.debug(response);
      this.info = response.data;
      let parametros = {
        SobreNombre: this.info.colaborador.etiqueta,
        ColaboradorID: this.info.colaborador.id
      }
      Puestos.gets(null, { Paginalen: 1000 }, (response) => {
        console.debug("Estos son los puestos:", response.data)
        let ListaP = response.data.listado
        this.Puesto = ListaP.filter(p => p.id == this.info.colaborador.puestoID)[0]
        ServicioSucursales.get(null, (response) => {
          console.debug("La respuesta es ", response);
          this.ListaSucursales = response.data.listaSucursales
          this.sucursal = this.ListaSucursales.filter(x => x.id == this.info.colaborador.ubicacionID)[0]
          PuntoEntrega.gets(this, {}, (response) => {
            this.puntosEntrega = response.data[0];
            Impresiones.post(null, parametros,(response) => {
      },(response) => {
        this.CargaGafete = false
        console.debug("Esta es su credencial", response.data)
        this.credencial.frente = response.data.cara0URL
        this.credencial.atras = response.data.cara1URL
      })
          });
        });
      })
    });


    /* ServicioSucursales.gets(this, this.$route.params.id, (response) => {
      console.debug(response);
      this.info = response.data;
    }) */
  },
  computed: {
  },
  methods: {
    Agregar() {
      if (this.Opcion == "Domicilio") {
        this.Parametros.fechaSolicitado = moment().format()
        this.Parametros.colaboradorID = this.$route.params.id
        this.Parametros.ubicacionID = this.sucursal.id
        this.Parametros.puntoRecogida = null
        this.Parametros.sobreNombre = this.info.colaborador.sobreNombre
        console.debug("El objeto que sse enviara es:", this.Parametros)
        Gafetes.post(this, this.Parametros, (response) => {
          console.debug("Esta es la respuesta", response)
          this.$emit("next-step")
        })
      }
      else if (this.Opcion == "Punto") {
        this.Parametros.fechaSolicitado = moment().format()
        this.Parametros.colaboradorID = this.$route.params.id
        this.Parametros.ubicacionID = null
        this.Parametros.puntoRecogida.puntoRecogidaID = this.puntosEntrega.id
        this.Parametros.puntoRecogida.receptor = this.puntosEntrega.datos.receptor
        this.Parametros.puntoRecogida.telReceptor = this.puntosEntrega.datos.telReceptor
        this.Parametros.sobreNombre = this.info.colaborador.sobreNombre
        console.debug("El objeto que sse enviara es:", this.Parametros)
        Gafetes.post(this, this.Parametros, (response) => {
          console.debug("Esta es la respuesta", response)
          this.$emit("next-step")
        })
      }
      else {
        this.$notify({ type: "danger", title: "Debes seleccionar una opcion de entrega" })
      }

      // Gafetes.post(
      //   this,
      //   Parametros.registro,
      //   (response) => {
      //     if (Parametros.OnAgregado != null) Parametros.OnAgregado(response);
      //     //    console.log("info", this.info);
      //   },
      //   (MsgError, Error) => {
      //     if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
      //   }
      // );
    },
    ActualizaOpcion(Valor) {
      this.Opcion = Valor
    },
    /*
    Evento de borrar registro
    @param Parametros{
      registro: registro a borrar
      OnBorrado: callback para ejecutar después de borrar
    }
  */
    Borrar(Parametros) {
      Servicio.delete(
        this,
        Parametros.registro.id,
        (response) => {
          if (Parametros.OnBorrado != null) Parametros.OnBorrado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    /*
    Evento de Reactivar registro
    @param Parametros{
      registro: registro a borrar
      OnBorrado: callback para ejecutar después de borrar
    }
  */
    Reactivar(Parametros) {
      Servicio.patch(
        this,
        Parametros.registro.id,
        (response) => {
          if (Parametros.OnBorrado != null) Parametros.OnBorrado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    Actualiza(value) {
      this.BaseGetsListado = value;
      Servicio.gets(this, value, (response) => {
        this.info = response.data;
        //    console.log("info", this.info);
      });
    },
    CambiaSucursal(Nombre) {
      this.sucursal = this.ListaSucursales.filter(x => x.etiqueta == Nombre)[0]
    },
    handleRadioChange(value) {
      this.selectedRadio = value;
    },
  },
};
</script>

<style>
.fondo {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 320px;
  height: 591px;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  position: relative;
  transition: background-image 0.5s;
  perspective: 1000px;
}

.card-inner {
  width: 320px;
  height: 435px;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.flipped .card-inner {
  transform: rotateY(180deg);
}

.front,
.back {
  position: absolute;
  width: 100%;
 
  backface-visibility: hidden;
}

.back {
  transform: rotateY(180deg);
}

.fondo-front {
  background-image: url("@/assets/img/logosya/Gafetes-22.png");
  background-image: url("@/assets/img/logosya/Gafetes-221.png");
}

.fondo-back {
  background-image: url("@/assets/img/logosya/Gafetes-21.png");
  background-image: url("@/assets/img/logosya/Gafetes-211.png");
}

.front {
  position: absolute;
  /* border: 1px solid black; */
  /* Asegura que la imagen esté encima de la imagen de la parte trasera */
}

.btn {
  transition: transform 0.5s;
  /* Agrega una transición para el giro del botón */
}

.btn.rotated {
  transform: rotateY(180deg);
  /* Gira el botón 180 grados en el eje Y */
}
</style>