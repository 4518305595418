<!-- Pendiente realizar mejora para poder cargar mas de una imagen -->
<template>
    <div>
        <MazDropzone style="border-color: #2dcec6; background-color: #effffe" class="maz-dropzone"
            ref="mazDropzoneInstance" :options="dropzoneOptions" @success="SubirArchivo"
            @sending="loading = true" @complete="loading = false" @removedfile="Quitar"  >
            <template #icon>
                <i class="fa-1x fas fa-plus" style="color: #2dcec6"></i> Agrega logotipo
            </template>
         </MazDropzone>

    </div>
</template>

<script>
import MazDropzone, { MazDropzoneInstance, MazDropzoneOptions } from 'maz-ui/components/MazDropzone'
import Servicio from '@/api-services/blobs.service';
export default {
    name: "ArchivoSelect",
    components: {
        MazDropzone
    },
    props: {
        // value: [String, Array],
        value: {type:String},
        archivo: {type:File},
        Paginas: { type: String, default: 'Dos' },
        Tipo: { type: String, default: 'Generico' },
        Etiqueta: { type: String, default: 'Campo' },
        MensajeCampo: { Type: String, default: "Da click o arrastra un archivo" },
        TextoCampo: { type: String, default: "PNG o JPG" },
        TiposArchivos: { type: String, default: "image/jpeg,image/jpg,image/png" },
        ArchivosMaximos: { type: Number, default: 1 },
        TamArchivo: { type: Number, default: 10 },
        Llave: { type: String, default: '' },
        TipoDato: { type: String, default: '' },
        required: { type: Boolean, default: false },
        altura:{ type: String, default:"100"},
    },
    data() {
        return {
            dropzoneOptions: {
                url: this.url(0),
                headers: Servicio.Headers(),
                maxThumbnailFilesize: 3,
                acceptedFiles: this.TiposArchivos,
                maxFilesize: this.TamArchivo,
                maxFiles: this.ArchivosMaximos,
                autoProcessQueue: true,
                autoRemoveOnError: true,
                dictDefaultMessage: this.MensajeCampo,
                dictFilesDescriptions: `(${this.TextoCampo} de maximo ${this.TamArchivo} MB)`,
                dictFallbackMessage: 'Tu navegador no es soportado',
                dictFileTooBig: `Archivo(s) muy grandes (max: ${this.TamArchivo} MB)`,
                dictInvalidFileType: `Tipo de archivo invalido`,
                dictRemoveFile: 'Remover',
                dictCancelUpload: 'Cancelar',
                dictMaxFilesExceeded: `No puedes subir mas archivos. (max: ${this.ArchivosMaximos})`,
                dictUploadCanceled: 'Carga de archivos cancelada',
            },
        }
    },

    emits: ["update:value","update:archivo"],

    computed: {
    },
    mounted() {

    },
    methods: {
        SubirArchivo(Archivo) {
            console.debug("Este es el archivo que se cargo", Archivo)
            // let urlimg = [Archivo.response.archivoURL]
            this.$emit("update:value",Archivo.response.archivoURL)
            this.$emit("update:archivo",Archivo.file)

        },
        url(Orden) {
            var Llave = this.Llave;
            if (Llave == null || Llave == "")
                Llave = null
            // return Servicio.ObtenURLPostSimple(this.Tipo);
            return Servicio.ObtenURLPost(this.Tipo, Llave, Orden);
        },
        Quitar(){
            this.$emit("update:value",null)
            this.$emit("update:archivo",null)
        },
    },
}
</script>

<style scoped></style>