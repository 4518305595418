<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { inject } from "vue";
import BaseService from "@/api-services/base.service";
import Base from "@/api-services/base";
import Mantenimiento from "@/api-services/mantenimiento";
const store = useStore();
const swal = inject("$swal");

const getApi = () => {
  let urls = Base.URL
  let url = new URL(urls)
  return url.host.split(".")[0]
}

const showSwal = () => {

  Mantenimiento.Version(this, (response) => {
    swal({
      iconColor: "#2ECD89",
      title: "Versión",
      html: `YCv: <b>${BaseService.Version}</b> <br>
            Api: <b>${response.data}</b> <br>
            Fuente: <b>${getApi()}</b>`,
      //    text: `Go: ${BaseService.Version} - Api: ${response.data}`,
      //icon: 'success',
      iconHtml: '<i class="fas fa-seedling" style="color: #2ECD89; boder: none" ></i>',
      showCancelButton: false,
      customClass: {
        confirmButton: "btn ",
      },
      confirmButtonText: "Ok",
      buttonsStyling: false,
    });
  })
}

const isRTL = computed(() => store.state.isRTL);
</script>
<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-end">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div class="d-lg-flex">
            <div class="my-auto mt-4 ms-auto mt-lg-0">
              <div class="text-sm text-end copyright text-muted" :class="isRTL ? 'text-lg-end' : 'text-lg-start'"
                @click="showSwal">
                ©
                {{ new Date().getFullYear() }}, creado con
                <i class="fa fa-heart"></i> para valorar.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
