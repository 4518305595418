/** @format */

import Base from "@/api-services/base";

import YaCalificaMe from "@/api-services/yaCalifica.me.service.js";
const BaseURL = `${YaCalificaMe.URL}/Suscripciones/{{SuscripcionID}}`;

export default {

  get(Vista, ColaboradorID,Fecha, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Colaboradores/${ColaboradorID}/Respuestas`);
    return Base.get(
      Vista,
      URL,
      { params:Fecha },
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },

};
