/**
 * =========================================================
 * YaCalifica.me - v1.0.0
 * =========================================================
 *
 * Copyright 2024 eClock
 *
 * Coded by eClock Team
 *
 * =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

import { createApp } from "vue";
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "maz-ui/styles"; // or import 'maz-ui/css/main.css'
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";

import MazCheckbox from "maz-ui/components/MazCheckbox";
import MazInput from "maz-ui/components/MazInput";
import MazSelect from "maz-ui/components/MazSelect";
import MazPagination from "maz-ui/components/MazPagination";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
polyfillCountryFlagEmojis();

import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import MazPicker from "maz-ui/components/MazPicker";
import MazTextarea from "maz-ui/components/MazTextarea";
import MazInputTags from "maz-ui/components/MazInputTags";


import Notifications from './Componentes/NotificationPlugin';

const appInstance = createApp(App);

import MsalService from "@/api-services/msal.service";
MsalService.inicializa();
appInstance.config.globalProperties.$msal = MsalService.msalInstance; // Hace MSAL accesible globalmente en tu aplicación

import Axios from "axios";
import VueAxios from "vue-axios";

appInstance.use(VueAxios, Axios);

//! Se necesita para PWA
/*
import wb from "./registerServiceWorker";
appInstance.provide('workbox', wb);
*/

appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);

appInstance.use(Notifications);

appInstance.component("MazCheckbox", MazCheckbox);
appInstance.component("MazInput", MazInput);
appInstance.component("MazSelect", MazSelect);
appInstance.component("MazPagination", MazPagination);
appInstance.component("MazPhoneNumberInput", MazPhoneNumberInput);
appInstance.component("MazPicker", MazPicker);
appInstance.component("MazTextarea", MazTextarea);
appInstance.component("MazInputTags", MazInputTags);


appInstance.use(ArgonDashboard);


import Usuarios from "@/api-services/usuarios.service";
import SuscripcionesUsuarios from "@/api-services/suscripciones.usuarios.service";
appInstance.provide("SuscripcionesUsuarios", SuscripcionesUsuarios);

appInstance.provide("Usuarios", Usuarios);
appInstance.mount("#app");


router.beforeEach((to, from, next) => {
  const currentUser = localStorage.sesion;
 // console.debug({ from, to });
  var requiresAuth =
    to.matched.some((record) => record.meta.requiresAuth) ||
    //to.path.indexOf("/state=") == 0 ||
    to.path == "/login" ||
    to.path == "/dashboards";
  //Mueve al inicio el scroll al cambiar de página
  const container = document.querySelector("#app");
  if (container) {
      container.scrollTop = 0;
  }

  // ! idea Alberca checar
  if (requiresAuth) requiresAuth = currentUser == null || currentUser == "" || localStorage.EsSSO == 1;

  if (!requiresAuth) {
    next();
    return;
  }
  if (requiresAuth) {
    MsalService.handleRedirectPromise(next, to.fullPath);

    return;
  }
});
