<!-- @format -->

<template>
  <ListadoSelect
    v-model="Seleccionado"
    :tipo="tipo"
    tipo-icono="fas fa-sitemap"
    :service="Service"
  />
</template>
  
  <script>
import Service from "@/api-services/encuestas.service";
import ListadoSelect from "@/Componentes/Listados/Select.vue";
export default {
  name: "EncuestasSelect",
  components: {
    ListadoSelect,
  },
  props: {
    tipo: {
      type: String,
      required: false,
      default: "encuesta",
    },
  },
  
  emits: ["update:Seleccionado"],

  data() {
    return {
      Seleccionado: null,
    };
  },
  computed: {
    Service() {
      return Service;
    },
  },
  watch: {
    value(valor) {
      this.Seleccionado = valor;
    },
    /*   selectValue(valor) {
            console.debug("El valor es", valor);
            //this.$emit('update:value', valor);
           // this.$emit("actualiza", valor);
            //  this.$set(this, 'value', valor);
            // this.value = valor;
        },*/
  },
  beforeMount() {
    console.debug("El valor que le llega es de ecnuedsta: ", this.value);
    console.debug("El valor que esta en el ecnuedsta: ", this.Seleccionado);
    if (this.value != this.Seleccionado) this.Seleccionado = this.value;
  },
  async mounted() {},
  methods: {
    Actualiza(valor) {
      console.debug("El valor es", valor);
    },
  }
};
</script>